@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.question_timer_container {
  background-color: #ff4c4c;
  display: flex;
  align-items: center;
  width: 12%;
  justify-content: center;
  border-radius: 30px;
  height: 45px;
  margin: 0 0 0 2%;
}
.out_of_title_text {
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 0 10%;
}

@media screen and (max-width: 767px) {
  .question_blue_container {
    width: 80px;
    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
    display: flex;

    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    width: 85px;
    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  }
  .timer_img {
    width: 16%;
  }
  .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 768px) {
  .question_blue_container {
    width: 88px;
    border-radius: 26px;
    height: 34px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    width: 27%;

    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  }
  .timer_img {
    width: 16%;
  }
  .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 991px) {
  .question_blue_container {
    width: 120px;
    border-radius: 26px;
    height: 45px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 18%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  }
  .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 1200px) {
  .question_blue_container {
    width: 126px;
    border-radius: 26px;
    height: 50px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 14%;
    justify-content: center;
    border-radius: 30px;
    height: 50px;
    margin: 0 0 0 2%;
  }
  .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  }
}
