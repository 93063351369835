.career-tooltip {
  position: relative;
  cursor: pointer;

}
.career-tooltip:hover::after {
  color: #222;
  padding: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 1.5rem;
  position: absolute;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: white;
  box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
}

@media only screen and (max-width: 768px) {
  .career-tooltip:hover::after {
    color: #222;
    width: 90vw;
    bottom: -16vw;
    left: -2vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
    z-index: 999;
  }
}


.section-tooltip {
  position: relative;
  cursor: pointer;
  z-index: 1000;
 
}
.section-tooltip:hover::after {
  color: rgb(255, 255, 255);
  padding: 1rem;
  font-weight: 400;
  top:-45px;
  left:-25px;
  font-size: 1.5rem;
  margin-left: 1rem;
  position: absolute;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: #1273B7;

  /* box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem; */
}

.section-tooltip:hover:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1273B7;
  content: ' ';
  position: absolute;
  top: -2px;
  left: 35%;
}


@media only screen and (max-width: 768px) {
  .section-tooltip:hover::after {
    color: #222;
    left: -40vw;
    width: 90vw;
    bottom: -16vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 1rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
  }
}
