@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
.main_header_container {
  width: 100%;
}
.under_main_header_container {
  margin: 0 auto 0 auto;
  display: flex;
  background: #1273b7;
  /* width: 1400px; */

  align-items: flex-start;
}
.left_side_img_container {
  position: relative;
}
.left_side_container {
  width: 40%;
  position: relative;
}
.vector_img_header {
  margin-top: -15%;
  top: 0;
  left: 0;
  position: absolute;
}
.header_title_container {
  position: absolute;
  left: 60%;
  transform: translate(-50%, 154%);
}
.header_title_text {
  font-family: Poppins;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  line-height: 70px;
  margin: 0;

  letter-spacing: -0.01em;
}
.header_title_yellow_text {
  font-family: Poppins;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  line-height: 70px;
  margin: -5% 0 0 0;

  letter-spacing: -0.01em;
  color: rgba(239, 207, 27, 1);
}
.right_side_container {
  width: 60%;
}
.img_header_bg {
  width: 100%;
}

@media (max-width: 600px) {
  .vector_img_header {
    margin-top: -9%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .header_title_container {
    position: absolute;
    left: 59%;
    top: 50%;
    transform: translate(-50%, 129%);
  }
  .header_title_text {
    font-size: 15px;
    line-height: 23px;
  }
  .header_title_yellow_text {
    font-size: 15px;
    line-height: 23px;
    margin: -3% 0 0 0;
  }
}

@media (min-width: 600px) {
  .vector_img_header {
    margin-top: -15%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .header_title_container {
    position: relative;
    left: 0;
    transform: translate(20%, 119%);
  }
  .header_title_text {
    font-size: 30px;
    line-height: 39px;
  }
  .header_title_yellow_text {
    font-size: 30px;
    line-height: 39px;
    margin: -1% 0 0 0;
  }
}

@media (min-width: 991px) {
  .header_title_container {
    position: relative;
    left: 0;
    transform: translate(16%, 142%);
  }
  .header_title_text {
    font-size: 40px;
    line-height: 50px;
  }
  .header_title_yellow_text {
    font-size: 40px;
    line-height: 50px;
    margin: -1% 0 0 0;
  }
}

@media (min-width: 1200px) {
  .vector_img_header {
    margin-top: -15%;
    top: 0;
    left: 0;
    position: absolute;
    width: 751px;
    height: 534px;
  }
  .main_header_container {
    margin: 0 auto 81px auto;
  }
  .header_title_container {
    position: relative;

    left: 0;
    transform: translate(0%, 0%);
    margin: 247px 0 0 140px;
  }
  .header_title_text {
    font-size: 56px;
    line-height: 60px;
  }
  .header_title_yellow_text {
    font-size: 56px;
    line-height: 60px;

    margin: 0 0 0 0;
  }
  .under_main_header_container {
    width: 100%;
  }
  .left_side_container {
  }
}

@media (min-width: 1400px) {
  .under_main_header_container {
    width: 100%;
    min-height: 568px;
  }
}

/* @media (min-width: 1200px) {
  .main_header_container {
    margin: 0 auto 81px auto;
  }
  .header_title_container {
    position: relative;
    left: 0;
    transform: translate(16%, 162%);
  }
  .header_title_text {
    font-size: 50px;
    line-height: 60px;
  }
  .header_title_yellow_text {
    font-size: 50px;
    line-height: 60px;

    margin: -1% 0 0 0;
  }
  .under_main_header_container {
    width: 1400px;
  }
  .left_side_container {
  }
} */
