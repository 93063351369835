.question_container {
    width: 100%;
    display: block;

}

.option_image_width {
    width: 49%
}

.option_content_width {
    width: 100%
}

.question_container p img {

    width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.option_content_container img {
    width: 50%;
    object-fit: contain;
}

.question_container p,
.question_container table {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}


.question_container p {
    text-align: left;
}

.question_container table {

    text-align: center;
}

.question_container ol {
    font-size: 1.4rem;
    text-align: left;
    font-weight: 500;
    margin: 0;
}



.question_container span[style="background-color:transparent; color:#000000"] {
    display: flex;

}

.question_container table span[style="background-color:transparent; color:#000000"] {
    display: flex;
    justify-content: center;
}

.question_container>p>span[style="background-color:transparent; color:#000000"] {
    text-align: left;
}

@media (max-width:767px) {
    .question_container p img {
        width: 100%;
        height: 115px;
        display: flex;

    }

    .question_container p {
        font-size: 1.4rem;
    }

    .question_container table {
        font-size: 1.3rem;
    }

    .question_container ol {
        font-size: 1.3rem;
    }

    .option_content_container img {
        width: 100%;
        height: 60px;
    }
}

@media (min-width:768px) {
    .option_content_container img {
        width: 33%;
        object-fit: contain;
    }

    .question_container p {}

    .question_container p img {
        width: 50%;
        height: 200px;

    }

    .question_container p {
        font-size: 1.6rem;
    }

    .question_container ol {
        font-size: 1.5rem;
    }
}

@media (min-width:1200px) {
    .option_content_container img {
        width: 50%;
        object-fit: contain;
    }
}


@media (min-width:1400px) {
    .question_container p {
        font-size: 1.7rem;
    }

    .question_container ol {
        font-size: 1.6rem;
    }
}