@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap);
/*!
 * 
 * antd v3.24.3
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
.btn-stride-primary{
  background-color: #1273B7;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 #1890ff;
          box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px #1890ff;
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px #1890ff;
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
          animation-name: antSwingIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 4px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert.ant-alert-closable {
  padding-right: 30px;
}
.ant-alert-icon {
  position: absolute;
  top: 11.5px;
  left: 16px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  border-radius: 4px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-close {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert-slide-up-leave {
  -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@-webkit-keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #1890ff;
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #1890ff;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: 32px;
  line-height: 1.5;
  background: transparent;
  border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--single .ant-select-selection-selected-value {
  padding-right: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-select-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-select-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f7ff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #1890ff;
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-input-clear-icon + i {
  margin-left: 6px;
}
.ant-input-search-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-link:active > a:only-child,
.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1890ff;
  background-color: transparent;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #096dd9;
  background-color: transparent;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background-color: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9363e;
  background-color: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link {
  color: #1890ff;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: #40a9ff;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: #096dd9;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 4px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.ant-badge-count {
  z-index: 10;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #f5222d;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
          transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #40a9ff;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-item > a:hover {
  color: #1890ff;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1890ff;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #1890ff;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #1890ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6f7ff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 4px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background: rgba(0, 0, 0, 0.65) \9;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
          transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
          transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #1890ff, #1890ff);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
          transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
          transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2px);
          transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2px);
          transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #1890ff;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #1890ff;
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #1890ff;
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #1890ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.01px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
          transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
          transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
          transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
          transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1890ff;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid #d9d9d9;
  outline: none;
}
.ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
.ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  color: #fff;
  background: #1890ff;
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #1890ff inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #1890ff;
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 32px;
  line-height: 30px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #bae7ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #1890ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #e6f7ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #1890ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}
.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.65);
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #1890ff;
  background: #fff;
  border-color: #1890ff;
  box-shadow: -1px 0 0 0 #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #1890ff !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #1890ff;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #40a9ff;
  border-color: #40a9ff;
  box-shadow: -1px 0 0 0 #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #096dd9;
  border-color: #096dd9;
  box-shadow: -1px 0 0 0 #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #096dd9;
  border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(24, 144, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #1890ff;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #1890ff;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #1890ff;
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 45px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
  line-height: 56px;
}
.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
  line-height: 37px;
}
.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: 100%;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #1890ff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 2px;
  background-color: #1890ff;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  -webkit-filter: none;
          filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: ' ';
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 2px solid transparent;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #40a9ff;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #096dd9;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs-content::before {
  display: block;
  overflow: hidden;
  content: '';
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 4px 4px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 4px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #e6f7ff;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1890ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fff;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-comment {
  position: relative;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color: #40a9ff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #40a9ff;
}
.ant-calendar-today .ant-calendar-date {
  color: #1890ff;
  font-weight: bold;
  border-color: #1890ff;
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #bae7ff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: '';
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: #1890ff;
  background: #bae7ff;
  border-color: #1890ff;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #1890ff;
  border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #1890ff;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  line-height: 24px;
  border: 0;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6f7ff;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #e6f7ff;
}
.ant-calendar-time-picker-select li:focus {
  color: #1890ff;
  font-weight: 600;
  outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-month-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
  flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-year-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
  flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-decade-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: rgba(0, 0, 0, 0.65);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
  flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6f7ff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #bae7ff;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  width: 56px;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:focus {
  color: #1890ff;
  font-weight: 600;
  outline: none;
}
.ant-time-picker-panel-select li:hover {
  background: #e6f7ff;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.5;
    }
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1890ff;
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-descriptions-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}
.ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ' ';
}
.ant-descriptions-item-colon::after {
  content: ':';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-descriptions-item {
  padding-bottom: 0;
}
.ant-descriptions-item > span {
  display: inline-block;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  background: #e8e8e8;
}
.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-color: #e8e8e8;
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer > * {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top.ant-drawer-open.no-mask,
.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.85);
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 24px;
}
form .has-feedback .ant-input-password-icon {
  margin-right: 18px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.5;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.5;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f5222d;
}
.has-error .ant-input-prefix {
  color: #f5222d;
}
.has-error .ant-input-group-addon {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .has-feedback {
  color: #f5222d;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #f5222d;
}
.has-error .ant-select-selection:hover {
  border-color: #f5222d;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #f5222d;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #f5222d;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-transfer-list {
  border-color: #f5222d;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #1890ff;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 5px;
}
.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333333) rotate(0deg);
          transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 4px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 4px 0 0 4px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
}
.ant-list-item-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #1890ff;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #1890ff;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1890ff;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color: #1890ff;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(0, 0, 0, 0.65);
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 1px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(0, 0, 0, 0.45);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mention-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5;
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mention-wrapper .ant-mention-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mention-wrapper .ant-mention-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mention-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color: #1890ff;
  text-align: center;
}
.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-mention-dropdown-item:hover {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-mentions:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:read-only {
  cursor: default;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #e6f7ff;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 4px 4px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 4px 4px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #e6f7ff;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  float: left;
  margin: 8px 0;
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #40a9ff;
}
.ant-page-header-back-button:active {
  color: #096dd9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  width: 100%;
  overflow: hidden;
}
.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.ant-page-header-heading .ant-avatar {
  float: left;
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  float: left;
  margin: 5px 0;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-page-header-heading-tags {
  float: left;
  margin: 4px 0;
}
.ant-page-header-heading-extra {
  float: right;
}
.ant-page-header-heading-extra > * {
  margin-left: 8px;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
  overflow: hidden;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px;
  font-size: 16px;
}
@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: block;
    float: unset;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin-top: 32px;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #1890ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #1890ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #f5222d;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
          transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 51px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 104px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 40px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: 140px;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 3px;
  background-color: #1890ff;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:not(.ant-switch-disabled):active::before,
.ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #1890ff;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  -webkit-transform: scale(0.66667);
          transform: scale(0.66667);
}
.ant-switch-checked {
  background-color: #1890ff;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan] {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #1890ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(0, 0, 0, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e6f7ff;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 4px 4px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 4px 4px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 400px;
  overflow-x: hidden;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #1890ff;
  font-weight: bold;
  text-shadow: 0 0 2px #bae7ff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:hover {
  color: #40a9ff;
}
.ant-table-filter-dropdown-link:active {
  color: #096dd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: auto;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 4px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 4px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr:only-child > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 4px 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-timeline-item-head-red {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -6px;
  margin: 0 0 0 18px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-transfer-customize-list {
  display: flex;
}
.ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 12px 9px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-header .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
.ant-transfer-list-body-with-search {
  padding-top: 56px;
}
.ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content > .LazyLoad {
  -webkit-animation: transferHighlightIn 1s;
          animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item-text {
  padding-left: 8px;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #e6f7ff;
  cursor: pointer;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 16px;
}
.ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
.ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@-webkit-keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }
  100% {
    background: transparent;
  }
}
@keyframes transferHighlightIn {
  0% {
    background: #bae7ff;
  }
  100% {
    background: transparent;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #bae7ff;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #e6f7ff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #1890ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #1890ff;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #1890ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #1890ff;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #1890ff;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #1890ff;
}
.ant-tree li.filter-node > span {
  color: #f5222d !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #1890ff;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #e6f7ff;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #bae7ff;
}
.ant-tree li span.ant-tree-checkbox {
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.45);
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(100% - 22px);
  margin: 22px 0 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography {
  color: rgba(0, 0, 0, 0.65);
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #f5222d;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4 {
  margin-top: 1.2em;
}
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-typography a:focus,
.ant-typography a:hover {
  color: #40a9ff;
}
.ant-typography a:active {
  color: #096dd9;
}
.ant-typography a:active,
.ant-typography a:hover {
  text-decoration: none;
}
.ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #1890ff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #40a9ff;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #096dd9;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul li {
  list-style-type: circle;
}
.ant-typography ul li li {
  list-style-type: disc;
}
.ant-typography ol li {
  list-style-type: decimal;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum', "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
  opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
  top: 25px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.65);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6f7ff;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 5px;
  color: #f5222d;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
          animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
          animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

.radioImage img{
  height: 100%;
  max-height: 150px;
  width: auto;
}

.progress-bar > div > div{
  border: 1.5px solid #1273B7;
}

.btn-stride-primary{
  background-color: #1273B7 !important;
  color: white !important;
  block-size: 30px;
}

.quizListTable{
  font-size: 14px !important;
}

/*# sourceMappingURL=antd.css.map*/

.hidden { display:none; }

.universal-quiz-instructions-wrapper div {
    font-family: "Poppins";
    font-style: normal;
}

.universal-quiz-instructions-wrapper {
    display: grid;
    grid-template-columns: 150px 10px 150px 10px 150px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    grid-row-gap: 40px;
    row-gap: 40px;
}

#aptitude-quiz-timer h2 {
    margin: 0;
}

.aptitude-quiz-report-question-counter {
    background: #ffffff;
    border-radius: 100px;
    text-align: center;
    margin: 60px auto;
    width: 137px;
    height: 48px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 48px;
    color: #4f4f4f;
}

.variable-width .slick-slide {
    /* width: 475px; */
}

#aptitude-quiz-type-progress {
    width: 50px;
}

.aptitude-quiz-page-wrapper,
.aptitude-report-page-wrapper {
    /* background: url("https://d2b560u3hlgvw0.cloudfront.net/BASVNI_Content Frame.png")
    no-repeat center;
  background-size: cover; */
    background: linear-gradient(180deg, #4eb6fed4 0%, #1c8bd9 100%);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100%;
}

.aptitude-quiz-question-option-wrapper {
    margin-bottom: 0 !important;
}

.aptitude-quiz-question-option-wrapper label {
    text-align: left;
}

.aptitude-quiz-page-wrapper {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aptitude-quiz-reveal-answer-button {
    display: none;
}

.universal-quiz-instructions-button {
    border: 0;
    border-radius: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
}

.universal-quiz-instructions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.universal-quiz-instructions:nth-child(1) {
    padding-bottom: 40px;
}

.universal-quiz-instructions:nth-child(3) {
    padding-top: 40px;
}

.horizontal-divider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.universal-quiz-instruction-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 150px;
}

.universal-quiz-instruction {
    color: #1273b7;
}

.universal-quiz-instruction {
    width: 80px;
    height: 80px;
    background: #e7f1f7;
    border-radius: 10px;
    margin: 0 auto;
    font-weight: 600;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.universal-quiz-instruction-title {
    font-size: 40px;
    line-height: 40px;
}

.universal-quiz-instruction-description {
    font-size: 14px;
    line-height: 14px;
}

.universal-quiz-instruction-info {
    margin-top: 16px;
    font-size: 18px;
    line-height: 22px;
    color: #4f4f4f;
}

.vertical-line-150 {
    height: 150px;
    width: 0px;
    border: 1.25px solid #e0e0e0;
}

.horizontal-line-150 {
    width: 150px;
    height: 0px;
    border: 1.25px solid #e0e0e0;
}

.universal-quiz-instructions-button {
    height: 100%;
}

.universal-quiz-instructions-wrapper {
    padding-bottom: 40px;
}

.react-quizzes-builder form {
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px;
    width: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    min-height: 100%;
}

#content>.react-quizzes-quizz {
    position: relative;
}

.react-quizzes-builder .ElementWrapper {
    padding: 10px;
}

.react-quizzes-builder .ElementWrapper:hover {
    cursor: grab;
    box-shadow: inset 0 0 5px #ddd;
    background: #fff;
}

.react-quizzes-builder,
#root {
    height: 100%;
}

.react-quizzes-builder .ant-form-item-label,
.react-quizzes-quizz .ant-form-item-label {
    white-space: normal !important;
    text-align: center !important;
    line-height: normal !important;
    word-break: break-all;
}

.question-counter {
    margin: 46px 0 52px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 18px;
    color: #333333;
}

.dashed-line {
    border: 1px dashed #bdbdbd;
}

.react-quizzes-quizz .ant-radio-group {
    display: none;
    grid-gap: 32px;
    gap: 32px;
    justify-items: stretch;
    min-height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
}

.react-quizzes-quizz .question-options-div {
    text-align: center;
    margin-top: 15px;
}

.react-quizzes-quizz .question-options-div .ant-form-item-control {
    text-align: left;
}

.react-quizzes-quizz .question-options-div.radio-quiz {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 40px 60px;
}

.react-quizzes-quizz .question-options-div.radio-quiz .ant-form-item-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px !important;
    color: #333333;
    width: 100%;
    text-align: left !important;
    margin-bottom: 32px;
}

.react-quizzes-quizz .ant-radio-wrapper {
    width: 300px;
    max-width: 100%;
    min-height: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-items: stretch;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.react-quizzes-quizz .ant-radio-wrapper-checked {
    border: dashed 2px white;
    background-color: #1273b7 !important;
}

.aptitude-quiz-question-option-wrapper>div:first-child {
    display: flex;
}

.aptitude-quiz-question-option-wrapper>div:first-child label {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #4f4f4f !important;
}

.react-quizzes-quizz.aptitude-quiz .ant-radio-wrapper-checked {
    border: 1.25px solid #1273b7 !important;
    box-sizing: border-box;
    border-radius: 8px;
    background: #f3f8fb !important;
}

.react-quizzes-quizz.aptitude-quiz .ant-radio-wrapper-checked>span {
    color: #1273b7 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.react-quizzes-quizz .ant-radio-wrapper>span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.slick-container {
    background: #219653 !important;
    border: 10px solid red;
}

.react-quizzes-quizz .sample-question.ant-radio-wrapper-checked {
    background-color: #f3fff8 !important;
    border: 2px solid #219653;
}

.react-quizzes-quizz .sample-question.ant-radio-wrapper-checked>span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #219653 !important;
}

.react-quizzes-quizz .ant-radio-wrapper-checked .radioImage {
    background-color: #1273b7 !important;
}

.react-quizzes-quizz .ant-radio {
    margin-left: 3%;
}

.react-quizzes-quizz .question-options-div .ant-form-item-label label {
    font-size: 20px;
    font-weight: bold;
    word-break: normal;
}


/* p img {
  max-height: 300px;
  max-width: 300px;
} */

#submit-button-div {
    text-align: center;
}

#quizName {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #1273b7;
}

.react-quizzes-builder .ant-avatar.ant-avatar-icon {
    color: inherit;
    background: transparent;
}

.react-quizzes-builder .ant-avatar.ant-avatar-circle {
    color: inherit;
    background: transparent;
}


/* makes questions display next to required symbol */

.react-quizzes-builder .ant-form-item-no-colon label>p:nth-child(1),
.react-quizzes-quizz .ant-form-item-no-colon label>p:nth-child(1) {
    display: inline;
}

.ReactQueryDevtools {
    display: none;
}

#content {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.universal-quiz-page-wrapper {
    height: 100%;
    background: url("https://d1dhn4cz1wmlqo.cloudfront.net/staging/ASSESSMENT_PROCESSOR/5B5DPD_Background.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.radioImageWrapper .ant-radio {
    display: none;
}

.react-quizzes-quizz .radioImage img {
    height: auto;
    width: 100%;
    display: block;
}

.react-quizzes-quizz .radioImage div {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
    color: black;
}

.radioImage {
    line-height: 1.5;
}

.radioImageOptions>div>.radioImageWrapper {
    border-radius: 0px;
    padding: 0;
    background-color: white;
    flex-basis: calc(50% - 40px);
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.radioImageWrapper span:not([class]) {
    padding: 0;
    background-color: white;
}

#thank-you-modal {
    display: flex !important;
    overflow: hidden !important;
    justify-content: center !important;
}

#thank-you-modal .success-animation {
    display: block;
    width: 156px;
    height: 156px;
    margin: 0 auto;
}

#thank-you-modal.universal-quiz-popup .success-animation {
    width: 160px;
    height: 160px;
}

.reportContent {
    padding-top: 30px;
}

.invalid-report-id {
    text-align: center;
    font-family: "Poppins";
    font-size: 32px;
}

#thank-you-modal-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

#thank-you-modal {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
}

#thank-you-modal .modal-content {
    border-radius: 10px 10px 0 0;
    height: 420px !important;
}

#thank-you-modal.universal-quiz-popup {
    width: 328px;
}

#thank-you-modal.universal-quiz-popup .modal-content {
    height: 100% !important;
}

#thank-you-modal.universal-quiz-popup .modal-body {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 16px 16px;
}

#thank-you-modal.universal-quiz-popup .modal-footer {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 16px 16px;
    height: 56px;
}

#thank-you-modal.universal-quiz-popup .heading {
    margin: 0 0 10px 0;
    font-weight: 500;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    line-height: 16px;
}

#thank-you-modal.universal-quiz-popup .heading div:nth-child(1) {
    font-size: 12px;
}

#thank-you-modal.universal-quiz-popup .heading div:nth-child(2) {
    font-size: 14px;
    margin-top: 5px;
}

#thank-you-modal.universal-quiz-popup #submitBtn {
    background: #219653;
    height: 40px;
}

#thank-you-modal .modal-body {
    min-height: 90%;
    background: white;
    padding: 0px 16px 0px 12px;
}

#container {
    height: 100%;
    width: 100%;
    position: relative;
}

#top-wave {
    position: relative;
}

#top-wave .desktop {
    display: block;
}

#top-wave .mobile {
    display: none;
}

#top-wave>img {
    width: 100%;
}

#top-wave>.report-title {
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 50px;
}

.scroll-down-arrow-animation {
    cursor: pointer;
}

#quiz-page-loading-gif {
    position: fixed;
    left: 50%;
    top: 250px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 99;
}

#top-wave>.report-title>div:nth-child(2) {
    color: #efcf1b;
    font-size: 32px;
}

#report-wrapper {
    width: 100%;
    position: absolute;
    top: 180px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.report-content {
    width: 1160px;
    max-width: 70%;
    margin: 0 auto;
    padding: 60px 60px 8px 60px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

.report-content>div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.report-content>div:nth-child(1)>img {
    max-width: 40%;
}

.report-content>div:nth-child(1)>.content {
    max-width: 60%;
    margin: 0 60px 0 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
}

#report-wrapper>div>div:nth-child(1)>p>span {
    display: block;
    margin-bottom: 16px;
}

.report-content>div:nth-child(2) {
    margin-top: 60px;
}

#report-wrapper .report-progress-bar {
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border: 2.5px solid #1273b7;
    border-radius: 100px;
    position: relative;
    display: flex;
    align-items: center;
}

#report-wrapper .report-progress-bar>.bar {
    height: 85%;
    width: 100%;
    margin: 0 3px;
    background-color: #1273b7;
    border-radius: 100px;
}

.report-content>div:nth-child(2)>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    text-align: center;
    margin-top: 32px;
}

#report-wrapper>div>div:nth-child(2)>div.report-progress-bar>div>span {
    line-height: 40px;
    position: absolute;
    right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1273b7;
}

#reportData {
    text-align: left;
    font-size: 14px;
}

#reportHeading {
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
}

.option-div,
.option-value-div,
.option-image-div,
.toggle-button-div,
.delete-button-div {
    margin: 50px 20px;
    position: relative;
}

.quiz-score {
    text-align: center;
}

.quiz-score .animation {
    display: inline-flex;
}

.quiz-score .animation>div {
    height: 100px !important;
}

.quiz-score>div {
    text-align: center;
    margin: 0 auto;
}

.quiz-score>div:not(first-child) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
}

.quiz-score>div:nth-child(2) {
    font-size: 48px;
    line-height: 64px;
    color: #f2994a;
}

.quiz-score>div:nth-child(3) {
    font-size: 40px;
    line-height: 40px;
    color: #333333;
}

.quiz-score>div:nth-child(4) {
    display: none;
}

.vl {
    border-left: 1px solid #e0e0e0;
    height: 100%;
    position: absolute;
    left: -20px;
}

.universal-quiz-summary {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 24px;
}

.option-div>button {
    margin-top: 10px;
}

.slick-list {
    margin: 0 auto !important;
    width: 85%;
    padding: 10px 50px !important;
}

.slick-slide:not(.slick-center) .universal-quiz-question-wrapper {
    /* filter: blur(4px); */
}

.slick-center .universal-quiz-question-wrapper {
    -webkit-filter: none;
            filter: none;
}

.slick-next,
.slick-prev {
    height: 44px !important;
    width: 44px !important;
    color: red !important;
}

.slick-prev:before,
.slick-next:before {
    color: #1273b7 !important;
    background: #e7f1f7 !important;
    font-size: 44px !important;
}

.slick-next {
    /* right: 48px !important; */
}

.slick-prev {
    /* left: 48px !important; */
}

.correct-answer-toggle {
    height: 32px;
    display: flex;
    align-items: center;
}

.universal-quiz-question-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 296px !important;
    margin: 0 auto !important;
    display: block !important;
}

.aptitude-quiz-question-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    margin: 0 auto !important;
    display: block !important;
}

.apt .slider-wrapper {
    width: 1000px;
    margin: 0 auto;
}

.slider-wrapper .slick-track>div {
    padding: 10px;
}

.slider-wrapper .slick-prev {
    left: unset !important;
}

.slider-wrapper .slick-next {
    right: 0 !important;
}

.universal-quiz-question,
.aptitude-quiz-question {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    text-align: left;
}

.aptitude-quiz-question {
    padding: 20px;
}

.universal-quiz-answers {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.universal-quiz-answers>div {
    height: 40px;
    width: 100%;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px;
    border-radius: 30px;
    white-space: normal !important;
    line-height: 2;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 10px;
}

.aptitude-quiz-question-wrapper img {
    margin: 0 auto;
    display: inline-block !important;
}

.aptitude-quiz-question-wrapper .universal-quiz-answers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-content: start;
    margin-top: 0;
}

.aptitude-quiz-answers-text,
.aptitude-quiz-answers-image {
    padding: 20px;
}

.universal-quiz-answers .user-answer {
    background: #fff6f6;
    border: 1px solid #e92e30;
    box-sizing: border-box;
    border-radius: 8px;
}

.universal-quiz-answers>.correct-answer {
    background: #f3fff8;
    border: 1px solid #219653;
    box-sizing: border-box;
    border-radius: 8px;
}

.universal-quiz-answers .user-answer:only-child {
    background: #f3fff8;
}

.universal-quiz-question-counter {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
}

.valueInputOption {
    margin-bottom: 52px;
}

#quiz-progess-section {
    text-align: center;
    padding: 0 20px;
}

.progress-bar>div,
.right-arrow,
.left-arrow,
.back-button,
.skip-button {
    display: inline-block;
}

#quiz-progess-section>div.progress-bar>div>div>span {
    position: absolute;
    right: 0;
}

#universal-quiz-type-progress {
    padding: 50px 16px 0 16px;
}

#universal-quiz-type-progress>div.progress-bar>div>div>span,
.random-quiz-type-progress>div.progress-bar>div>div>span {
    position: absolute;
    right: 0;
}

.right-arrow,
.left-arrow {
    cursor: pointer;
    color: white;
    width: 28px;
    height: 28px;
    background-color: #1273b7;
    border-radius: 50%;
    line-height: 2;
}

.left-arrow,
.back-button {
    float: left;
    display: none;
}

.right-arrow,
.skip-button {
    float: right;
    display: none;
}

.progress-bar>div {
    display: grid;
    border: 1.5px solid #1273b7;
    background-color: #ffffff !important;
    position: relative;
}

.progress-bar>div>div {
    height: 24px !important;
    margin: auto 1px;
}

#lead-squared-form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.quiz-score {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.stride-blue {
    color: #1273b7;
}

.correct-answers-count {
    color: rgb(43, 167, 43);
}

.horizontal-line {
    background: #e5e5e5e5;
    border: 1.5px solid #e0e0e0;
    margin: 10px;
}

#lead-squared-form>.form-description {
    margin: 10px;
}

#lead-squared-form>.form-description>div:nth-child(1) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #333333;
}

#lead-squared-form>.form-description>div:nth-child(2) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

#lead-squared-form .security-logo {
    margin-top: 22px;
    text-align: center;
}

#quiz-progess-section>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
}

#thank-you-modal .heading {
    margin: 24px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333333;
}

#thank-you-modal .heading div:nth-child(1) {
    font-size: 24px;
}

#thank-you-modal .heading div:nth-child(2) {
    font-size: 18px;
    margin-top: 10px;
}

#thank-you-modal .description {
    margin-top: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333333;
}

#thank-you-modal .progress-wrapper {
    height: 28px;
    background-color: rgb(224, 224, 222);
    border-radius: 100px;
    width: 70%;
    margin: 4px auto;
}

#thank-you-modal .progress-wrapper>div {
    height: 28px;
    background-color: rgb(18, 115, 183);
    transition: width 1s ease-in-out 0s;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 99%;
}

#thank-you-modal .progress-wrapper span {
    padding: 5px;
    color: white;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 15px;
    display: initial;
}

#thank-you-modal #submitBtn {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border: none;
    background: #1273b7;
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

#thank-you-modal .profile_navigation_btn {
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: none;
    background: #fff !important;
    color: #219653 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

#lead-squared-form input {
    background: #f6faff;
    border: none;
}

.single-input-asterik {
    position: absolute;
    top: 40%;
    right: 5%;
}

.double-input-asterik {
    position: absolute;
    top: 55%;
    right: 10%;
}

.universal-quiz-report {
    display: block !important;
}

.universal-quiz-report-mobile {
    display: none !important;
}

.universal-quiz-page-footer {
    position: absolute;
    bottom: 0;
    display: none;
}


/* styling for options in add/edit quiz form */

.quiz-options-row {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 32px;
    background: #ffffff;
}

.quiz-options-fields {
    display: flex;
}

.options-hl {
    border: 1px solid #e0e0e0;
    margin: 12px 0;
}

.options-vl {
    border: 1px solid #e0e0e0;
    margin-left: 24px;
    margin-right: 32px;
    width: 1px;
}

.text-input,
.value-input,
.image-input,
.correct-answer-input,
.score-input {
    padding: 37px 0 6px 0;
    position: relative;
}

.option-input-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 39px;
    grid-gap: 5px;
    gap: 5px;
}

.horizontal-line-100,
.vertical-line-100,
.spacer-mobile {
    display: none;
}

.slider-wrapper .slick-list {
    padding: 0 60px !important;
}

.aptitude-quiz-report-slider .aptitude-quiz-question {
    text-align: center;
}

.question-options-div .ant-form-item-label label:first-child {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #4f4f4f !important;
}

.react-quizzes-quizz .question-options-div.radio-quiz {
    padding: 16px 40px 100px 16px !important;
    margin: 16px !important;
}

@media only screen and (max-width: 500px) {
    .aptitude-quiz-question-wrapper .universal-quiz-answers {
        display: grid;
        grid-template-columns: 100%;
    }
    .aptitude-quiz-answers-text>div {
        margin: 0px !important;
    }
    .aptitude-quiz-answers-image {
        display: none !important;
    }
    .aptitude-quiz-reveal-answer-button {
        display: block;
    }
    .slider-wrapper {
        width: auto;
    }
    .slider-wrapper .slick-list {
        padding: 0 60px !important;
    }
    .spacer-mobile {
        display: block;
    }
    .horizontal-line-150,
    .vertical-line-150,
    .spacer-desktop {
        display: none;
    }
    .vertical-line-100 {
        display: block;
        height: 100px;
        border: 1.25px solid #e0e0e0;
    }
    .horizontal-line-100 {
        display: block;
        width: 100px;
        height: 0px;
        border: 1.25px solid #e0e0e0;
    }
    .aptitude-report-page-wrapper {
        justify-content: flex-start;
    }
    #aptitude-quiz-timer {
        top: 63px !important;
        right: 35px !important;
    }
    .aptitude-quiz.react-quizzes-quizz {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #aptitude-quiz-content {
        max-width: 90% !important;
    }
    #aptitude-quiz-content.aptitude-quiz-report-summary {
        height: 480px !important;
        padding-top: 64px !important;
    }
    #aptitude-quiz-content .header {
        padding: 0 !important;
    }
    .aptitude-quiz-emoji {
        height: 80px !important;
        width: auto !important;
    }
    .aptitude-quiz-summary-header>div:first-child {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .aptitude-quiz-summary-header>div:nth-child(2) {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .universal-quiz-instruction {
        height: 60px !important;
        width: 60px !important;
    }
    .aptitude-quiz-summary-header {
        padding-bottom: 28px !important;
    }
    .horizontal-divider {
        padding: 16px auto !important;
    }
    .universal-quiz-instructions-wrapper {
        display: grid;
        grid-template-columns: 117px 1px 117px;
        justify-content: space-evenly;
        grid-row-gap: 36px;
        row-gap: 36px;
    }
    .aptitude-quiz-report-summary {
        grid-gap: 32px 0 !important;
        gap: 32px 0 !important;
        justify-content: space-between !important;
    }
    .universal-quiz-page-footer {
        display: block;
    }
    .universal-quiz-page-wrapper {
        background: unset;
    }
    #thank-you-modal.universal-quiz-popup .success-animation {
        margin-bottom: 50px;
    }
    .skip-button {
        float: right;
        font-family: Poppins;
        font-size: 14px;
        line-height: 18px;
        color: #333333 !important;
        margin-right: 16px;
    }
    .react-quizzes-quizz .question-options-div.radio-quiz {
        padding: 16px 16px 100px 16px !important;
        margin: 16px !important;
    }
    .question-counter {
        font-size: 16px !important;
        line-height: 18px !important;
        padding: 0 16px !important;
        margin: 24px 0 !important;
    }
    .aptitude-quiz-content .question-counter {
        padding: 0px 0px !important;
    }
    .universal-quiz-question-counter {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .slick-list {
        width: 100%;
    }
    .slick-current .universal-quiz-question-wrapper {
        -webkit-filter: none !important;
                filter: none !important;
    }
    .universal-quiz-question-wrapper {
        width: 90% !important;
    }
    .slick-arrow {
        display: none !important;
    }
    .quiz-score {
        width: 100%;
    }
    .quiz-score>div:nth-child(3) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .quiz-score>div:nth-child(2) {
        display: none;
    }
    .quiz-score>div:nth-child(4) {
        display: block;
        font-size: 40px;
        line-height: 40px;
        color: #f2994a;
    }
    .universal-quiz-summary {
        margin: 24px auto;
        width: 80%;
    }
    .radioImage {
        width: 130px;
    }
    .react-quizzes-quizz .radioImage img {
        max-height: 80px;
        margin: 0 auto;
    }
    #quiz-progess-section {
        background: url("https://d1dhn4cz1wmlqo.cloudfront.net/staging/ASSESSMENT_PROCESSOR/PS35IR_Rectangle 603.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom;
        height: 85px;
        position: relative;
    }
    #quiz-progess-section>p {
        position: absolute;
        bottom: 8px;
        left: 50%;
        -webkit-transform: translate(-50%);
                transform: translate(-50%);
        font-size: 12px;
        line-height: 10px;
        font-weight: 600;
        font-family: Poppins;
    }
    #quiz-progess-section>.progress-bar {
        position: relative;
        top: 15px;
    }
    #quiz-progess-section>.left-arrow {
        position: absolute;
        top: 15px;
        left: 20px;
    }
    #quiz-progess-section .right-arrow {
        position: absolute;
        top: 15px;
        right: 20px;
    }
    .progress-bar>div {
        width: 75% !important;
    }
    /* For mobile phones: */
    /* p img {
    height: 150px;
    width: 150px;
  } */
    .aptitude-quiz-question-option-wrapper .ant-radio-wrapper {
        width: 100% !important;
        margin: 0 !important;
    }
    .react-quizzes-quizz .ant-radio-wrapper {
        margin-left: 5%;
        margin-right: 5%;
    }
    #footerGraphic img {
        height: 50px;
    }
    .react-quizzes-quizz .radioImage div {
        font-size: 10px;
    }
    .reportContent {
        width: 350px;
    }
    #quiz-progess-section>div.progress-bar>div>div>span {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 12px;
        font-family: Poppins;
    }
    #thank-you-modal .success-animation {
        width: 100px;
        height: 100px;
    }
    #thank-you-modal.universal-quiz-popup .success-animation {
        margin-bottom: 0;
    }
    .lead-form-header {
        font-size: 24px !important;
        justify-content: center;
    }
    #lead-squared-form>div:nth-child(1) {
        flex-direction: column;
    }
    #lead-squared-form input[name="first_name"],
    #lead-squared-form input[name="last_name"] {
        width: 94%;
    }
    #lead-form-description {
        padding: 0 40px;
    }
    .horizontal-line {
        margin: 0 auto;
        width: 43.1px !important;
        border: 2px solid #efcf1b !important;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .double-input-asterik {
        position: absolute;
        top: 55%;
        right: 5%;
    }
    #top-wave .desktop {
        display: none;
    }
    #top-wave .mobile {
        display: block;
    }
    #report-wrapper {
        top: 140px;
    }
    .react-quizzes-quizz .question-options-div .ant-form-item-label label {
        font-size: 16px;
    }
    .report-content {
        padding: 32px 19.5px 8px 19.5px;
        max-width: 85%;
    }
    .report-content>div:nth-child(1) {
        width: 100%;
        position: relative;
        display: block;
    }
    .report-content>div:nth-child(1)>img {
        max-width: 100%;
    }
    #report-wrapper>div>div:nth-child(1)>p>span {
        margin-top: 16px;
    }
    .report-content>div:nth-child(1)>.content {
        margin-right: 0;
        max-width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
    }
    #report-wrapper .report-progress-bar {
        margin-top: 16px;
        height: 40px;
    }
    .scroll-down-arrow-animation>div {
        height: 40px !important;
        width: 40px !important;
    }
    #report-wrapper>div>div:nth-child(2)>div.report-progress-bar>div>span {
        line-height: 30px;
    }
    .report-content>div:nth-child(2) {
        margin: 0;
    }
    .report-content>div:nth-child(2)>p {
        line-height: 20px;
        font-size: 14px;
        margin: 16px auto;
        padding: 0;
    }
    #top-wave>.report-title {
        font-size: 16px;
        line-height: 1.2;
    }
    #lead-squared-form-wrapper {
        padding: 0 !important;
        width: 100%;
        overflow: hidden;
    }
    .react-quizzes-quizz.aptitude-quiz .ant-radio-group {
        grid-template-columns: 1fr !important;
        justify-items: center;
    }
    .radioImageOptions>.ant-radio-group {
        grid-template-columns: 1fr 1fr !important;
        justify-content: center;
    }
    .react-quizzes-quizz .question-options-div.radio-quiz .ant-radio-wrapper {
        margin: 0;
    }
    .react-quizzes-quizz .ant-radio-group {
        grid-template-columns: auto !important;
    }
    .radioImageOptions>.ant-radio-group {
        grid-template-columns: 1fr 1fr !important;
    }
    #lead-squared-form>.form-description>div:nth-child(1) {
        font-size: 24px;
        text-align: center;
        line-height: 36px;
    }
    #lead-squared-form>.form-description>div:nth-child(2) {
        text-align: center;
        font-size: 14px;
        line-height: 21px;
        width: 210px;
        margin: 0 auto;
    }
    #lead-squared-form>div:nth-child(3) {
        flex-direction: column;
    }
    .universal-quiz-instruction-title {
        font-size: 20px;
        line-height: 40px;
    }
    .universal-quiz-instruction-info {
        /* margin-top: 16px; */
        margin-top: 7px;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
    }
}

.ElementWrapper .ant-slider {
    display: none;
}

.question_title_quiz p img {
    max-width: 100%;
    max-height: -webkit-max-content;
    max-height: max-content;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}


/* Classes for Custom Slider : react-quizzes */

.ant-slider {
    margin-left: 25px;
}

.ant-slider-step {
    background-color: #aecbdf;
    height: 10px;
    border-radius: 40px;
}

.ant-slider-step::before {
    content: '';
    color: transparent;
    position: absolute;
    width: 20px;
    height: 10px;
    margin-left: -15px;
    border-radius: 40px;
    background-color: #aecbdf;
}

.ant-slider-step::after {
    content: '';
    color: transparent;
    position: absolute;
    width: 20px;
    height: 10px;
    left: 96%;
    border-radius: 40px;
    background-color: #aecbdf;
}

.ant-slider-dot {
    height: 20px;
    width: 2px !important;
    border-radius: 0px;
    border-width: 0px;
    background-color: #aecbdf;
    border-color: #aecbdf;
    margin-top: -3px;
}

.ant-slider-handle {
    background-color: #1c6ae4;
    height: 30px;
    width: 30px;
    margin-top: -10px;
    border-color: #fff;
    border-width: 3px;
}


/* .ant-slider-handle:focus {
  box-shadow: none;
  border-color: #fff;
} */

.ant-slider-mark {
    margin-top: 10px;
}

.ant-slider-mark-text {
    max-width: 65px;
}

@media only screen and (max-width: 767px) {
    .react-quizzes-quizz .question-options-div.radio-quiz .ant-form-item-label {
        margin-bottom: 0px;
    }
    .ant-slider-mark {
        width: 98%;
    }
}

@media (min-width: 767px) {
    .ant-slider-mark-text {
        min-width: 80px;
    }
}

@media (min-width: 1200px) {
    .aptitude-quiz-page-wrapper {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@font-face {font-family: "Gordita"; src: url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.eot"); src: url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.woff") format("woff"), url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.svg#Gordita") format("svg"); }
.slick-slide {
  width: 100%;
}

.slick-list{
  padding: 0 !important;
}

.main_aptitude_container {
  /* background: red; */
  margin: 0% 3% 3% 3%;
}
.instruction_top_text_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  /* background: red; */
}
.instruction_title {
  margin: 0;
  font-family: Poppins;
}
.instruction_extra_msg_text {
  margin: 0;
  font-family: Poppins;
  opacity: 0.6;
}
.instruction_all_box_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4% 0 0 0;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.instruction_per_box_bg_color {
  background: #ebffed;
}

.instruction_per_box_bg_color1 {
  background: #fff6e8;
}
.instruction_box_same_style {
  text-align: left;
  width: 29.33%;
  margin: 0 3% 3% 0;
  padding: 1% 2% 2% 2%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.instruction_title_number_text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.instrution_number_text_color {
  color: #44db53;
}
.instrution_number_text_same_style {
  font-size: 50px;
  font-weight: 600;

  letter-spacing: -0.5px;
  margin: 0;
  font-family: Poppins;
}
.instruction_correct_box_img {
  margin: 7% 0 0 0;
}
.instruction_img_correct {
}
.instrution_black_number_text {
  background-color: #091b28;
  color: #fff;
  margin: 0 0 0 4%;
  padding: 1% 4% 1% 4%;
  text-align: center;
  border-radius: 8px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: Poppins;
  text-transform: capitalize;
}
.instruction_number_text_one {
  margin: 7% 0 0 0;
  font-size: 20px;
  color: #091b28;
  font-family: Poppins;
  text-transform: capitalize;
}

.instruction_number_text_two {
  margin: 0;
  font-size: 20px;
  color: #091b28;
  font-family: Poppins;
  text-transform: capitalize;
}
.proceed_btn {
  background: rgb(18, 115, 183);
  width: 40%;
  padding: 2%;
  border-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 5% 0 2% 0;
}
.instruction {
}

.vector_img {
}
@media screen and (max-width: 767px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 6% 3% 6%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .instruction_extra_msg_text {
    margin: 10px 0 0 0;

    letter-spacing: 0px;

    text-align: justify;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #091b28;
    opacity: 0.6;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 6% auto 2% auto;
    width: 100%;
    align-items: flex-start;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 46.33%;
    margin: 0 4px 15px 4px;
    padding: 3% 4% 4% 5%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 120px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 32px;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 34%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 4%;
    padding: 2.5% 6% 1.5% 7%;
    border-radius: 7px;
    font-size: 7px;
    text-align: center;
  }
  .instruction_number_text_one {
    margin: 4% 0 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 100%;
    padding: 0%;
    height: 45px;

    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    /* margin: 20px 0 2% 0; */

    line-height: 18px;

    margin: 15px 0 2% 0;

    background: #1273b7;
    border-radius: 22.5px;
  }
}

@media screen and (min-width: 768px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 15px 15px 15px;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .instruction_extra_msg_text {
    margin: 1% 0 0 0;
    opacity: 0.6;
    letter-spacing: 0px;
    line-height: 16px;
    font-size: 13px;
    text-align: justify;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 6% auto 0 auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 46.33%;
    margin: 0 5px 3% 7px;
    padding: 3% 4% 4% 5%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 144px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.5px;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 26%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 5%;
    padding: 2.5% 4% 1.5% 4%;
    border-radius: 7px;
    font-size: 8px;
    text-align: center;
  }
  .instruction_number_text_one {
    margin: 4% 0 0 0;
    font-size: 18px;
    line-height: 22px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 100%;
    height: 45px;
    padding: 0;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 17px;
    margin: 20px 0 15px 0;
    border-radius: 22.5px;
  }
}

@media screen and (min-width: 991px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 4% 0% 4%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-family: Poppins;
    font-size: 24px;
    line-height: 33px;
  }
  .instruction_extra_msg_text {
    margin: 0;
    font-family: Poppins;
    opacity: 0.6;
    line-height: 22px;
    font-size: 17px;
    font-weight: 300;
    color: rgba(9, 27, 40, 1);
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 4% auto 0 auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 30.33%;
    margin: 0 4px 14px 11px;;
    padding: 3% 2% 2% 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 35px;
    font-weight: 600;

    letter-spacing: -0.5px;
    margin: 0;
    font-family: Poppins;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 28%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 4%;
    padding: 1.5% 6% 1.5% 6%;
    border-radius: 8px;
    letter-spacing: 1px;
    font-size: 11px;
  }
  .instruction_number_text_one {
    margin: 13% 0 7px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #091b28;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 50%;
    padding: 0%;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 22px auto 30px auto;
    display: block;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    height: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .vector_img {
    position: absolute;
  }
  .instruction {
    width: 276px;
    height: 242px;
  }

  .main_aptitude_container {
    /* background: red; */
    margin: 0% 4% 3% 4%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-size: 27px;
  }
  .instruction_extra_msg_text {
    margin: 1.5% 0 0 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    color: #091b28;
    opacity: 0.7;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 4% auto 0px auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 30.33%;
    margin: 0 7px 3% 10px;
    padding: 25px 15px 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 52px;
    line-height: 60px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin: 0;
    font-family: Poppins;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
  }
  .instrution_black_number_text {
    margin: 0 0 0 5%;
    padding: 0;
    border-radius: 11px;
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 15px;
    min-width: 60px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .instruction_number_text_one {
    margin: 56px 0 0 0;
    font-size: 23px;
    line-height: 32px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 50%;
    padding: 2%;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5% auto 2% auto;
    height: 60px;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
}

.question_timer_container {
  background-color: #ff4c4c;
  display: flex;
  align-items: center;
  width: 12%;
  justify-content: center;
  border-radius: 30px;
  height: 45px;
  margin: 0 0 0 2%;
}
.out_of_title_text {
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 0 10%;
}

@media screen and (max-width: 767px) {
  .question_blue_container {
    width: 80px;
    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
    display: flex;

    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    width: 85px;
    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  }
  .timer_img {
    width: 16%;
  }
  .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 768px) {
  .question_blue_container {
    width: 88px;
    border-radius: 26px;
    height: 34px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    width: 27%;

    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  }
  .timer_img {
    width: 16%;
  }
  .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 991px) {
  .question_blue_container {
    width: 120px;
    border-radius: 26px;
    height: 45px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 18%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  }
  .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  }
}

@media screen and (min-width: 1200px) {
  .question_blue_container {
    width: 126px;
    border-radius: 26px;
    height: 50px;
    margin: 0 0 0 0%;
    display: flex;
    background-color: #ff4c4c;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 14%;
    justify-content: center;
    border-radius: 30px;
    height: 50px;
    margin: 0 0 0 2%;
  }
  .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  }
}

.main_quiz_container {
  background: rgb(78, 182, 254);
  background: linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 0%,
    rgba(28, 139, 217, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4eb6fe",endColorstr="#1c8bd9",GradientType=1);
}
.white_center_container {
  position: relative;
  margin: 0% auto 0% auto;
  padding: 8% 0 8% 0;
  /* left: 50%;
    transform: translate(-50%, 0%); */
  width: 75%;
}
.white_container {
  background: #fff;
  padding: 1%;
  border-radius: 20px;
  /* margin: 10% 0 10% 0; */
}
.intro_orange_btn_option {
  font-size: 19px;
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 40%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(18, 115, 183);
  border-radius: 20px;
  margin: 3% auto 0 auto;
}
/* .intro_orange_btn_option:hover {
  background: #ff9900cc;
  color: rgb(0, 0, 0);
} */
.circular_timer_container {
  display: flex;
  justify-content: center;
}
.circulat_timer_content {
  width: 100px;
  height: 100px;
  background: lightyellow;
  margin-top: -5%;
}

.quiz_blue_container {
  background-color: #eef8ff;
  margin: 5% 3% 3% 3%;
  padding: 0 3% 3% 3%;
  position: relative;
  border-radius: 20px;
}
.question_head_timer_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  top: -23px;
}
.question_head_container {
  background-color: #091b28;
  display: flex;
  color: #fff;
  font-size: 20px;
  font-family: "POPPINS";
  align-items: center;

  justify-content: center;
  border-radius: 30px;
  height: 45px;
}
/* .question_timer_container {
  background-color: #ff4c4c;
  display: flex;
  align-items: center;
  width: 12%;
  justify-content: center;
  border-radius: 30px;
  height: 45px;
  margin: 0 0 0 2%;
} */
.out_of_timer_text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.quiz_title_text {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  margin: 0 0 0 3%;
}
.yellow_quiz_text {
  color: #ffd600;
  font-size: 22px;
  margin: 0 3px 0 8px;
  font-family: Poppins;
  font-weight: 600;
}
.out_of_text_quiz {
  opacity: 0.7;
  color: #fff;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
}
/* .out_of_title_text {
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 0 10%;
} */
.question_title_quiz {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 200;
}
.option_container_main_quiz {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  flex-direction: row;
}
.option_coponent_container_quiz {
  display: flex;
  flex-direction: row;
  margin: 2% 0 0 0;
  width: 50%;
  border: 0;
  outline: none;
  background-color: transparent;
  justify-content: center;
  cursor: pointer;
}
.option_blue_part_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d8efff;
  width: 90%;

  border-radius: 10px;
  min-height: 45px;
}

.option_head_text_quiz {
  font-size: 20px;
  color: #091b28;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0 0 0 3%;
}

.option_white_part_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  width: 90%;
  margin: 0 0 0 15px;
  min-height: 45px;
  border-radius: 10px;
  min-height: 45px;
  text-align: left;
}
.option_ans_text_quiz {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;

  color: #333;
  text-align: left;
  min-height: 45px;
  font-size: 14px;
  margin: 0 0 0 5%;
  display: flex;
  align-items: center;
}
.next_btn_container {
  display: flex;
  justify-content: space-evenly;
  margin: 5% 0 0 0;
}

.same_btn_quiz {
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 48%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
}
.prev_btn_quiz {
  background: #091b28;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.next_btn_quiz {
  background: #1273b7;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
/* .prev_btn_quiz:hover {
  background: #202020;
}
.next_btn_quiz:hover {
  background: rgb(46, 134, 197);
} */

/* after click  */

.option_blue_part_container_color {
  background: #27cb7c;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  border-radius: 10px;
  min-height: 45px;
}
.option_head_text_quiz_color {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0 0 0 3%;
  color: #fff;
}
.option_white_part_container_color {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d9ffed;
  width: 90%;
  margin-left: auto;
  min-height: 45px;
  border-radius: 10px;
}
.timer_content {
  top: 15%;
  right: 10%;
  /* height: 20px; */
  width: 60%;
  margin: 0% auto 0 auto;
  position: absolute;
  z-index: 1;
}
/* .prev_btn_quiz:hover {
  background: #202020;
  color: #fdaf39;
}
.next_btn_quiz:hover {
  background: rgb(46, 134, 197);
  color: #202020;
} */
.orange_label_container {
  background-color: #fff6e8;
  margin: 5% 3% 3% 3%;
  padding: 0 3% 3% 3%;
  border-radius: 20px;
  position: relative;
}
.orange_label_next_btn {
  font-size: 19px;
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 40%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  background: #ff9b00;
  border-radius: 20px;
  margin: 3% auto 0 auto;
}
.orange_label_prev_btn_quiz {
  background: #091b28;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.orange_label_next_btn_quiz {
  background: #ff9b00;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

@media screen and (max-width: 767px) {
  .orange_label_container {
    margin: 50px 3% 3% 3%;
    padding: 0 3% 0% 3%;

    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .orange_label_next_btn {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;

    cursor: pointer;

    margin: 3% auto 0 auto;
  }
  .orange_label_prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .orange_label_next_btn_quiz {
    background: #ff9b00;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .timer_content {
    top: 75px;
    left: 58%;
    /* height: 20px; */
    width: 100px;
    margin: 0;
    position: absolute;
    z-index: 1;
    /* background-color: red; */
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 21% 0 40% 0;
    width: 90%;
  }
  .intro_orange_btn_option {
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;

    width: 100%;

    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    background: rgb(18, 115, 183);
    border-radius: 20px;
    margin: 3% auto 0 auto;
  }

  .white_container {
    background: #fff;
    padding: 1%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    margin-top: -19%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 50px 3% 10px 3%;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_head_timer_container {
    top: -15px;
    left: -45px;
  }
  .question_head_container {
    min-width: 125px;
    width: 125px;
    border-radius: 26px;
    height: 33px;
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
  }
  /* .question_timer_container {
    width: 45%;

    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  } */
  /* .timer_img {
    width: 16%;
  } */
  .out_of_timer_text {
    font-size: 18px;
  }
  .quiz_title_text {
    margin: 30px 0 14% 4%;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .yellow_quiz_text {
    margin: 0 2px 0 6px;
    line-height: 10px;
    font-weight: 700;
    font-size: 15px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 10px;
  }
  /* .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    padding: 15px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    color: #091b28;
    line-height: 17px;
    text-align: justify;
  }
  .option_container_main_quiz {
    flex-direction: column;
    margin: 8% 0 6% 0;
  }
  .option_coponent_container_quiz {
    margin: 2% 0 2% 0;
    width: 100%;
  }
  .option_blue_part_container {
    width: 100%;
  }

  .option_head_text_quiz {
    font-size: 16px;
    margin: 0 0 0 5%;
  }

  .option_white_part_container {
    width: 90%;
  }
  .option_ans_text_quiz {
    font-size: 14px;
    padding: 2% 0;
    margin: 0 0 0 5%;
  }
  .next_btn_container {
    display: flex;
    position: relative;
    margin: 5% 0 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 15px auto 10px auto;
    width: 100%;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    width: 48%;
  }
  .prev_btn_quiz {
  }
  .next_btn_quiz {
  }

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;

    width: 100%;
  }
  .option_head_text_quiz_color {
    font-size: 16px;

    margin: 0 0 0 5%;

    color: #fff;
  }
  .option_white_part_container_color {
    width: 86%;
  }
}

@media screen and (min-width: 768px) {
  .orange_label_container {
    margin: 60px 3% 3% 3%;
    padding: 0 3% 15px 3%;

    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .orange_label_next_btn {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;

    cursor: pointer;

    margin: 3% auto 0 auto;
  }
  .orange_label_prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .orange_label_next_btn_quiz {
    background: #ff9b00;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .timer_content {
    top: 82px;
    right: -11%;
    /* height: 20px; */
    width: 60%;
    margin: 0% auto 0 auto;
    position: absolute;
    z-index: 1;
  }
  .white_center_container {
    width: 55%;
    padding: 11% 0 8% 0;
  }
  .intro_orange_btn_option {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 3% auto 0 auto;
  }

  .white_container {
    background: #fff;
    padding: 1%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    margin-top: -14%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 60px 3% 15px 3%;
    padding: 0 3% 15px 3%;
    border-radius: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_head_timer_container {
    top: -18px;
    left: -8px;
  }
  .question_head_container {
    color: #fff;
    font-size: 14px;
    width: 125px;
    border-radius: 26px;
    height: 34px;
    position: relative;
    left: -63px;
  }

  .out_of_timer_text {
    font-size: 18px;
  }
  .quiz_title_text {
    font-size: 22px;
    margin: 4% 0 8% 4%;
  }
  .yellow_quiz_text {
    font-size: 15px;
    margin: 0 3px 0 8px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 10px;
  }
  /* .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #091b28;
    line-height: 24px;
  }
  .option_container_main_quiz {
    margin: 2% 0 0% 0;

    flex-direction: column;
  }
  .option_coponent_container_quiz {
    margin: 2% 0 2% 0;
    width: 100%;
  }
  .option_blue_part_container {
    width: 100%;
  }

  .option_head_text_quiz {
    font-size: 19px;
    margin: 0 0 0 4%;
  }

  .option_white_part_container {
    width: 89%;
  }
  .option_ans_text_quiz {
    font-size: 16px;
    margin: 0 0 0 3%;
    padding: 2% 0;
  }
  .next_btn_container {
    margin: 5% 0 0 0;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;
    border: 0;
    text-transform: uppercase;

    font-family: "Poppins";

    letter-spacing: 1px;
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .prev_btn_quiz {
  }
  .next_btn_quiz {
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;

    width: 100%;
  }
  .option_head_text_quiz_color {
    font-size: 16px;

    margin: 0 0 0 4%;

    color: #fff;
  }
  .option_white_part_container_color {
    width: 89%;
  }
}

@media screen and (min-width: 991px) {
  .orange_label_container {
    margin: 80px 3% 3% 3%;
    padding: 0 3% 2% 3%;

    min-height: 500px;
  }
  .orange_label_next_btn {
    font-size: 19px;

    height: 50px;

    width: 50%;
  }
  .orange_label_prev_btn_quiz {
  }
  .orange_label_next_btn_quiz {
  }
  .timer_content {
    top: 97px;
    right: -12%;
  }
  .intro_orange_btn_option {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 10px auto 0 auto;
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 8% 0 8% 0;
    /* left: 50%;
      transform: translate(-50%, 0%); */
    width: 75%;
  }
  .intro_orange_btn {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 40%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
    background: #ff9b00;
    border-radius: 20px;
    margin: 3% auto 0 auto;
  }
  .white_container {
    background: #fff;
    padding: 0%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    width: 100px;
    height: 100px;
    background: lightyellow;
    margin-top: -7%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 80px 3% 3% 3%;

    padding: 0 3% 2% 3%;

    min-height: 500px;
    border-radius: 20px;
  }
  .question_head_timer_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;

    top: -23px;
    left: -21px;
  }
  .question_head_container {
    background-color: #091b28;
    display: flex;
    color: #fff;
    font-size: 20px;
    font-family: "POPPINS";
    align-items: center;
    width: 160px;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
  }
  /* .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 18%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  } */
  .out_of_timer_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .quiz_title_text {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0 0 0 3%;
  }
  .yellow_quiz_text {
    color: #ffd600;
    font-size: 22px;
    margin: 0 3px 0 8px;
    font-family: Poppins;
    font-weight: 600;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
  }
  /* .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-size: 17px;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 22px;
  }
  .option_container_main_quiz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 2% 0 5% 0;
    flex-direction: row;
  }
  .option_coponent_container_quiz {
    display: flex;
    flex-direction: row;
    margin: 1% 0 0 0;
    width: 50%;
    padding: 1% 0px;
    border: 0;
    outline: none;
    background-color: transparent;
    justify-content: center;
    cursor: pointer;
  }
  .option_blue_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d8efff;
    width: 94%;
    border-radius: 10px;
    min-height: 50px;
  }

  .option_head_text_quiz {
    font-size: 20px;

    margin: 0 0 0 6%;
  }

  .option_white_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    width: 82%;
    margin-left: auto;
    min-height: 50px;
    border-radius: 10px;
  }
  .option_ans_text_quiz {
    font-size: 17px;
    font-family: "Poppins";
    font-weight: 500;
    margin: 0 0 0 5%;
    color: #333;
    overflow: auto;
  }
  .next_btn_container {
    display: flex;
    justify-content: space-evenly;
    margin: 5% 0 0 0;
  }

  .same_btn_quiz {
    width: 48%;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 0;
    color: #fff;
    font-family: "Poppins";
    cursor: pointer;

    padding: 0%;

    text-transform: uppercase;
    letter-spacing: 1px;

    display: block;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    height: 50px;
  }
  .prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .next_btn_quiz {
    background: #1273b7;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 94%;
    border-radius: 10px;
    min-height: 50px;
  }
  .option_head_text_quiz_color {
    font-size: 20px;

    margin: 0 0 0 6%;
  }
  .option_white_part_container_color {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d9ffed;
    width: 82%;
    margin-left: auto;
    min-height: 50px;
    border-radius: 10px;
    overflow: auto;
  }
}

@media screen and (min-width: 1200px) {
  .orange_label_container {
    margin: 8% 3% 3% 3%;
    padding: 0 3% 2% 3%;

    min-height: 600px;
  }
  .orange_label_next_btn {
    font-size: 22px;

    height: 60px;

    width: 50%;
  }
  .orange_label_prev_btn_quiz {
  }
  .orange_label_next_btn_quiz {
  }
  .timer_content {
    top: 82px;
    right: -12%;
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 8% 0 8% 0;
    /* left: 50%;
      transform: translate(-50%, 0%); */
    width: 75%;
  }
  .white_container {
    background: #fff;
    padding: 0%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    width: 100px;
    height: 100px;
    background: lightyellow;
    margin-top: -7%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 8% 3% 3% 3%;
    padding: 0 3% 2% 3%;
    border-radius: 20px;
    min-height: 600px;
  }
  .question_head_timer_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;

    top: -24px;
    left: -25px;
  }
  .question_head_container {
    background-color: #091b28;
    display: flex;
    color: #fff;
    font-size: 21px;
    font-family: "POPPINS";
    align-items: center;
    width: 170px;
    justify-content: center;
    border-radius: 30px;
    height: 50px;
  }
  /* .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 14%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  } */
  .out_of_timer_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .quiz_title_text {
    font-size: 29px;
    font-weight: 600;
  }
  .yellow_quiz_text {
    font-size: 22px;
    margin: 0 3px 0 8px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
  }
  /* .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-size: 22px;
    line-height: 27px;
  }
  .option_container_main_quiz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0% 0 0% 0;
    flex-direction: row;
  }
  .option_coponent_container_quiz {
    display: flex;
    flex-direction: row;
    margin: 5px 0 0 0;
    width: 50%;
    border: 0;
    outline: none;
    background-color: transparent;
    justify-content: center;
    cursor: pointer;
  }
  .option_blue_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d8efff;
    width: 94%;

    border-radius: 10px;
    min-height: 60px;
  }

  .option_head_text_quiz {
    font-size: 22px;

    margin: 0 0 0 5%;
  }

  .option_white_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    width: 85%;
    margin-left: auto;
    min-height: 60px;

    border-radius: 10px;
  }
  .option_ans_text_quiz {
    font-size: 22px;
    font-family: "Poppins";
    font-weight: 500;
    margin: 0 0 0 3%;
    color: #333;
  }
  .next_btn_container {
    display: flex;
    justify-content: space-evenly;
    margin: 2% 0 0 0;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;

    color: #fff;
    font-family: "Poppins";
    font-weight: 700;

    cursor: pointer;
    border: 0;

    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5% auto 0% auto;
    height: 60px;
    font-size: 22px;
    line-height: 32px;
  }
  .prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .next_btn_quiz {
    background: #1273b7;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 94%;
    border-radius: 10px;
    min-height: 60px;
  }
  .option_head_text_quiz_color {
    font-size: 24px;
    margin: 0 0 0 5%;
  }
  .option_white_part_container_color {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d9ffed;
    width: 85%;
    margin-left: auto;
    min-height: 60px;
    border-radius: 10px;
  }
  .intro_orange_btn_option {
    font-size: 22px;
    display: flex;
    flex-direction: row;
    height: 60px;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 19px auto 0 auto;
  }
}

.pop_box_sucessfull_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.sucessfull_img_container {
  width: 250px;
}
.sucessfull_img_self {
  width: 100%;
}
.test_completed_text {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}
.text_succes_extra_content {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 300;
  color: #fff;
  line-height: 22px;
  margin: 4% 0 5% 0;
  width: 81%;
}
.sucess_extra_content_highlight {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.ans_extra_conetent_yellow {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #ffd600;
}
.success-btn-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.done_btn {
  border: 0;
  outline: none;
  background: #fff;
  color: #1273b7;
  width: 60%;
  border-radius: 10px;
  height: 40px;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 1% 2% 8% 0;
  cursor: pointer;
}
.profile_navigation_btn {
  border: 0;
  outline: none;
  background:#1273b7;
  color: #fff;
  width: 60%;
  border-radius: 10px;
  height: 40px;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 1% 2% 8% 2%;
  cursor: pointer;
}

.profile_navigation_from_report_page {
  position: absolute;
  right: 4px;
  width: 20%;
  top: 0px;
  z-index: 1;
  outline: none;
  border: none;
  background: #fff;
  color: #1273b7;
  border-radius: 10px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 1% 0 8% 2%;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

@media screen and (max-width: 767px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 150px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 1% 0 4% 0;
    width: 95%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 13px;
    letter-spacing: 1px;
    margin: 3% 1% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color: #fff;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 13px;
    letter-spacing: 1px;
    margin: 3% 0 8% 1%;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 150px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 1% 0 4% 0;
    width: 95%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 15px;
    letter-spacing: 1px;
    margin: 3% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color:  #fff;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 15px;
    letter-spacing: 1px;
    margin: 3% 0 8% 2%;
    cursor: pointer;
  }
  .profile_navigation_from_report_page {
    width: 10%;
  }
}

@media screen and (min-width: 991px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 250px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 4% 0 5% 0;
    width: 81%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color: #fff;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 0 8% 2%;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 250px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 4% 0 5% 0;
    width: 81%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color:  #fff;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 0 8% 2%;
    cursor: pointer;
  }
}

.modal_styles {
  transition: all 10s ease-in-out; /*make it smooth*/
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 2% 2% 0 2%;
  width: 50%;
  z-index: 1000;
  max-height: 650px;
  overflow-y: auto;
  border-radius: 8px;
  background: rgb(78, 182, 254);
  background: linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 100%,
    rgba(28, 139, 217, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4eb6fe",endColorstr="#1c8bd9",GradientType=1);
}
.overlay_styles {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.close_btn_text_container {
  display: flex;
  justify-content: flex-end;
}
.modal_close_btn_modal {
  border: none;
  background: #01bc82;
  padding: 1%;
  border-radius: 50%;
  text-align: center;
  display: flex;
  outline: none;
  margin: 0 0 2% auto;
}
.fill_the_details_txt {
  font-size: 35px;
  font-family: PSMed;
  color: #01bc82;
  letter-spacing: 2px;
}
.form_container {
  margin: 5% 0 5% 0;
}
.title_input_field_reset_btn_container {
  margin: 1% 0 5% 0;
}
.input_title {
  font-size: 25px;
  font-family: "PSReg";
  letter-spacing: 0.5px;
  text-align: left;
}
.full_name_container {
  background: #f6faff;
  width: 100%;
  padding: 2% 1% 2% 1%;
}
.full_name_input_field {
  border: 0px;
  outline: none;
  width: 98%;
  font-family: "PSREG";
  letter-spacing: 1px;
  font-size: 20px;
  background: #f6faff;
}
.btn_reset_cross {
  border: 0px;
  outline: none;
  background-color: transparent;
}
.btn_reset_cross {
  border: 0px;
  outline: none;
  background-color: transparent;
}
.btn_reset_cross1 {
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 0 2% 0 0;
}
.select_box_container_under {
  outline: none;
  border: 0;
  padding: 18% 3% 18% 3%;
  font-size: 20px;
  background: #f6faff;
}
.title_input_field_reset_btn_container1 {
  margin: 3% 0 5% 0;
}
.contact_number_reset_btn_container1 {
  display: flex;
  width: 100%;
  margin: 0 0% 0 3%;
  background: #f6faff;
  padding: 2% 0 2% 0;
}
.contact_number_input_field {
  border: 0px;
  outline: none;
  width: 100%;
  font-family: "PSREG";
  letter-spacing: 1px;
  font-size: 20px;
  background: #f6faff;
  padding: 0 2% 0 3%;
}
.select_box_number_reset_btn_container {
  display: flex;
  width: 100%;
  padding: 1% 0 1% 0;
  /* border: 1px solid #000; */
}
.select_box_container_under1 {
  width: 100%;
  border: 0;
  outline: none;
  font-size: 21px;
  padding: 3% 0% 3% 0;
  background: #f6faff;
  margin: 2% 0 0 0;
}
.modal_btn_txt_submit {
  background: #1273b7;
  width: 100%;
  padding: 1% 0 1% 0;
  color: #fff;
  font-family: "PSMED";
  letter-spacing: 1px;
  font-size: 27px;
  outline: none;
  border: 0;
  border-radius: 10px;
}
.modal_btn_txt_submit:hover {
  background: #01bc82;
  color: #fff;
}
.close_btn_dialog {
  background: transparent;
  border: 0;
  outline: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md_close_btn_icon1 {
  font-size: 22px;
}
.close_btn_dialog:hover {
  background: rgba(255, 255, 255, 0.39);
  border: 0;
  outline: none;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .modal_styles {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 7% 5% 4% 5%;
    width: 90%;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 4px;
  }

  .overlay_styles {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  .close_btn_text_container {
    display: flex;
  }
  .modal_close_btn_modal {
    border: none;
    background: #01bc82;
    padding: 1%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    outline: none;
    margin: 0 0 2% auto;
    align-items: center;
    flex-direction: row;
    height: 24px;
    width: 23px;
  }
  .fill_the_details_txt {
    font-size: 21px;
    letter-spacing: 1px;
  }
  .form_container {
    margin: 5% 0 5% 0;
  }
  .title_input_field_reset_btn_container {
    margin: 1% 0 5% 0;
  }
  .input_title {
    font-size: 16px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_container {
    background: #f6faff;
    width: 100%;
    padding: 2% 1% 2% 1%;
  }
  .full_name_input_field {
    border: 0px;
    outline: none;
    width: 94%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 14px;
    background: #f6faff;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    font-size: 10px;

    background-color: transparent;
  }
  .btn_reset_cross1 {
    border: 0px;
    outline: none;
    background-color: transparent;
    padding: 0 2% 0 0;
    font-size: 10px;
  }
  .select_box_container_under {
    font-size: 12px;
  }
  .title_input_field_reset_btn_container1 {
    margin: 3% 0 5% 0;
  }
  .contact_number_reset_btn_container1 {
    display: flex;
    width: 100%;
    margin: 0 0% 0 3%;
    background: #f6faff;
    padding: 2% 0 2% 0;
  }
  .contact_number_input_field {
    border: 0px;
    outline: none;
    width: 100%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 13px;
    background: #f6faff;
    padding: 0 2% 0 3%;
  }
  .select_box_number_reset_btn_container {
    display: flex;
    width: 100%;
    padding: 1% 0 1% 0;
    /* border: 1px solid #000; */
  }
  .select_box_container_under1 {
    font-size: 12px;
  }
  .modal_btn_txt_submit {
    background: #1273b7;
    width: 100%;
    padding: 3% 0 3% 0;
    color: #fff;
    font-family: "PSMED";
    letter-spacing: 0.8px;
    font-size: 16px;
    outline: none;
    border: 0;
    border-radius: 4px;
  }
  .modal_btn_txt_submit:hover {
    background: #01bc82;
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .modal_styles {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 3% 3% 0 3%;
    width: 50%;
    z-index: 1000;
    max-height: 460px;
    overflow-y: auto;
    border-radius: 8px;
  }

  .overlay_styles {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  .close_btn_text_container {
    display: flex;
  }
  .modal_close_btn_modal {
    border: none;
    background: #01bc82;
    padding: 1%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    outline: none;
    margin: 0 0 2% auto;
  }
  .fill_the_details_txt {
    font-size: 30px;
    font-family: PSMed;
    color: #01bc82;
    letter-spacing: 1px;
  }
  .form_container {
    margin: 5% 0 5% 0;
  }
  .title_input_field_reset_btn_container {
    margin: 1% 0 5% 0;
  }
  .input_title {
    font-size: 17px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_container {
    background: #f6faff;
    width: 100%;
    padding: 2% 1% 2% 1%;
  }
  .full_name_input_field {
    border: 0px;
    outline: none;
    width: 96%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 15px;
    background: #f6faff;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross {
    font-size: 13px;
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross1 {
    border: 0px;
    outline: none;
    background-color: transparent;
    padding: 0 2% 0 0;

    font-size: 12px;
  }
  .select_box_container_under {
    outline: none;
    border: 0;
    padding: 18% 3% 18% 3%;
    font-size: 14px;
    background: #f6faff;
  }
  .title_input_field_reset_btn_container1 {
    margin: 3% 0 5% 0;
  }
  .contact_number_reset_btn_container1 {
    display: flex;
    width: 100%;
    margin: 0 0% 0 3%;
    background: #f6faff;
    padding: 2% 0 2% 0;
  }
  .contact_number_input_field {
    border: 0px;
    outline: none;
    width: 100%;
    font-family: "PSREG";
    letter-spacing: 1px;

    font-size: 14px;

    background: #f6faff;
    padding: 0 2% 0 3%;
  }
  .select_box_number_reset_btn_container {
    display: flex;
    width: 100%;
    padding: 1% 0 1% 0;
    /* border: 1px solid #000; */
  }
  .select_box_container_under1 {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 13px;
    padding: 3% 0% 3% 0;
    background: #f6faff;
    margin: 2% 0 0 0;
  }
  .modal_btn_txt_submit {
    background: #1273b7;
    width: 100%;
    padding: 2% 0 2% 0;
    color: #fff;
    font-family: "PSMED";
    letter-spacing: 0px;
    font-size: 19px;
    outline: none;
    border: 0;
    border-radius: 5px;
  }
  .modal_btn_txt_submit:hover {
    background: #01bc82;
    color: #fff;
  }
}

@media screen and (min-width: 991px) {
  .modal_styles {
    width: 50%;

    max-height: 650px;
  }
}

@media screen and (min-width: 1200px) {
  .modal_styles {
    width: 50%;

    max-height: 650px;
  }
  .input_title {
    font-size: 24px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_input_field {
    font-size: 19px;
  }
  .select_box_container_under {
    font-size: 19px;
  }
  .contact_number_input_field {
    font-size: 19px;
  }
  .select_box_container_under1 {
    font-size: 19px;
  }
}

.student-block {
  background: rgba(26, 100, 214, 0.08);
  /*background: rgba(71, 33, 95, 1);*/
  color: #000;
  padding: 30px;
  padding-bottom: 60px;
}
.student-block .student-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.student-info{
  margin-bottom: 20px;
}

.student-block h3 {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 0;

  /* identical to box height */

  color: #000;

  opacity: 0.5;
}
.student-block p {
  margin: 0;
}
.student-block .name {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: #333333;
}
.student-block .email {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  /* identical to box height */
  letter-spacing: 0.01em;

  color:#333333;
}
.student-block .card {
  background: linear-gradient(180deg, #FFFFFF 0%, #DAE3F1 100%);
  /*width: 22.5%;*/
  min-height: 250px;
  border-radius: 22px;
  margin: 0;
  display: flex;
  align-items: center;
  border: none;
}
.student-block .card:hover{
  background: linear-gradient(180deg, #1A64D6 0%, #1A64D6 100%);
}
.student-block .card.aptitude {
  background: #1A64D6;
}
.student-block .card .card-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.student-block .card .card-body .head {
  font-family: "Gordita", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #BDBDBD;
  opacity: 0.6;
}
.student-block .card .card-body .value {
  font-family: "Gordita", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.01em;
  color: #333333;
}
.student-block .card .card-body .icon{
  font-size: 30px;
  margin-top: 10px;
}
.student-block .card .card-body .icon img{
  width: 30px;
  height: 30px;
}
.student-block .card.aptitude .card-body .head {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
}
.student-block .card.aptitude .card-body .value {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
}
.student-block .card.aptitude .card-body .icon {
  color: #47215f;
  font-size: 30px;
  margin-top: 10px;
}
.report-block {
  position: relative;
  background: #fbfafe;
}
.report-block .card {
  position: relative;
  top: -30px;
  background: #fff;
  border: none;
  border-radius: 20px;
}

.report-block h3 {
  font-family: "Gordita", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}
.report-block .table-responsive {
  margin-top: 30px;
  padding: 0 30px;
}
.report-block td {
  vertical-align: baseline;
}
.report-block .subject {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  color: #18161f;
}
.report-block .score {
  width: 50%;
}
.report-block .score .progress {
  background: #f9f9fc;
  border: none;
  border-radius: 500px;
  height: 20px;
}
.report-block .score .progress-bar {
  /*background: linear-gradient(180deg, #00b8ff 0%, #57d0ff 47.92%, #89deff 100%);*/
  border-radius: 500px;
}
.report-block .remarks .badge {
  position: relative;
  top: -5px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;

  /*background-color: #f5e8f7;*/
  border-radius: 20px;
  padding: 5px 10px;
}

.card-body h3{
  margin-top: 30px !important;
  font-weight: 800;
}

@media (max-width: 768px) {
  .student-block .card {
    margin-bottom: 10px;
    min-height: 160px;
  }
  .student-block .card .card-body .head {
    font-family: "Gordita", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #BDBDBD;
    opacity: 0.6;
  }
  .student-block .card .card-body .value {
    font-family: "Gordita", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #333333;
  }
  .student-block .card .card-body .icon {
    font-size: 30px;
    margin-top: 0px;
  }
  .student-block .card .card-body .icon img{
    width: 30px;
    height: 30px;
  }
  .student-block .card.aptitude .card-body .head {
    color: #ffffff;
    font-size: 14px;
  }
  .student-block .card.aptitude .card-body .value {
    color: #ffffff;
    font-size: 30px;
    font-weight: 900;
  }
  .student-block .card.aptitude .card-body .icon {
    color: #47215f;
    font-size: 40px;
    margin-top: 0px;
  }
  .report-block .table-responsive {
    margin-top: 20px;
    padding: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .student-block .card {
    margin-bottom: 10px;
  }
}
.main_header_container {
  width: 100%;
}
.under_main_header_container {
  margin: 0 auto 0 auto;
  display: flex;
  background: #1273b7;
  /* width: 1400px; */

  align-items: flex-start;
}
.left_side_img_container {
  position: relative;
}
.left_side_container {
  width: 40%;
  position: relative;
}
.vector_img_header {
  margin-top: -15%;
  top: 0;
  left: 0;
  position: absolute;
}
.header_title_container {
  position: absolute;
  left: 60%;
  -webkit-transform: translate(-50%, 154%);
          transform: translate(-50%, 154%);
}
.header_title_text {
  font-family: Poppins;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  line-height: 70px;
  margin: 0;

  letter-spacing: -0.01em;
}
.header_title_yellow_text {
  font-family: Poppins;
  color: #fff;
  font-weight: 800;
  font-size: 50px;
  line-height: 70px;
  margin: -5% 0 0 0;

  letter-spacing: -0.01em;
  color: rgba(239, 207, 27, 1);
}
.right_side_container {
  width: 60%;
}
.img_header_bg {
  width: 100%;
}

@media (max-width: 600px) {
  .vector_img_header {
    margin-top: -9%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .header_title_container {
    position: absolute;
    left: 59%;
    top: 50%;
    -webkit-transform: translate(-50%, 129%);
            transform: translate(-50%, 129%);
  }
  .header_title_text {
    font-size: 15px;
    line-height: 23px;
  }
  .header_title_yellow_text {
    font-size: 15px;
    line-height: 23px;
    margin: -3% 0 0 0;
  }
}

@media (min-width: 600px) {
  .vector_img_header {
    margin-top: -15%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .header_title_container {
    position: relative;
    left: 0;
    -webkit-transform: translate(20%, 119%);
            transform: translate(20%, 119%);
  }
  .header_title_text {
    font-size: 30px;
    line-height: 39px;
  }
  .header_title_yellow_text {
    font-size: 30px;
    line-height: 39px;
    margin: -1% 0 0 0;
  }
}

@media (min-width: 991px) {
  .header_title_container {
    position: relative;
    left: 0;
    -webkit-transform: translate(16%, 142%);
            transform: translate(16%, 142%);
  }
  .header_title_text {
    font-size: 40px;
    line-height: 50px;
  }
  .header_title_yellow_text {
    font-size: 40px;
    line-height: 50px;
    margin: -1% 0 0 0;
  }
}

@media (min-width: 1200px) {
  .vector_img_header {
    margin-top: -15%;
    top: 0;
    left: 0;
    position: absolute;
    width: 751px;
    height: 534px;
  }
  .main_header_container {
    margin: 0 auto 81px auto;
  }
  .header_title_container {
    position: relative;

    left: 0;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    margin: 247px 0 0 140px;
  }
  .header_title_text {
    font-size: 56px;
    line-height: 60px;
  }
  .header_title_yellow_text {
    font-size: 56px;
    line-height: 60px;

    margin: 0 0 0 0;
  }
  .under_main_header_container {
    width: 100%;
  }
  .left_side_container {
  }
}

@media (min-width: 1400px) {
  .under_main_header_container {
    width: 100%;
    min-height: 568px;
  }
}

/* @media (min-width: 1200px) {
  .main_header_container {
    margin: 0 auto 81px auto;
  }
  .header_title_container {
    position: relative;
    left: 0;
    transform: translate(16%, 162%);
  }
  .header_title_text {
    font-size: 50px;
    line-height: 60px;
  }
  .header_title_yellow_text {
    font-size: 50px;
    line-height: 60px;

    margin: -1% 0 0 0;
  }
  .under_main_header_container {
    width: 1400px;
  }
  .left_side_container {
  }
} */

.about_us_main_section {
}
.res_about_us_container {
}
.dont_worry_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.dont_worry_content_container {
}
.dont_worry_title_text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
}
.dont_worry_under_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 24px;
  margin: 0px 0 10px 0;
}
.dont_worry_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  font-family: Roboto;
}
.lock_img_btn {
  cursor: pointer;
  border: 0;
  font-weight: 600;
  outline: none;
  font-family: Poppins;
  color: #fff;
  height: 40px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    92.13deg,
    #1273b7 -10.42%,
    #3194e7 51.89%,
    #1b89e5 114.19%
  );
  border-radius: 4px;
}
.lock_img_btn:hover {
  background: #3194e7;
}

.lock_img {
  margin: 0 10px;
  width: 5%;
}
.img_dont_worry_self {
}
.rocket_img {
}

@media (max-width: 767px) {
  .about_us_main_section {
    max-width: 450px;
    margin: auto;
  }
  .res_about_us_container {
    max-width: 850px;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin: 9% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 90%;
  }
  .dont_worry_title_text {
    line-height: 22px;
    letter-spacing: -0.01em;
    font-size: 18px;
  }
  .dont_worry_under_line {
    border-bottom: 2px solid rgba(239, 207, 27, 1);
    width: 50px;
    margin: 3% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  .lock_img_btn {
    font-size: 14px;
    border: 0;
    outline: none;
    margin: 4% 0 4% 0;

    height: 40px;
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
  }
  .lock_img {
    margin: 0 10px;
    width: 4%;
  }
  .img_dont_worry_self {
  }
  .rocket_img {
    width: 0;
  }
}

@media (min-width: 768px) {
  .about_us_main_section {
  }
  .res_about_us_container {
    max-width: 768px;
    margin: auto;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 84%;
    flex-wrap: wrap;
    margin: 10% auto 0% auto;
  }
  .dont_worry_content_container {
    width: 59%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 28px;

    font-size: 20px;
  }
  .dont_worry_under_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 50px;
    margin: 1% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
  .lock_img_btn {
    margin: 4% 0 4% 0;
    height: 35px;
    font-size: 13px;
    width: 284px;

    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-bottom: -50px;
  }
}

@media (min-width: 991px) {
  .about_us_main_section {
  }
  .res_about_us_container {
    max-width: 1024px;
    margin: auto;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 85%;
    flex-wrap: wrap;
    margin: 7% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 32px;
    font-size: 24px;
  }
  .dont_worry_under_line {
    border-bottom: 4px solid rgba(239, 207, 27, 1);
    width: 80px;
    margin: 1% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  .lock_img_btn {
    margin: 3% 0 3% 0;
    height: 39px;
    font-size: 15px;
    width: 313px;
    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-top: -50px;
  }
}

@media (min-width: 1200px) {
  .about_us_main_section {
    margin: 168px 0 0 0;
  }

  .dont_worry_container {
    width: 86%;

    margin: 7% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 32px;
    font-size: 24px;
  }
  .dont_worry_under_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 77px;

    margin: 4px 0 0px 0;
  }
  .dont_worry_extra_content {
    font-size: 19px;
    line-height: 24px;
    margin: 50px 0 23px 0;
  }
  .lock_img_btn {
    margin: 3% 0 3% 0;
    height: 45px;
    font-size: 17px;
    width: 347px;
    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-bottom: 10px;
  }
}

@media (min-width: 1390px) {
  .about_us_main_section {
    margin: 168px 0 0 0;
  }

  .dont_worry_container {
    width: 79%;
    margin: 120px 142px 120px 142px;
  }
  .dont_worry_content_container {
    width: 58%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    font-size: 32px;
    line-height: 60px;
  }
  .dont_worry_under_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 102px;
    margin: 4px 0 0px 0;
  }
  .dont_worry_extra_content {
    margin: 60px 0 68px 0;
    font-size: 20px;
    line-height: 30px;
  }
  .lock_img_btn {
    margin: 0px 0 0% 0;
    height: 43px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    width: 373px;
    padding: 5px 5px;
    border-radius: 4px;
    font-family: Poppins;
  }
  .lock_img {
    margin: 0 8px;
    width: 12px;
    height: 15px;
  }
  .img_dont_worry_self {
    margin: 0 0 0 60px;
    width: 412px;
    height: 411px;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-top: -100px;
  }
}
.about_us_container {
  display: flex;
  justify-content: center;
}
.lost_container {
  background: rgba(18, 115, 183, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  border-radius: 80px;
}
.left_lost_container {
  width: 40%;
}
.lost_img_self {
  width: 60%;
}
.lost_content_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
.lost_text {
  font-family: "Poppins";

  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
}
.lost_under_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 24px;
  margin: 0px 0 10px 0;
}
.lost_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  font-family: Roboto;
  letter-spacing: -0.01em;
  text-align: justify;

  color: #4f4f4f;
}
.lost_img_container {
}
@media (max-width: 767px) {
  .lost_img_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 53%;
    margin-top: -19%;
  }
  .confused_img_container {
    width: 57%;
    margin-top: -23%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .confident_img_container {
    width: 77%;
    margin-top: -59%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .about_us_container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 30% auto 10% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    border-radius: 10px;
    padding: 0 4% 5% 4%;
    margin: auto;
  }
  .left_lost_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .lost_img_self {
    width: 100%;
    max-width: 100%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .lost_text {
    line-height: 39px;
    font-size: 29px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    margin: 0;
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .lost_img_container {
    width: 81%;
    margin: -51px 0 0 0;
  }
  .confused_img_container {
    width: 68%;
    margin: -68px 0 0 0;
  }
  .confident_img_container {
    width: 100%;
    margin: -152px 0 0 -41px;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 12% auto 10% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
    border-radius: 50px;
    padding: 0 2% 0% 2%;
    margin: 0 auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_self {
    max-width: 100%;
    width: 100%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 3% 0 3% 0;
  }
  .lost_text {
    line-height: 39px;
    font-size: 29px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 13px;
    line-height: 18px;
    text-align: justify;
  }
}

@media (min-width: 991px) {
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10% auto 6% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 71%;
    border-radius: 48px;
    padding: 0 3% 0% 0%;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_container {
    width: 81%;
    margin: -51px 0 0 0;
  }
  .confused_img_container {
    width: 61%;
    margin: -68px 0 0 0;
  }
  .confident_img_container {
    width: 98%;
    margin: -194px 0 0 -33px;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  }
  .lost_text {
    line-height: 47px;
    font-size: 37px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (min-width: 1200px) {
  .res_about_us_container {
    max-width: 1200px;
    margin: auto;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    margin: 81px auto 1% auto;
  }
  .lost_container {
    width: 70%;
    border-radius: 100px;
    padding: 0 3% 0 2%;
    margin: 0% auto 5% auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_container {
    width: 79%;
    margin-top: -25%;
  }
  .confused_img_container {
    width: 65%;
    margin: -29% 0 0 0;
  }
  .confident_img_container {
    width: 98%;
    margin: -72% 0 0 -81px;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 2% 0 3% 0;
  }
  .lost_text {
    line-height: 51px;
    font-size: 41px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 19px;
    line-height: 24px;
    margin-top: 8px;
    text-align: left;
  }
  .lost_under_line {
    width: 35px;
    margin: 0px 0 10px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
}

@media (min-width: 1400px) {
  .res_about_us_container {
    max-width: 1440px;
    margin: auto;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 81px auto 0% auto;
  }
  .lost_container {
    width: 1003px;
    border-radius: 138px;
    padding: 0 3% 0 2%;
    margin: 0% auto 0% auto;
    min-height: 275.81px;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .lost_img_container {
    width: 291px;
    margin-top: -32%;
    height: 364px;
    margin-left: 46px;
  }
  .confused_img_container {
    margin: -29% 0 0 58px;
    /* width: 272px; */
    /* margin-top: -64%; */
    /* height: 304px; */
    /* margin-left: 46px; */
    width: 68%;
  }
  .confident_img_container {
    margin: -65% 0 0 -27px;
    width: 100%;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 0% 0 0% 0;
  }
  .lost_text {
    margin: 30.98px 0 0 0;
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
  }

  .lost_extra_content {
    margin-top: 8px;
    text-align: justify;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35.98px;
  }
  .lost_under_line {
    width: 40px;
    margin: 4px 0 24.98px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
}

/* @media (min-width: 1200px) {
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 81px auto 10% auto;
    width: 1200px;
  }
  .lost_container {
    width: 70%;
    border-radius: 100px;
    padding: 0 3% 0 2%;
    margin: 0% auto 5% auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_self {
    width: 86%;
    margin-top: -25%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 2% 0 3% 0;
  }
  .lost_text {
    line-height: 51px;
    font-size: 41px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 20px;
    line-height: 29px;
    margin-top: 8px;
    text-align: left;
  }
  .lost_under_line {
    width: 35px;
    margin: 0px 0 10px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
} */

.work_main_container {
  background: rgba(18, 115, 183, 0.06);
}
.res_work_container {
}
.work_under_section_contianer {
  padding: 4% 0 0 0;
}
.title_main_text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
  text-align: center;
}
.work_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 68px;
  margin: 17px auto 10px auto;
}
.work_content_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.work_left_side_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.work_right_side_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btn_center {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.dashed_line_container {
  position: relative;
}
.first_dash {
  width: 100%;
  position: absolute;
}
.sec_dash {
  width: 100%;
  position: absolute;
}
.third_dash {
  width: 100%;
  position: absolute;
}
.four_dash {
  width: 100%;
  position: absolute;
}

@media (max-width: 767px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    margin: auto;
  }
  .third_dash {
    width: 0%;
  }
  .four_dash {
    width: 0%;
  }
  .sec_dash {
    width: 0%;
  }
  .first_dash {
    width: 0%;
    position: absolute;
    left: 32%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 37%;
  }
  .work_right_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .work_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work_under_section_contianer {
    padding: 8% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 77px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .third_dash {
    width: 0%;
  }
  .four_dash {
    width: 0%;
  }
  .sec_dash {
    width: 0%;
  }
  .first_dash {
    width: 0%;
    position: absolute;
    left: 32%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 37%;
  }
  .work_right_side_container {
    width: 100%;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .work_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 27px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 991px) {
  .step_one_text {
    margin: 0% 0 0 0;
    font-size: 19px;
    line-height: 22px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 21px;
    margin: 6% 0 0 0;
  }
  .work_right_side_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .four_dash {
    width: 44%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 96%;
  }
  .third_dash {
    width: 43%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 49%;
    z-index: -1;
  }

  .sec_dash {
    width: 42%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 100%;
  }
  .first_dash {
    width: 53%;
    position: absolute;
    left: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 29%;
  }
  .work_content_container {
    flex-direction: row;
    width: 86%;

    margin: auto;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 30px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 40px 0 80px 0;
  }

  .four_dash {
    width: 47%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 90%;
    z-index: -1;
  }
  .third_dash {
    width: 47%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 53%;
    z-index: -1;
  }
  .sec_dash {
    width: 46%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 100%;
    z-index: -1;
  }
  .first_dash {
    width: 50%;
    position: absolute;
    left: 34%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 32%;
    z-index: -1;
  }
  .res_work_container {
    width: 1200px;
    margin: 0 auto;
  }
  .work_content_container {
    flex-direction: row;

    width: 94%;

    margin: auto;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 40px;
    font-size: 36px;
    margin: 0;
  }
  .work_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 60px;
    margin: 11px auto 23px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1390px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 94px 0 0px 0;
    padding: 0 0 80px 0;
  }

  .four_dash {
    width: 48%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 101%;
    z-index: -1;
  }
  .third_dash {
    width: 48%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 57%;
    z-index: -1;
  }
  .sec_dash {
    width: 49.2%;
    position: absolute;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 100%;
    z-index: -1;
  }
  .first_dash {
    width: 50%;
    position: absolute;
    left: 34.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 28%;
    z-index: -1;
  }
  .res_work_container {
    width: 1440px;
    margin: 0 auto;
  }
  .work_content_container {
    flex-direction: row;
    width: 94%;
    margin: 10px auto;
  }
  .work_under_section_contianer {
    padding: 0% 0 0 0;
    width: 1395px;
    margin: auto;
  }
  .title_main_text {
    padding: 66px 0 0 0;
    font-size: 32px;
    line-height: 60px;
  }
  .work_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 40px;
    margin: 0px auto 100px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

.one_work_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tell_img_left_container {
}
.tell_img_self {
}
.tell_img_right_container {
}
.step_one_text {
  font-family: "Roboto";

  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  margin: 0;
}
.tell_us_sub_text {
  font-family: "Roboto";

  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #1273b7;
  margin: 0;
}
.tell_us_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  font-family: Roboto;
}

@media (max-width: 767px) {
  .tell_us_extra_content {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    padding: 2px 6% 8% 6%;
  }
  .one_work_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .step_one_text {
    margin: 5% 0 2% 0;
    font-size: 17px;
    line-height: 22px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 18px;
    margin: 6px 0 3px 0;
    width: 92%;
  }
}

@media (min-width: 768px) {
  .tell_img_self {
    width: 70%;
    /* margin: 0 auto; */
  }
  .tell_img_left_container {
    display: flex;
    justify-content: center;
  }
  .tell_us_extra_content {
    margin: 0;
    width: 92%;
    font-size: 13px;
    line-height: 15px;

    text-align: center;

    padding: 4px 6% 4% 6%;
  }
  .one_work_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .step_one_text {
    margin: 2% 0 0 0;
    font-size: 17px;
    line-height: 21px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 26px;
    margin: 10px 0 0 0;
  }
}

@media (min-width: 991px) {
  .tell_img_self {
    width: 80%;
  }
  .tell_img_left_container {
    width: 30%;
  }
  .tell_us_extra_content {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    padding: 4px 0%;
    width: 100%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 2% 0 0 0;
    font-size: 17px;
    line-height: 21px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 21px;
    margin: 3% 0 0 0;
  }
}

@media (min-width: 1200px) {
  .tell_img_self {
    width: 70%;
    z-index: 1;
  }
  .tell_img_left_container {
    width: 30%;
    margin: 0 3% 0 0%;
    display: flex;
    justify-content: flex-end;
  }
  .tell_us_extra_content {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    padding: 4px 0%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 160px;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 0% 0 0 0;
    font-size: 19px;
    line-height: 15px;
    letter-spacing: 0.5px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 20px;

    margin: 3% 0 0 0;
  }
}

@media (min-width: 1390px) {
  .tell_img_self {
    width: 80%;
    z-index: 1;
    max-width: 100%;
  }
  .tell_img_left_container {
    width: 30%;
    margin: 0 3% 0 0%;
    display: flex;
    justify-content: flex-end;
  }
  .tell_us_extra_content {
    margin: 6px 0 0 0;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    padding: 0px 0%;
    width: 100%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 184px;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 0% 0 21px 0;
    font-size: 19px;
    line-height: 15px;
    letter-spacing: -0.01em;
    font-size: 22px;
    line-height: 30px;
  }
  .tell_us_sub_text {
    margin: 0% 0 0 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #1273b7;
  }
}

.first_step_container {
    display: flex;
    flex-direction: column;
}

.open_login_form_container {
    position: fixed;
    bottom: 0;
    right: 0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #fff;
    background-color: #fff;
    width: 100%;
    padding: 10px 12px;
    z-index: 10;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.46);
}

.open_login_form_container>button {
    color: #fff;
    background: #1273b7;
    padding: 1rem;
    font-size: 1.8rem;
    border-radius: 9px;
    width: 20%;
    outline: none;
    border: 0;
    letter-spacing: .5px;
    cursor: pointer;
}

.open_login_form_container>button:hover {
    color: #333;
    background: #0d5b92;
}

.google_icon {
    font-size: 19px;
}



@media (max-width:767px) {
    div[label="Personal info"]::after {
        left: 80%;

    }

    .open_login_form_container>button {
        width: 170px;
    }

}

@media (max-width:300px) {
    .open_login_form_container>button {
        width: 100%;

        font-size: 1.4rem;
    }

}

@media (min-width:1200px) {
    div[label="Personal info"]::after {
        left: 80%;

    }

    .open_login_form_container>button {
        color: #fff;
        background: #1273b7;
        padding: 1rem;
        font-size: 1.8rem;
        border-radius: 9px;
        width: 200px;
    }
}
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }


  .loader-wrapper{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  }

  .loader-text{
    margin-top: 5%;
    font-size:20px;
    -webkit-transform: translate(-20%, -20%);
            transform: translate(-20%, -20%);
  }
.question_container {
    width: 100%;
    display: block;

}

.option_image_width {
    width: 49%
}

.option_content_width {
    width: 100%
}

.question_container p img {

    width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.option_content_container img {
    width: 50%;
    object-fit: contain;
}

.question_container p,
.question_container table {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}


.question_container p {
    text-align: left;
}

.question_container table {

    text-align: center;
}

.question_container ol {
    font-size: 1.4rem;
    text-align: left;
    font-weight: 500;
    margin: 0;
}



.question_container span[style="background-color:transparent; color:#000000"] {
    display: flex;

}

.question_container table span[style="background-color:transparent; color:#000000"] {
    display: flex;
    justify-content: center;
}

.question_container>p>span[style="background-color:transparent; color:#000000"] {
    text-align: left;
}

@media (max-width:767px) {
    .question_container p img {
        width: 100%;
        height: 115px;
        display: flex;

    }

    .question_container p {
        font-size: 1.4rem;
    }

    .question_container table {
        font-size: 1.3rem;
    }

    .question_container ol {
        font-size: 1.3rem;
    }

    .option_content_container img {
        width: 100%;
        height: 60px;
    }
}

@media (min-width:768px) {
    .option_content_container img {
        width: 33%;
        object-fit: contain;
    }

    .question_container p {}

    .question_container p img {
        width: 50%;
        height: 200px;

    }

    .question_container p {
        font-size: 1.6rem;
    }

    .question_container ol {
        font-size: 1.5rem;
    }
}

@media (min-width:1200px) {
    .option_content_container img {
        width: 50%;
        object-fit: contain;
    }
}


@media (min-width:1400px) {
    .question_container p {
        font-size: 1.7rem;
    }

    .question_container ol {
        font-size: 1.6rem;
    }
}
.career-tooltip {
  position: relative;
  cursor: pointer;

}
.career-tooltip:hover::after {
  color: #222;
  padding: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 1.5rem;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: white;
  box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
}

@media only screen and (max-width: 768px) {
  .career-tooltip:hover::after {
    color: #222;
    width: 90vw;
    bottom: -16vw;
    left: -2vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
    z-index: 999;
  }
}


.section-tooltip {
  position: relative;
  cursor: pointer;
  z-index: 1000;
 
}
.section-tooltip:hover::after {
  color: rgb(255, 255, 255);
  padding: 1rem;
  font-weight: 400;
  top:-45px;
  left:-25px;
  font-size: 1.5rem;
  margin-left: 1rem;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: #1273B7;

  /* box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem; */
}

.section-tooltip:hover:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1273B7;
  content: ' ';
  position: absolute;
  top: -2px;
  left: 35%;
}


@media only screen and (max-width: 768px) {
  .section-tooltip:hover::after {
    color: #222;
    left: -40vw;
    width: 90vw;
    bottom: -16vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 1rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
  }
}

.disclaimer_container {
    padding: 20px;
    margin: 5rem 0;
    border-radius: 10px;
    background-color: #fff;
}

.disclaimer_container > p {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.disclaimer_container > h1 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}


/* styles */
.bLXDyt {
    background-color: transparent !important;
    padding: 0 !important;
}

.container-md {
    max-width: calc(1400px + 40px);
    padding: 0 20px;
    margin: 0 auto;
}

.report-hero-section-alignment {
    background: #FAF9FF;
    padding: 60px 0 120px 0;
    font-family: 'Poppins', sans-serif;

}

.report-hero-section-alignment .image-center-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 100px 0;
}

.report-hero-section-alignment .image-center-alignment > img {
    max-width: 434px;
    cursor: pointer;
    height: 80px;
    object-fit: cover;
}

.report-hero-section-alignment .report-hero-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    gap: 40px;
}


.take-section-banner-box-center-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items h1 {
    font-weight: 700;
    font-size: 42px;
    color: #352958;
    line-height: 52px;
    margin: 0 0 30px 0;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items p:last-child {
    margin: 0;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    margin: 0 0 20px 0;
    letter-spacing: 0.02em;
    text-align: justify;
    color: #706E84;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items .chart-image img {
    max-width: 100%;
}

.management-aptitide-section-alignemnt {
    font-family: 'Poppins', sans-serif;
    padding: 120px 0 0 0;
    background-color: #fff;
}

.all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(1) {
    order: 2;
}

.all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(2) {
    order: 1;
}

.management-aptitide-section-alignemnt .common-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    gap: 40px;
    padding: 0 0 60px 0;
    align-items: center;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment img {
    max-width: 500px;
    object-fit: cover;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment {
    display: flex;
    justify-content: center;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style {
    padding: 0 0 15px 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style h2 {
    font-weight: 500;
    color: #352958;
    font-size: 32px;
    margin: 0 0 10px 0;
    line-height: 40px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style p {
    font-weight: 500;
    font-size: 16px;
    color: #A3A2B4;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style {
    padding: 0 0 20px 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p:last-child {
    margin: 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul {
    list-style: devanagari;
    margin: 0;
    padding: 0 0 0 15px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul li {
    font-weight: 400;
    padding: 0 0 10px 0;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    line-height: 26px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p {
    font-weight: 500;
    color: #352958;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 15px 0;
    letter-spacing: 0.02em;
}

.management-aptitide-section-alignemnt .score-box {
    width: 574px;
    background: #F8F7FC;
    padding: 15px;
    margin: 0 0 15px 0;
    border-radius: 10px;
}

.score-box .score-grid {
    display: grid;
    grid-template-columns: 60px 1fr auto;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}

.score-box .score-grid .score-grid-items .rounded-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0px 40px 60px #ECE6FF;

    justify-content: center;
    cursor: pointer;
}

.score-box .score-grid .score-grid-items .rounded-icon img {
    max-width: 25px;
}

.score-box .score-grid .score-grid-items:last-child {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.score-box .score-grid .score-grid-items h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: #5330BA;
}

.score-box .score-grid .score-grid-items p {
    font-weight: 500;
    font-size: 18px;
    margin: 0 0 10px 0;
    line-height: 28px;
    color: #352958;
}

.score-box .score-grid .score-grid-items .score-range {
    width: 100%;
    background: #FFFFFF;
    height: 14px;
    border-radius: 30px;
    position: relative;
}

.score-box .score-grid .score-grid-items .score-range .score-range-active {
    background: #FFC700;
    border-radius: 10px;
    height: 8px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 3px;
}

.take-section-banner {
    font-family: 'Poppins', sans-serif;
    background-image: url(/static/media/bg-banner.7b304c5e.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 80px 0;
    display: flex;
    align-content: center;
}

.take-section-banner .take-box {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 0 80px 80px 80px;
}

.take-section-banner .take-box .image-center-alignment {
    display: flex;
    padding: 0 0 0px 0;
    justify-content: center;
}

.take-section-banner .take-box .image-center-alignment img {
    max-width: 366px;
    height: 350px;
}

.take-section-banner .take-box .heigh-alignment {
    padding: 0 300px;
    display: grid;
    grid-template-columns: 1fr 125px;
    grid-gap: 0;
    gap: 0;
}

input:focus {
    outline: none;
}

.take-section-banner .take-box .heigh-input input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px 0px 0px 20px;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-left: 20px;
    width: 100%;
    height: 60px;
    text-align: center;
}

.take-section-banner .take-box .heigh-input ::-webkit-input-placeholder {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
}

.take-section-banner .take-box .heigh-input ::placeholder {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
}

.take-section-banner .take-box .button-style button {
    width: 100%;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(135deg, #9173E9 0%, #E128EE 100%);
    border-radius: 0px 20px 20px 0px;
    cursor: pointer;
    height: 60px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.take-section-banner .take-box .button-style p {
    width: 100%;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(135deg, #9173E9 0%, #E128EE 100%);
    border-radius: 0px 20px 20px 0px;
    height: 60px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-box-button-alignment {
    padding: 50px 0 0 0;
}

.text-box-button-alignment p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px 0;
    color: #fff;
    text-align: center;
    text-align: justify;
}

.text-box-button-alignment div {
    display: flex;
    justify-content: center;
}

.text-box-button-alignment div button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgba(56, 39, 88, 0.2);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;
}

.three-month-section {
    font-family: 'Poppins', sans-serif;
    padding: 90px 0px 50px 0;
    background-color: #fff;
}

.three-month-section .page-title {
    padding: 0 0 90px 0;
}

.three-month-section .page-title2 {
    padding: 0 0 90px 0;
}

.three-month-section .page-title h3 {
    font-weight: 700;
    font-size: 42px;
    max-width: 850px;
    margin: 0 auto;
    letter-spacing: -0.01em;
    line-height: 52px;
    color: #352958;
    position: relative;
    text-align: center;
}

.three-month-section .page-title2 h3 {
    font-weight: 700;
    font-size: 42px;
    max-width: 850px;
    margin: 0 auto;
    letter-spacing: -0.01em;
    line-height: 52px;
    color: #352958;
    position: relative;
    text-align: center;
}

.three-month-section .page-title h3::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}


.three-month-section .three-month-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 110px;
    gap: 110px;
}

.three-month-section .three-month-grid-items:nth-child(1) {
    background: #FFFFFF;
    -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.05));
            filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.05));
    padding: 50px 60px;
    border-radius: 50px;
}

.three-month-section .three-month-grid-items p {
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.02em;
    color: #706E84;
    line-height: 36px;
    margin: 0 0 30px 0;
}

.three-month-section .three-month-grid-items h3 {
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 10px 0;
    letter-spacing: 0.02em;
    color: #352958;
    line-height: 45px;
}

.three-month-section .three-month-grid-items .all-text-alignment h4 {
    font-weight: 600;
    font-size: 32px;
    color: #352958;
    letter-spacing: 0.02em;
    line-height: 42px;
    text-align: left;
}

.three-month-section .three-month-grid-items .all-text-alignment div {
    padding: 0 0 40px 0;
}

.three-month-section .three-month-grid-items .all-text-alignment div:last-child {
    padding: 0;
}

.three-month-section .three-month-grid-items .all-text-alignment h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #A3A2B4;
    letter-spacing: 0.02em;
}

.container-md-2 {
    max-width: calc(1310px + 40px);
    padding: 0 20px;
    margin: 0 auto;
}

.our-promise-section {
    font-family: 'Poppins', sans-serif;
    background: #F9F7FD;
    padding: 50px 0;
}

.our-promise-section .page-title h2 {
    font-weight: 700;
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    position: relative;
    text-align: center;
    line-height: 52px;
}

.our-promise-section .page-title {
    padding: 0 0 90px 0;
}

.our-promise-section .page-title2 {
    padding: 0 0 90px 0;
}

.our-promise-section .page-title h2::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}

.our-promise-section .page-title2 h2::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}

.our-promise-section .our-grid {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-gap: 160px;
    gap: 160px;
    align-items: center;
}

.our-promise-section .profile-details {
    padding: 15px 0 0 0;
}

.our-promise-section .profile-details span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A3A2B4;
    display: block;
}

.our-promise-section .profile-details p {
    font-weight: 500;
    color: #352958;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 3px 0;
}

.our-promise-section .profile-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    height: 326px;
}

.our-promise-section .child-text-style p {
    font-weight: 400;
    font-size: 18px;
    color: #352958;
    margin: 0 0 30px 0;
    line-height: 28px;
    text-align: justify;
}

.career-section-alignment {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    padding: 50px 0 0 0;
}

.career-section-alignment .page-title h4 del {
    color: #514EFF;
}

.career-section-alignment .page-title2 h4 del {
    color: #514EFF;
}

.career-section-alignment .page-title h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    max-width: 950px;
    margin: 0 auto 30px auto;
    text-align: center;
    letter-spacing: -0.01em;
}

.career-section-alignment .page-title2 h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    max-width: 950px;
    margin: 0 auto 30px auto;
    text-align: center;
    letter-spacing: -0.01em;
}

.career-section-alignment .page-title p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    text-align: center;
    position: relative;
}

.career-section-alignment .page-title2 p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    text-align: center;
    position: relative;
}

.career-section-alignment .page-title p::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}

.career-section-alignment .page-title2 p::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}

.career-section-alignment .page-title {
    padding: 0 0 100px 0;
}

.career-section-alignment .page-title2 {
    padding: 0 0 100px 0;
}

.new-col-grid {
    display: grid;
    padding: 0 0 100px 0;
    grid-template-columns: 1fr 421px;
    grid-gap: 232px;
    gap: 232px;
}

.new-col-grid .new-dummy-text-style p {
    font-weight: 500;
    font-size: 16px;
    color: #0F0BCB;
    margin: 0 0 5px 0;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.new-col-grid .new-dummy-text-style {
    padding: 0 0 20px 0;
}

.new-col-grid .new-dummy-text-style span {
    display: block;
    font-weight: 400;
    color: #281E42;
    font-size: 16px;
    line-height: 25px;
}

.new-col-grid .all-question-box-alignment .question-box {
    margin: 0;
}

.new-col-grid .all-question-box-alignment .question-box {
    border: 1px solid #D9D9D9;
    margin: 0 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.question-box-header {
    padding: 20px;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;

}

.question-box-header-items .taj-icon img {
    cursor: pointer;
}

.question-box-header-items h6 {
    font-weight: 500;
    color: #281E42;
    font-size: 18px;
    line-height: 27px;
}

.question-box-header-items .action-button {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #D9D9D9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.new-text-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.new-text-show {
    max-height: calc(100vh - 0px);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.text-style {
    padding: 0 20px 16px 20px;
}

.text-style p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #7C7492;
}

.new-col-grid .new-col-grid-items .new-image-style img {
    max-width: 100%;
}

.two-col-grid {
    display: grid;
    grid-template-columns: 550px 1fr;
    padding: 0 0 50px 0;
    grid-gap: 160px;
    gap: 160px;
}

.two-col-grid .two-col-grid-items .image-style img {
    max-width: 100%;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style p {
    font-weight: 500;
    font-size: 16px;
    color: #0F0BCB;
    margin: 0 0 5px 0;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style {
    padding: 0 0 20px 0;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style span {
    display: block;
    font-weight: 400;
    color: #281E42;
    font-size: 16px;
    line-height: 25px;
}

.two-col-grid .two-col-grid-items .all-question-box-alignment .question-box {
    margin: 0;
}

.two-col-grid .two-col-grid-items .all-question-box-alignment .question-box {
    border: 1px solid #D9D9D9;
    margin: 0 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.question-box-header {
    padding: 20px;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;

}

.question-box-header-items .taj-icon img {
    cursor: pointer;
}

.question-box-header-items h6 {
    font-weight: 500;
    color: #281E42;
    font-size: 18px;
    line-height: 27px;
}

.question-box-header-items .action-button {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #D9D9D9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.question-box-header-items .action-button-blue {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #0F0BCB;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.new-text-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.new-text-show {
    max-height: calc(100vh - 0px);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.text-style {
    padding: 0 20px 0 30px;
}

.text-style p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #7C7492;
}

.lorem-text-style-section {
    font-family: 'Poppins', sans-serif;
    background: #F9F7FD;
    padding: 50px 0;
}

.lorem-text-style-section .page-title h1 {
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    font-weight: 600;
    line-height: 52px;
    position: relative;
    text-align: center;
}

.lorem-text-style-section .page-title2 h1 {
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    font-weight: 600;
    line-height: 52px;
    position: relative;
    text-align: center;
}

.lorem-text-style-section .page-title h1::before {
    position: absolute;
    bottom: -30px;
    background-image: url(/static/media/text-vectore.09762484.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    content: "";
}

.lorem-text-style-section .page-title {
    padding: 0 0 90px 0;
}

.lorem-text-style-section .page-title2 {
    padding: 0 0 90px 0;
}

.lorem-text-style-section .lorem-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    gap: 40px;
    padding: 0 0 60px 0;
}

.lorem-text-style-section .pay-now-button {
    display: flex;
    justify-content: space-between;

}

.lorem-text-style-section .pay-now-button div {
    display: flex;
    width: 48%;
}

.lorem-text-style-section .pay-now-button button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgb(56 39 88 / 20%);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;

}

.lorem-text-style-section .lorem-grid .lorem-grid-items {
    border: 2px solid #D9D9D9;
    padding: 20px;
    border-radius: 20px;
}

.lorem-text-style-section .lorem-header {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
}

.lorem-text-style-section .lorem-header div span {
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding-left: 15px;
    line-height: 24px;
    color: #7C7492;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 10px 0;
    color: #352958;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p {
    font-weight: 500;
    color: #7C7492;
    font-size: 16px;
    line-height: 24px;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p span {
    font-weight: 700;
    color: #7C7492;
    font-size: 16px;
    line-height: 24px;

}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #D9D9D9;
}

.lorem-text-style-section .child-box {
    padding: 20px 0 0 0;
}

.lorem-text-style-section .child-box h6 {
    font-weight: 500;
    font-size: 20px;
    color: #352958;
    line-height: 29px;
    margin: 0 0 15px 0;
}

.lorem-text-style-section .child-box h6 {
    font-weight: 500;
    font-size: 20px;
    color: #352958;
    line-height: 29px;
    margin: 0 0 15px 0;
}

.lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid {
    padding: 0;
}

.lorem-text-style-section .icon-text-grid {
    display: grid;
    grid-template-columns: 30px 1fr;
    padding: 0 0 20px 0;
    grid-gap: 10px;
    gap: 10px;
}

.lorem-text-style-section .icon-text-grid img {
    position: relative;
    top: 10px;
}

.lorem-text-style-section .icon-text-grid p {
    font-weight: 400;
    font-size: 16px;
    color: #7C7492;
    line-height: 26px;
}

.lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid img {
    max-width: 16px;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p:last-child {
    color: #E76262;
}

.classess-banner {
    background-image: url(/static/media/bg-banner.7b304c5e.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding: 80px 0;
}

.classess-banner2 {
    background-color: #044063;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding: 80px 0;
}

.classess-banner2 .slick-list {
    width: 100% !important;
}

.classess-banner .slick-list {
    width: 100% !important;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: -50px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 999;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: -50px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 999;
}

.same-slider-class-arrow {
    border: 3px solid rgb(255, 255, 255, .1);
    width: 40px;
    background-color: transparent;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.classess-banner2 .slick-slide {
    padding: 0 20px;
}

.classess-banner .slick-slide {
    padding: 0 20px;
}

.classess-page-title {
    padding: 0 0 60px 0;
}

.classess-page-title p {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #BAA8EB;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 26px;
    text-align: center;
}

.classess-page-title h1 {
    font-weight: 700;
    font-size: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin: 0 0 20px 0;
    line-height: 52px;
}

.slider-section-card {
    background: #FFFFFF;
    padding: 25px 10px 0;
    min-height: 540px;
    border-radius: 20px;
}

@media (min-width: 320px) and (max-width: 640px) {
    .slider-section-card {
        min-height: 446px;
    }
}

.slider-image img {
    width: 100%;
    height: 280px;
    object-fit: contain;
}

.slider-card-details {
    padding: 24px 29px;
}

.slider-card-details h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 8px 0;
    letter-spacing: -0.01em;
    color: #352958;
}

.slider-card-details p {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #352958;
    opacity: .5;
    margin: 0 0 12px 0;
    line-height: 24px;
    /*min-height: 50px;*/
}

.slider-card-details span {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #352958;
    opacity: .5;
    margin: 0 0 12px 0;
    line-height: 24px;
    display: block;
}

@media (min-width: 1360px) and (max-width: 1560px) {
    .slider-card-details span {
        min-height: 10px;
    }
}

@media (min-width: 320px) and (max-width: 640px) {
    .slider-card-details span {
        min-height: 0px;
    }
}

.slider-card-image-alignment {
    display: flex;
    align-items: center;
}

.slider-card-image-alignment div:last-child {
    margin: 0;
}

.slider-card-image-alignment div img {
    cursor: pointer;
    max-width: 60px;
    height: 30px;
    object-fit: contain;
}

.slider-card-image-alignment div {
    margin-right: 48px;
}

.get-started-button-center-alignment {
    display: flex;
    padding: 60px 0 0 0;
    align-items: center;
    justify-content: center;
}

.get-started-button-center-alignment button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgb(56 39 88 / 20%);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;
}

.see-section-alignment {
    background-color: #fff;
    padding: 60px 0;
}

.see-section-alignment .page-title h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0 0 20px 0;
    color: #352958;
}

.see-section-alignment .page-title2 h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0 0 20px 0;
    color: #352958;
}

.see-section-alignment .page-title p {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    position: relative;
    text-align: center;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
}

.see-section-alignment .page-title2 p {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    position: relative;
    text-align: center;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
}

.see-section-alignment .page-title {
    padding: 0 0 100px 0;
}

.see-section-alignment .page-title2 {
    padding: 0 0 100px 0;
}

.see-section-alignment .page-title p::before {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -30px;
    background-image: url(/static/media/new-text-vectore.ae54cd34.svg);
    background-repeat: no-repeat;
    width: 200px;
    height: 4px;
    background-size: cover;
    content: "";
}

.see-section-alignment .slick-list {
    margin: 0 -20px;
}

.see-section-alignment .slick-slide {
    padding: 0 20px;
}

.see-section-alignment .first-card {
    background: #FFFFFF;
    border: 1px solid #d0d0d069;
    border-radius: 20px 20px 0px 0px;
    padding: 28px 40px;
    min-height: 400px;
}

.sec-card {
    height: 142px;
    background: #F2EFFB;
    border-radius: 0px 0px 20px 20px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}


.sec-card .rating-alignment {
    display: flex;
    align-items: center;
}

.sec-card .rating-alignment img {
    margin-right: 5px;
    cursor: pointer;
}

.sec-card .rating-alignment img:last-child {
    margin: 0;
}

.sec-card .text-s p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: rgba(53, 41, 88, 0.65);
}

.sec-card .text-s h5 {
    font-weight: 500;
    font-size: 24px;
    color: #352958;
    line-height: 32px;
}


.first-card .card-profile-image-alignment img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
}

.first-card .card-profile-image-alignment {
    padding: 0 0 30px 0;
}

.new-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #281E42;

}

.see-section-alignment .slick-list {
    width: 100%;
}

@media screen and (max-width: 1500px) {
    .container-md {
        max-width: 100%;
        padding: 0 80px;
    }

    .take-section-banner .take-box .image-center-alignment img {
        max-width: 266px;
        height: 250px;
    }

    .text-box-button-alignment p {
        font-size: 16px;
        line-height: 26px;
    }

    .take-section-banner .take-box {
        border-radius: 30px;
    }
}

@media screen and (max-width: 992px) {
    .report-hero-section-alignment .report-hero-grid,
    .three-month-section .three-month-grid,
    .our-promise-section .our-grid,
    .two-col-grid,
    .new-col-grid,
    .lorem-text-style-section .lorem-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .management-aptitide-section-alignemnt .common-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .take-section-banner .take-box {
        padding: 40px;
    }

    .container-md-2,
    .container-md {
        max-width: 100%;
        padding: 0 40px;
    }
}

@media screen and (max-width: 767px) {
    .report-hero-section-alignment .image-center-alignment > img {
        max-width: 260px;
        height: 35px;
    }

    .report-hero-section-alignment {
        padding: 20px 0 20px 0;
        height: auto;
    }

    .classess-banner .slick-slide {
        padding: 0 10px;
    }

    .left-arrow {
        top: auto;
        bottom: -55px;
        left: 44%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .right-arrow {
        top: auto;
        bottom: -55px;
        left: 60%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }

    .classess-banner {
        padding: 40px 0;
    }

    .see-section-alignment {
        padding: 70px 0;
    }

    .see-section-alignment .page-title h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .see-section-alignment .page-title2 h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .see-section-alignment .page-title p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 312px;
    }

    .see-section-alignment .page-title2 p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 312px;
    }

    .slider-image img {
        height: 210px;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
    }

    .get-started-button-center-alignment {
        padding: 70px 0 0 0;
    }

    .see-section-alignment .slick-slide {
        padding: 0 15px;
    }

    .first-card .card-profile-image-alignment img {
        width: 60px;
        height: 60px;
    }

    .sec-card .text-s p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .see-section-alignment .first-card {
        padding: 15px;
    }

    .sec-card {
        padding: 15px;
        min-height: auto;
    }

    .new-text p {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .get-started-button-center-alignment button {
        padding: 14px;
        letter-spacing: 0.02em;
        width: 100%;
        font-size: 14.6033px;
        line-height: 22px;
    }

    .slider-card-details {
        padding: 15px;
    }

    .see-section-alignment .page-title {
        padding: 0 0 50px 0;
    }
    .see-section-alignment .page-title2 {
        padding: 0 0 50px 0;
    }

    .see-section-alignment .page-title p::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }
    .see-section-alignment .page-title2 p::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .classess-page-title {
        padding: 0 0 30px 0;
    }

    .classess-page-title p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        letter-spacing: 0.02em;

    }

    .classess-page-title h1 {
        font-size: 26px;
        line-height: 39px;
        text-align: center;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.01em;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style p {
        font-weight: 500;
        letter-spacing: 0.02em;
        font-size: 16px;
        line-height: 24px;
    }

    .score-box .score-grid {
        grid-template-columns: 40px 1fr 70px;
        grid-gap: 10px;
        gap: 10px;
    }

    .score-box .score-grid .score-grid-items .rounded-icon {
        width: 40px;
        height: 40px;
        background-color: #fff;
    }

    .score-box .score-grid .score-grid-items .rounded-icon img {
        max-width: 20px;
    }

    .score-box .score-grid .score-grid-items p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 4px 0;
    }

    .management-aptitide-section-alignemnt .score-box {
        width: 100%;
        padding: 10px;
        border-radius: 15px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .management-aptitide-section-alignemnt .common-grid {
        grid-gap: 0;
        gap: 0;
    }

    .management-aptitide-section-alignemnt {
        padding: 40px 0 0 0;
    }

    .management-aptitide-section-alignemnt .common-grid {
        padding: 0;
    }

    .all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(2) {
        order: 2;
    }

    .all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(1) {
        order: 1;
    }

    .take-section-banner .take-box {
        padding: 30px 15px;
    }

    .take-section-banner .take-box .heigh-alignment {
        grid-template-columns: 1fr 91px;
    }

    .three-month-section .three-month-grid-items:nth-child(1) {
        padding: 15px;
        border-radius: 20px;
    }

    .three-month-section .three-month-grid-items h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        margin: 0 0 5px 0;
    }

    .our-promise-section {
        padding: 40px 0;
    }

    .our-promise-section .page-title h2 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #352958;
    }
    .our-promise-section .page-title2 h2 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #352958;
    }

    .three-month-section .three-month-grid-items .all-text-alignment h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        letter-spacing: 0.02em;
    }

    .three-month-section .three-month-grid-items .all-text-alignment div {
        padding: 0 0 30px 0;
    }

    .three-month-section .three-month-grid-items .all-text-alignment h6 {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 24px;
    }

    .three-month-section .three-month-grid {
        grid-gap: 30px;
        gap: 30px;
    }

    .three-month-section .three-month-grid-items p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    .take-section-banner .take-box .button-style button {
        height: 42px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        padding: 10px;
    }

    .take-section-banner .take-box .heigh-input input {
        height: 42px;
        font-weight: 700;
        font-size: 12px;
        padding-left: 16px;
        text-align: center;
        line-height: 18px;
    }

    .take-section-banner .take-box .heigh-alignment {
        padding: 0;
    }

    .take-section-banner .take-box .image-center-alignment img {
        max-width: 250px;
        height: 190px;
    }

    .take-section-banner {
        padding: 80px 23px;
        height: auto;
    }

    .take-section-banner .container-md {
        padding: 0 !important;
    }

    .text-box-button-alignment p {
        font-weight: 500;
        font-size: 14px;
        text-align: justify;
        margin: 0 0 20px 0;
        line-height: 26px;
    }

    .take-section-banner .take-box {
        border-radius: 30px;

    }

    .three-month-section .page-title h3 {
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 26px;
        line-height: 32px;
    }
    .three-month-section .page-title2 h3 {
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 26px;
        line-height: 32px;
    }

    .three-month-section .page-title {
        padding: 0 0 40px 0;
    }
    .three-month-section .page-title2 {
        padding: 0 0 40px 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p {
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        margin: 0 0 5px 0;
    }

    .lorem-text-style-section .lorem-grid {
        grid-gap: 20px;
        gap: 20px;
    }

    .lorem-text-style-section .pay-now-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;


    }

    .lorem-text-style-section .pay-now-button div {
        width: 90%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .lorem-text-style-section .pay-now-button button {
        padding: 10px 24px;
        border-radius: 6px;
        letter-spacing: 0.02em;
        width: 100%;
        font-size: 14.6033px;
        line-height: 22px;

    }

    .lorem-text-style-section .lorem-grid {
        padding: 0 0 40px 0;
    }

    .lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid .icon-text-grid-items img {
        max-width: 11px;
    }

    .lorem-text-style-section .icon-text-grid p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
    }

    .lorem-text-style-section .icon-text-grid {
        grid-template-columns: 20px 1fr;
    }

    .lorem-text-style-section .child-box h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
    }

    .lorem-text-style-section .child-box {
        padding: 15px 0 0 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items h4 {
        font-weight: 700;
        margin: 0 0 10px 0;
        font-size: 18px;
        line-height: 30px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section .lorem-header div span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .three-month-section .page-title h3::before,
    .our-promise-section .page-title h2::before,
    .career-section-alignment .page-title p::before,
    .lorem-text-style-section .page-title h1::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .three-month-section .page-title2 h3::before,
    .our-promise-section .page-title2 h2::before,
    .career-section-alignment .page-title2 p::before,
    .lorem-text-style-section .page-title2 h1::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items {
        padding: 15px;
    }

    .lorem-text-style-section .page-title {
        padding: 0 0 50px 0;
    }

    .lorem-text-style-section .page-title2 {
        padding: 0 0 50px 0;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style p {
        font-weight: 500;
        margin: 0 0 10px 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
    }

    .question-box-header .taj-icon img {
        max-width: 15px;
    }

    .two-col-grid {
        padding: 0 0 10px 0;
    }

    .new-col-grid {
        grid-gap: 24px;
        gap: 24px;
    }

    .new-col-grid-items:nth-child(1) {
        order: 2;
    }

    .new-col-grid {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section {
        padding: 40px 0;
    }

    .new-col-grid-items:nth-child(2) {
        order: 1;
    }

    .lorem-text-style-section .page-title h1 {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.01em;
        line-height: 33px;
    }

    .lorem-text-style-section .page-title2 h1 {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.01em;
        line-height: 33px;
    }

    .new-col-grid .new-dummy-text-style {
        padding: 0 0 20px 0;
    }

    .new-col-grid .new-dummy-text-style span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .new-col-grid .new-dummy-text-style p {
        font-weight: 500;
        letter-spacing: 0.02em;
        margin: 0 0 10px 0;
        font-size: 14px;
        line-height: 21px;
    }

    .new-col-grid .new-col-grid-items .new-image-style img {
        height: 275px;
    }

    .new-col-grid .new-col-grid-items .new-image-style {
        display: flex;
        justify-content: center;
    }

    .question-box-header-items h6 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .text-style p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .question-box-header {
        padding: 10px;
    }

    .text-style {
        padding: 0 10px 0 10px;
    }

    .question-box-header-items .action-button {
        width: 20px;
        height: 20px;
        font-size: 15px;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style {
        padding: 0 0 20px 0;
    }

    .question-box-header {
        grid-template-columns: 20px 1fr auto;
        align-items: inherit;
    }

    .question-box-header-items .action-button-blue {
        width: 20px;
        height: 20px;
    }

    .classess-page-title p,
    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
        text-align: justify !important;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .three-month-section {
        padding: 40px 0;
    }

    .career-section-alignment .page-title {
        padding: 0 0 43px 0;
    }

    .career-section-alignment .page-title2 {
        padding: 0 0 43px 0;
    }

    .two-col-grid {
        grid-gap: 23px;
        gap: 23px;
    }

    .our-promise-section .profile-image img {
        width: 100%;
        height: 312px;
    }

    .our-promise-section .profile-details {
        padding: 20px 0 0 0;
    }

    .our-promise-section .profile-details p {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        margin: 0 0 10px 0;
    }

    .our-promise-section .our-grid {
        grid-gap: 30px;
        gap: 30px;
    }

    .career-section-alignment {
        padding: 40px 0 0 0;
    }

    .career-section-alignment .page-title p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
    .career-section-alignment .page-title2 p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }

    .career-section-alignment .page-title h4 {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;

    }

    .career-section-alignment .page-title2 h4 {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;

    }

    .our-promise-section .child-text-style p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 20px 0;
    }

    .our-promise-section .profile-details span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .our-promise-section .page-title {
        padding: 0 0 50px 0;
    }

    .our-promise-section .page-title2 {
        padding: 0 0 50px 0;
    }

    .text-box-button-alignment {
        padding: 20px 0 0 0;
    }

    .text-box-button-alignment div button {
        padding: 10px;
        width: 100%;
        height: 52px;
        font-weight: 700;
        font-size: 14.6033px;
        line-height: 22px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul li {
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.02em;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style {
        padding: 0;
    }

    .score-box .score-grid .score-grid-items h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment img {
        max-width: 100%;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin: 10px 0 0 0;
        letter-spacing: 0.02em;
    }

    .management-aptitide-section-alignemnt .score-box {
        margin: 0 0 20px 0;
    }

    .score-box .score-grid .score-grid-items .score-range {
        height: 10px;
    }

    .score-box .score-grid .score-grid-items .score-range .score-range-active {
        height: 6px;
    }

    .report-hero-section-alignment .report-hero-grid {
        grid-gap: 10px;
        gap: 10px;
        padding: 20px 0 0 0;
    }

    .management-aptitide-section-alignemnt {
        padding: 40px 0;
    }

    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;

    }

    .report-hero-section-alignment .image-center-alignment {
        padding: 0 0 20px 0;
    }

    .take-section-banner .take-box .heigh-input ::-webkit-input-placeholder {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
    }

    .take-section-banner .take-box .heigh-input ::placeholder {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
    }

    .container-md-2,
    .container-md {
        max-width: 100%;
        padding: 0 24px;
    }

    .take-section-banner .take-box .button-style p {
        height: 42px;
    }

    .three-month-section .three-month-grid-items:nth-child(1) img {
        max-width: 100%;
    }

    .slider-card-details p {
        min-height: 10px;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p span {
        font-size: 13px;
    }
}

.apexcharts-tooltip {
    background: #f3f3f3;
    color: orange;
}

/* styles */




.heading {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-top: 2rem;
  line-height: 36px;
}

.summary-text {
  height: 60%;
  opacity: 0.6;
  color: #000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

.heade_image {
  border-radius: 100%;
}

@media screen and (max-width: 768px) {
  .summary-text {
    opacity: 0.6;
    color: #000;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 5px;
  }
}

.info-icon {
  opacity: 0.6;
  color: gray;
  font-size: 30px;
}
.avatar {
  height: 1rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 100%;
}

.phrase-tooltip {
  position: relative;
  cursor: pointer;
}
.phrase-tooltip:hover::after {
  color: #222;
  padding: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 1.5rem;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: white;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
}

@media only screen and (max-width: 768px) {
  .phrase-tooltip:hover::after {
    color: #222;
    left: -68vw;
    width: 90vw;
    bottom: -16vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 1rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
  }
}

.strength-desc {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1rem 1rem 1rem 1rem;
  transition: opacity 200ms ease-in;
}
.card-details-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.show_desc {
  top: 2%;
  right: 2%;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 10rem;
}
.show_desc:hover ~ .card-details-container .strength-desc {
  opacity: 1;
}

.ql-container.ql-snow {
  max-height: 150px;
  overflow: auto;
}

.drawer_settingsForm__1_SNr .ant-drawer-wrapper-body {
  width: 100%;
  height: 100%;
}

.drawer_settingsForm__1_SNr .ant-drawer-body {
  height: calc(100% - 55px);
  background: #f0f2f5;
}

/* makes settings drawer wider */
.drawer_settingsForm__1_SNr .ant-drawer-content-wrapper {
  width: auto !important;
  max-width: 1500px;
}

@media only screen and (max-width: 500px) {
  .drawer_settingsForm__1_SNr .ant-drawer-content-wrapper {
    max-width: 300px;
  }
}
/* Quill/tabs styles */
.drawer_settingsForm__1_SNr .ql-container.ql-snow {
  max-height: 500px;
  overflow: auto;
}

.drawer_settingsForm__1_SNr .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0px !important;
}
.drawer_settingsForm__1_SNr
  .ant-btn.ant-btn-circle.ant-btn-sm.ant-btn-icon-only {
  font-size: x-small !important;
}
/* languages dropdown */
.drawer_dropdownLanguages__3BI1B {
  overflow-y: auto;
  max-height: 50vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.questions-table {
    margin: 0 auto;
    width: 100% !important;
    line-height: initial;
}

.questions-table tbody,
.questions-table td,
.questions-table tfoot,
.questions-table th,
.questions-table thead,
.questions-table tr {
    border: 1px solid black;
}

img {
    max-width: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


/* This below line of code increases size of error in SignUp form */


/* .text-danger{
  font-size: 25px;
} */

html {
    font-size: 10px !important;
}

body {
    font-family: Roboto, Poppins, Ubuntu, sans-serif;
}

@font-face {
    font-family: "Gordita";
    src: local("GorditaRegular"), url(/static/media/Gordita-Regular.4595e81e.otf) format("truetype");
}

@font-face {
    font-family: "Gordita Bold";
    src: local("GorditaBold"), url(/static/media/Gordita-Bold.cea81a0d.otf) format("truetype");
}

@font-face {
    font-family: "Gordita Light";
    src: local("GorditaLight"), url(/static/media/Gordita-Light.67d9a955.otf) format("truetype");
}

@font-face {
    font-family: "Gordita Medium";
    src: local("GorditaMedium"), url(/static/media/Gordita-Medium.6ad95cd5.otf) format("truetype");
}


/* Hide scrollbar for Chrome, Safari and Opera */

::-webkit-scrollbar {
    width: 10px;
}


/* Hide scrollbar for IE, Edge and Firefox */

body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
    width: 12px;
    border-radius: 3px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #1273b7;
    border-radius: 3px;
}

.is_active {
    font-weight: 600 !important;
    color: #1273B7 !important;
}

.redutech_Is_Active {
    border: 2px solid #122a39 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
