.main_aptitude_container {
  /* background: red; */
  margin: 0% 3% 3% 3%;
}
.instruction_top_text_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  /* background: red; */
}
.instruction_title {
  margin: 0;
  font-family: Poppins;
}
.instruction_extra_msg_text {
  margin: 0;
  font-family: Poppins;
  opacity: 0.6;
}
.instruction_all_box_contianer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4% 0 0 0;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.instruction_per_box_bg_color {
  background: #ebffed;
}

.instruction_per_box_bg_color1 {
  background: #fff6e8;
}
.instruction_box_same_style {
  text-align: left;
  width: 29.33%;
  margin: 0 3% 3% 0;
  padding: 1% 2% 2% 2%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.instruction_title_number_text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.instrution_number_text_color {
  color: #44db53;
}
.instrution_number_text_same_style {
  font-size: 50px;
  font-weight: 600;

  letter-spacing: -0.5px;
  margin: 0;
  font-family: Poppins;
}
.instruction_correct_box_img {
  margin: 7% 0 0 0;
}
.instruction_img_correct {
}
.instrution_black_number_text {
  background-color: #091b28;
  color: #fff;
  margin: 0 0 0 4%;
  padding: 1% 4% 1% 4%;
  text-align: center;
  border-radius: 8px;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 13px;
  font-family: Poppins;
  text-transform: capitalize;
}
.instruction_number_text_one {
  margin: 7% 0 0 0;
  font-size: 20px;
  color: #091b28;
  font-family: Poppins;
  text-transform: capitalize;
}

.instruction_number_text_two {
  margin: 0;
  font-size: 20px;
  color: #091b28;
  font-family: Poppins;
  text-transform: capitalize;
}
.proceed_btn {
  background: rgb(18, 115, 183);
  width: 40%;
  padding: 2%;
  border-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 5% 0 2% 0;
}
.instruction {
}

.vector_img {
}
@media screen and (max-width: 767px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 6% 3% 6%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .instruction_extra_msg_text {
    margin: 10px 0 0 0;

    letter-spacing: 0px;

    text-align: justify;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #091b28;
    opacity: 0.6;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 6% auto 2% auto;
    width: 100%;
    align-items: flex-start;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 46.33%;
    margin: 0 4px 15px 4px;
    padding: 3% 4% 4% 5%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 120px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 32px;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 34%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 4%;
    padding: 2.5% 6% 1.5% 7%;
    border-radius: 7px;
    font-size: 7px;
    text-align: center;
  }
  .instruction_number_text_one {
    margin: 4% 0 0 0;
    font-size: 12px;
    line-height: 16px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 100%;
    padding: 0%;
    height: 45px;

    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    /* margin: 20px 0 2% 0; */

    line-height: 18px;

    margin: 15px 0 2% 0;

    background: #1273b7;
    border-radius: 22.5px;
  }
}

@media screen and (min-width: 768px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 15px 15px 15px;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .instruction_extra_msg_text {
    margin: 1% 0 0 0;
    opacity: 0.6;
    letter-spacing: 0px;
    line-height: 16px;
    font-size: 13px;
    text-align: justify;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 6% auto 0 auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 46.33%;
    margin: 0 5px 3% 7px;
    padding: 3% 4% 4% 5%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 144px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -0.5px;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 26%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 5%;
    padding: 2.5% 4% 1.5% 4%;
    border-radius: 7px;
    font-size: 8px;
    text-align: center;
  }
  .instruction_number_text_one {
    margin: 4% 0 0 0;
    font-size: 18px;
    line-height: 22px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 100%;
    height: 45px;
    padding: 0;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 17px;
    margin: 20px 0 15px 0;
    border-radius: 22.5px;
  }
}

@media screen and (min-width: 991px) {
  .main_aptitude_container {
    /* background: red; */
    margin: 0% 4% 0% 4%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-family: Poppins;
    font-size: 24px;
    line-height: 33px;
  }
  .instruction_extra_msg_text {
    margin: 0;
    font-family: Poppins;
    opacity: 0.6;
    line-height: 22px;
    font-size: 17px;
    font-weight: 300;
    color: rgba(9, 27, 40, 1);
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 4% auto 0 auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 30.33%;
    margin: 0 4px 14px 11px;;
    padding: 3% 2% 2% 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 35px;
    font-weight: 600;

    letter-spacing: -0.5px;
    margin: 0;
    font-family: Poppins;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
    width: 28%;
  }
  .instrution_black_number_text {
    margin: 0 0 0 4%;
    padding: 1.5% 6% 1.5% 6%;
    border-radius: 8px;
    letter-spacing: 1px;
    font-size: 11px;
  }
  .instruction_number_text_one {
    margin: 13% 0 7px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #091b28;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 50%;
    padding: 0%;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 22px auto 30px auto;
    display: block;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    height: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .vector_img {
    position: absolute;
  }
  .instruction {
    width: 276px;
    height: 242px;
  }

  .main_aptitude_container {
    /* background: red; */
    margin: 0% 4% 3% 4%;
  }
  .instruction_top_text_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    /* background: red; */
  }
  .instruction_title {
    margin: 0;
    font-size: 27px;
  }
  .instruction_extra_msg_text {
    margin: 1.5% 0 0 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    color: #091b28;
    opacity: 0.7;
  }
  .instruction_all_box_contianer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 4% auto 0px auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .instruction_per_box_bg_color {
    background: #ebffed;
  }

  .instruction_per_box_bg_color1 {
    background: #fff6e8;
  }
  .instruction_box_same_style {
    text-align: left;
    width: 30.33%;
    margin: 0 7px 3% 10px;
    padding: 25px 15px 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
  }
  .instruction_title_number_text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .instrution_number_text_color {
    color: #44db53;
  }
  .instrution_number_text_same_style {
    font-size: 52px;
    line-height: 60px;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin: 0;
    font-family: Poppins;
  }
  .instruction_correct_box_img {
    margin: 7% 0 0 0;
  }
  .instruction_img_correct {
  }
  .instrution_black_number_text {
    margin: 0 0 0 5%;
    padding: 0;
    border-radius: 11px;
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 15px;
    min-width: 60px;
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .instruction_number_text_one {
    margin: 56px 0 0 0;
    font-size: 23px;
    line-height: 32px;
  }

  .instruction_number_text_two {
    margin: 0;
    font-size: 20px;
    color: #091b28;
    font-family: Poppins;
    text-transform: capitalize;
  }
  .proceed_btn {
    width: 50%;
    padding: 2%;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5% auto 2% auto;
    height: 60px;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
}
