.universal-quiz-instructions-wrapper div {
    font-family: "Poppins";
    font-style: normal;
}

.universal-quiz-instructions-wrapper {
    display: grid;
    grid-template-columns: 150px 10px 150px 10px 150px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    row-gap: 40px;
}

#aptitude-quiz-timer h2 {
    margin: 0;
}

.aptitude-quiz-report-question-counter {
    background: #ffffff;
    border-radius: 100px;
    text-align: center;
    margin: 60px auto;
    width: 137px;
    height: 48px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 48px;
    color: #4f4f4f;
}

.variable-width .slick-slide {
    /* width: 475px; */
}

#aptitude-quiz-type-progress {
    width: 50px;
}

.aptitude-quiz-page-wrapper,
.aptitude-report-page-wrapper {
    /* background: url("https://d2b560u3hlgvw0.cloudfront.net/BASVNI_Content Frame.png")
    no-repeat center;
  background-size: cover; */
    background: linear-gradient(180deg, #4eb6fed4 0%, #1c8bd9 100%);
    height: fit-content;
    min-height: 100%;
}

.aptitude-quiz-question-option-wrapper {
    margin-bottom: 0 !important;
}

.aptitude-quiz-question-option-wrapper label {
    text-align: left;
}

.aptitude-quiz-page-wrapper {
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aptitude-quiz-reveal-answer-button {
    display: none;
}

.universal-quiz-instructions-button {
    border: 0;
    border-radius: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
}

.universal-quiz-instructions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.universal-quiz-instructions:nth-child(1) {
    padding-bottom: 40px;
}

.universal-quiz-instructions:nth-child(3) {
    padding-top: 40px;
}

.horizontal-divider {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.universal-quiz-instruction-wrapper {
    height: fit-content;
    width: 150px;
}

.universal-quiz-instruction {
    color: #1273b7;
}

.universal-quiz-instruction {
    width: 80px;
    height: 80px;
    background: #e7f1f7;
    border-radius: 10px;
    margin: 0 auto;
    font-weight: 600;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.universal-quiz-instruction-title {
    font-size: 40px;
    line-height: 40px;
}

.universal-quiz-instruction-description {
    font-size: 14px;
    line-height: 14px;
}

.universal-quiz-instruction-info {
    margin-top: 16px;
    font-size: 18px;
    line-height: 22px;
    color: #4f4f4f;
}

.vertical-line-150 {
    height: 150px;
    width: 0px;
    border: 1.25px solid #e0e0e0;
}

.horizontal-line-150 {
    width: 150px;
    height: 0px;
    border: 1.25px solid #e0e0e0;
}

.universal-quiz-instructions-button {
    height: 100%;
}

.universal-quiz-instructions-wrapper {
    padding-bottom: 40px;
}

.react-quizzes-builder form {
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px;
    width: 100%;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    min-height: 100%;
}

#content>.react-quizzes-quizz {
    position: relative;
}

.react-quizzes-builder .ElementWrapper {
    padding: 10px;
}

.react-quizzes-builder .ElementWrapper:hover {
    cursor: grab;
    box-shadow: inset 0 0 5px #ddd;
    background: #fff;
}

.react-quizzes-builder,
#root {
    height: 100%;
}

.react-quizzes-builder .ant-form-item-label,
.react-quizzes-quizz .ant-form-item-label {
    white-space: normal !important;
    text-align: center !important;
    line-height: normal !important;
    word-break: break-all;
}

.question-counter {
    margin: 46px 0 52px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 18px;
    color: #333333;
}

.dashed-line {
    border: 1px dashed #bdbdbd;
}

.react-quizzes-quizz .ant-radio-group {
    display: none;
    gap: 32px;
    justify-items: stretch;
    min-height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
}

.react-quizzes-quizz .question-options-div {
    text-align: center;
    margin-top: 15px;
}

.react-quizzes-quizz .question-options-div .ant-form-item-control {
    text-align: left;
}

.react-quizzes-quizz .question-options-div.radio-quiz {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 40px 60px;
}

.react-quizzes-quizz .question-options-div.radio-quiz .ant-form-item-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px !important;
    color: #333333;
    width: 100%;
    text-align: left !important;
    margin-bottom: 32px;
}

.react-quizzes-quizz .ant-radio-wrapper {
    width: 300px;
    max-width: 100%;
    min-height: 40px;
    height: fit-content;
    justify-items: stretch;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.react-quizzes-quizz .ant-radio-wrapper-checked {
    border: dashed 2px white;
    background-color: #1273b7 !important;
}

.aptitude-quiz-question-option-wrapper>div:first-child {
    display: flex;
}

.aptitude-quiz-question-option-wrapper>div:first-child label {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #4f4f4f !important;
}

.react-quizzes-quizz.aptitude-quiz .ant-radio-wrapper-checked {
    border: 1.25px solid #1273b7 !important;
    box-sizing: border-box;
    border-radius: 8px;
    background: #f3f8fb !important;
}

.react-quizzes-quizz.aptitude-quiz .ant-radio-wrapper-checked>span {
    color: #1273b7 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.react-quizzes-quizz .ant-radio-wrapper>span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
}

.slick-container {
    background: #219653 !important;
    border: 10px solid red;
}

.react-quizzes-quizz .sample-question.ant-radio-wrapper-checked {
    background-color: #f3fff8 !important;
    border: 2px solid #219653;
}

.react-quizzes-quizz .sample-question.ant-radio-wrapper-checked>span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #219653 !important;
}

.react-quizzes-quizz .ant-radio-wrapper-checked .radioImage {
    background-color: #1273b7 !important;
}

.react-quizzes-quizz .ant-radio {
    margin-left: 3%;
}

.react-quizzes-quizz .question-options-div .ant-form-item-label label {
    font-size: 20px;
    font-weight: bold;
    word-break: normal;
}


/* p img {
  max-height: 300px;
  max-width: 300px;
} */

#submit-button-div {
    text-align: center;
}

#quizName {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #1273b7;
}

.react-quizzes-builder .ant-avatar.ant-avatar-icon {
    color: inherit;
    background: transparent;
}

.react-quizzes-builder .ant-avatar.ant-avatar-circle {
    color: inherit;
    background: transparent;
}


/* makes questions display next to required symbol */

.react-quizzes-builder .ant-form-item-no-colon label>p:nth-child(1),
.react-quizzes-quizz .ant-form-item-no-colon label>p:nth-child(1) {
    display: inline;
}

.ReactQueryDevtools {
    display: none;
}

#content {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.universal-quiz-page-wrapper {
    height: 100%;
    background: url("https://d1dhn4cz1wmlqo.cloudfront.net/staging/ASSESSMENT_PROCESSOR/5B5DPD_Background.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.radioImageWrapper .ant-radio {
    display: none;
}

.react-quizzes-quizz .radioImage img {
    height: auto;
    width: 100%;
    display: block;
}

.react-quizzes-quizz .radioImage div {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
    color: black;
}

.radioImage {
    line-height: 1.5;
}

.radioImageOptions>div>.radioImageWrapper {
    border-radius: 0px;
    padding: 0;
    background-color: white;
    flex-basis: calc(50% - 40px);
    flex-direction: column;
    height: fit-content;
    width: fit-content;
}

.radioImageWrapper span:not([class]) {
    padding: 0;
    background-color: white;
}

#thank-you-modal {
    display: flex !important;
    overflow: hidden !important;
    justify-content: center !important;
}

#thank-you-modal .success-animation {
    display: block;
    width: 156px;
    height: 156px;
    margin: 0 auto;
}

#thank-you-modal.universal-quiz-popup .success-animation {
    width: 160px;
    height: 160px;
}

.reportContent {
    padding-top: 30px;
}

.invalid-report-id {
    text-align: center;
    font-family: "Poppins";
    font-size: 32px;
}

#thank-you-modal-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

#thank-you-modal {
    position: relative;
    height: fit-content;
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
}

#thank-you-modal .modal-content {
    border-radius: 10px 10px 0 0;
    height: 420px !important;
}

#thank-you-modal.universal-quiz-popup {
    width: 328px;
}

#thank-you-modal.universal-quiz-popup .modal-content {
    height: 100% !important;
}

#thank-you-modal.universal-quiz-popup .modal-body {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 16px 16px;
}

#thank-you-modal.universal-quiz-popup .modal-footer {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 16px 16px;
    height: 56px;
}

#thank-you-modal.universal-quiz-popup .heading {
    margin: 0 0 10px 0;
    font-weight: 500;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    line-height: 16px;
}

#thank-you-modal.universal-quiz-popup .heading div:nth-child(1) {
    font-size: 12px;
}

#thank-you-modal.universal-quiz-popup .heading div:nth-child(2) {
    font-size: 14px;
    margin-top: 5px;
}

#thank-you-modal.universal-quiz-popup #submitBtn {
    background: #219653;
    height: 40px;
}

#thank-you-modal .modal-body {
    min-height: 90%;
    background: white;
    padding: 0px 16px 0px 12px;
}

#container {
    height: 100%;
    width: 100%;
    position: relative;
}

#top-wave {
    position: relative;
}

#top-wave .desktop {
    display: block;
}

#top-wave .mobile {
    display: none;
}

#top-wave>img {
    width: 100%;
}

#top-wave>.report-title {
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: 50px;
}

.scroll-down-arrow-animation {
    cursor: pointer;
}

#quiz-page-loading-gif {
    position: fixed;
    left: 50%;
    top: 250px;
    transform: translate(-50%, -50%);
    z-index: 99;
}

#top-wave>.report-title>div:nth-child(2) {
    color: #efcf1b;
    font-size: 32px;
}

#report-wrapper {
    width: 100%;
    position: absolute;
    top: 180px;
    left: 50%;
    transform: translate(-50%);
}

.report-content {
    width: 1160px;
    max-width: 70%;
    margin: 0 auto;
    padding: 60px 60px 8px 60px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);
    border-radius: 8px;
}

.report-content>div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.report-content>div:nth-child(1)>img {
    max-width: 40%;
}

.report-content>div:nth-child(1)>.content {
    max-width: 60%;
    margin: 0 60px 0 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
}

#report-wrapper>div>div:nth-child(1)>p>span {
    display: block;
    margin-bottom: 16px;
}

.report-content>div:nth-child(2) {
    margin-top: 60px;
}

#report-wrapper .report-progress-bar {
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    border: 2.5px solid #1273b7;
    border-radius: 100px;
    position: relative;
    display: flex;
    align-items: center;
}

#report-wrapper .report-progress-bar>.bar {
    height: 85%;
    width: 100%;
    margin: 0 3px;
    background-color: #1273b7;
    border-radius: 100px;
}

.report-content>div:nth-child(2)>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #333333;
    text-align: center;
    margin-top: 32px;
}

#report-wrapper>div>div:nth-child(2)>div.report-progress-bar>div>span {
    line-height: 40px;
    position: absolute;
    right: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1273b7;
}

#reportData {
    text-align: left;
    font-size: 14px;
}

#reportHeading {
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
}

.option-div,
.option-value-div,
.option-image-div,
.toggle-button-div,
.delete-button-div {
    margin: 50px 20px;
    position: relative;
}

.quiz-score {
    text-align: center;
}

.quiz-score .animation {
    display: inline-flex;
}

.quiz-score .animation>div {
    height: 100px !important;
}

.quiz-score>div {
    text-align: center;
    margin: 0 auto;
}

.quiz-score>div:not(first-child) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
}

.quiz-score>div:nth-child(2) {
    font-size: 48px;
    line-height: 64px;
    color: #f2994a;
}

.quiz-score>div:nth-child(3) {
    font-size: 40px;
    line-height: 40px;
    color: #333333;
}

.quiz-score>div:nth-child(4) {
    display: none;
}

.vl {
    border-left: 1px solid #e0e0e0;
    height: 100%;
    position: absolute;
    left: -20px;
}

.universal-quiz-summary {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 24px;
}

.option-div>button {
    margin-top: 10px;
}

.slick-list {
    margin: 0 auto !important;
    width: 85%;
    padding: 10px 50px !important;
}

.slick-slide:not(.slick-center) .universal-quiz-question-wrapper {
    /* filter: blur(4px); */
}

.slick-center .universal-quiz-question-wrapper {
    filter: none;
}

.slick-next,
.slick-prev {
    height: 44px !important;
    width: 44px !important;
    color: red !important;
}

.slick-prev:before,
.slick-next:before {
    color: #1273b7 !important;
    background: #e7f1f7 !important;
    font-size: 44px !important;
}

.slick-next {
    /* right: 48px !important; */
}

.slick-prev {
    /* left: 48px !important; */
}

.correct-answer-toggle {
    height: 32px;
    display: flex;
    align-items: center;
}

.universal-quiz-question-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    height: fit-content !important;
    width: 296px !important;
    margin: 0 auto !important;
    display: block !important;
}

.aptitude-quiz-question-wrapper {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: fit-content !important;
    margin: 0 auto !important;
    display: block !important;
}

.apt .slider-wrapper {
    width: 1000px;
    margin: 0 auto;
}

.slider-wrapper .slick-track>div {
    padding: 10px;
}

.slider-wrapper .slick-prev {
    left: unset !important;
}

.slider-wrapper .slick-next {
    right: 0 !important;
}

.universal-quiz-question,
.aptitude-quiz-question {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    text-align: left;
}

.aptitude-quiz-question {
    padding: 20px;
}

.universal-quiz-answers {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.universal-quiz-answers>div {
    height: 40px;
    width: 100%;
    padding: 10px;
    gap: 10px;
    margin: 10px;
    border-radius: 30px;
    white-space: normal !important;
    line-height: 2;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 10px;
}

.aptitude-quiz-question-wrapper img {
    margin: 0 auto;
    display: inline-block !important;
}

.aptitude-quiz-question-wrapper .universal-quiz-answers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    justify-content: start;
    margin-top: 0;
}

.aptitude-quiz-answers-text,
.aptitude-quiz-answers-image {
    padding: 20px;
}

.universal-quiz-answers .user-answer {
    background: #fff6f6;
    border: 1px solid #e92e30;
    box-sizing: border-box;
    border-radius: 8px;
}

.universal-quiz-answers>.correct-answer {
    background: #f3fff8;
    border: 1px solid #219653;
    box-sizing: border-box;
    border-radius: 8px;
}

.universal-quiz-answers .user-answer:only-child {
    background: #f3fff8;
}

.universal-quiz-question-counter {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    text-align: center;
    margin-bottom: 30px;
}

.valueInputOption {
    margin-bottom: 52px;
}

#quiz-progess-section {
    text-align: center;
    padding: 0 20px;
}

.progress-bar>div,
.right-arrow,
.left-arrow,
.back-button,
.skip-button {
    display: inline-block;
}

#quiz-progess-section>div.progress-bar>div>div>span {
    position: absolute;
    right: 0;
}

#universal-quiz-type-progress {
    padding: 50px 16px 0 16px;
}

#universal-quiz-type-progress>div.progress-bar>div>div>span,
.random-quiz-type-progress>div.progress-bar>div>div>span {
    position: absolute;
    right: 0;
}

.right-arrow,
.left-arrow {
    cursor: pointer;
    color: white;
    width: 28px;
    height: 28px;
    background-color: #1273b7;
    border-radius: 50%;
    line-height: 2;
}

.left-arrow,
.back-button {
    float: left;
    display: none;
}

.right-arrow,
.skip-button {
    float: right;
    display: none;
}

.progress-bar>div {
    display: grid;
    border: 1.5px solid #1273b7;
    background-color: #ffffff !important;
    position: relative;
}

.progress-bar>div>div {
    height: 24px !important;
    margin: auto 1px;
}

#lead-squared-form-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.quiz-score {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stride-blue {
    color: #1273b7;
}

.correct-answers-count {
    color: rgb(43, 167, 43);
}

.horizontal-line {
    background: #e5e5e5e5;
    border: 1.5px solid #e0e0e0;
    margin: 10px;
}

#lead-squared-form>.form-description {
    margin: 10px;
}

#lead-squared-form>.form-description>div:nth-child(1) {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #333333;
}

#lead-squared-form>.form-description>div:nth-child(2) {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

#lead-squared-form .security-logo {
    margin-top: 22px;
    text-align: center;
}

#quiz-progess-section>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
}

#thank-you-modal .heading {
    margin: 24px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333333;
}

#thank-you-modal .heading div:nth-child(1) {
    font-size: 24px;
}

#thank-you-modal .heading div:nth-child(2) {
    font-size: 18px;
    margin-top: 10px;
}

#thank-you-modal .description {
    margin-top: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #333333;
}

#thank-you-modal .progress-wrapper {
    height: 28px;
    background-color: rgb(224, 224, 222);
    border-radius: 100px;
    width: 70%;
    margin: 4px auto;
}

#thank-you-modal .progress-wrapper>div {
    height: 28px;
    background-color: rgb(18, 115, 183);
    transition: width 1s ease-in-out 0s;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 99%;
}

#thank-you-modal .progress-wrapper span {
    padding: 5px;
    color: white;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 15px;
    display: initial;
}

#thank-you-modal #submitBtn {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border: none;
    background: #1273b7;
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

#thank-you-modal .profile_navigation_btn {
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: none;
    background: #fff !important;
    color: #219653 !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

#lead-squared-form input {
    background: #f6faff;
    border: none;
}

.single-input-asterik {
    position: absolute;
    top: 40%;
    right: 5%;
}

.double-input-asterik {
    position: absolute;
    top: 55%;
    right: 10%;
}

.universal-quiz-report {
    display: block !important;
}

.universal-quiz-report-mobile {
    display: none !important;
}

.universal-quiz-page-footer {
    position: absolute;
    bottom: 0;
    display: none;
}


/* styling for options in add/edit quiz form */

.quiz-options-row {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 32px;
    background: #ffffff;
}

.quiz-options-fields {
    display: flex;
}

.options-hl {
    border: 1px solid #e0e0e0;
    margin: 12px 0;
}

.options-vl {
    border: 1px solid #e0e0e0;
    margin-left: 24px;
    margin-right: 32px;
    width: 1px;
}

.text-input,
.value-input,
.image-input,
.correct-answer-input,
.score-input {
    padding: 37px 0 6px 0;
    position: relative;
}

.option-input-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 39px;
    gap: 5px;
}

.horizontal-line-100,
.vertical-line-100,
.spacer-mobile {
    display: none;
}

.slider-wrapper .slick-list {
    padding: 0 60px !important;
}

.aptitude-quiz-report-slider .aptitude-quiz-question {
    text-align: center;
}

.question-options-div .ant-form-item-label label:first-child {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #4f4f4f !important;
}

.react-quizzes-quizz .question-options-div.radio-quiz {
    padding: 16px 40px 100px 16px !important;
    margin: 16px !important;
}

@media only screen and (max-width: 500px) {
    .aptitude-quiz-question-wrapper .universal-quiz-answers {
        display: grid;
        grid-template-columns: 100%;
    }
    .aptitude-quiz-answers-text>div {
        margin: 0px !important;
    }
    .aptitude-quiz-answers-image {
        display: none !important;
    }
    .aptitude-quiz-reveal-answer-button {
        display: block;
    }
    .slider-wrapper {
        width: auto;
    }
    .slider-wrapper .slick-list {
        padding: 0 60px !important;
    }
    .spacer-mobile {
        display: block;
    }
    .horizontal-line-150,
    .vertical-line-150,
    .spacer-desktop {
        display: none;
    }
    .vertical-line-100 {
        display: block;
        height: 100px;
        border: 1.25px solid #e0e0e0;
    }
    .horizontal-line-100 {
        display: block;
        width: 100px;
        height: 0px;
        border: 1.25px solid #e0e0e0;
    }
    .aptitude-report-page-wrapper {
        justify-content: flex-start;
    }
    #aptitude-quiz-timer {
        top: 63px !important;
        right: 35px !important;
    }
    .aptitude-quiz.react-quizzes-quizz {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    #aptitude-quiz-content {
        max-width: 90% !important;
    }
    #aptitude-quiz-content.aptitude-quiz-report-summary {
        height: 480px !important;
        padding-top: 64px !important;
    }
    #aptitude-quiz-content .header {
        padding: 0 !important;
    }
    .aptitude-quiz-emoji {
        height: 80px !important;
        width: auto !important;
    }
    .aptitude-quiz-summary-header>div:first-child {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .aptitude-quiz-summary-header>div:nth-child(2) {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .universal-quiz-instruction {
        height: 60px !important;
        width: 60px !important;
    }
    .aptitude-quiz-summary-header {
        padding-bottom: 28px !important;
    }
    .horizontal-divider {
        padding: 16px auto !important;
    }
    .universal-quiz-instructions-wrapper {
        display: grid;
        grid-template-columns: 117px 1px 117px;
        justify-content: space-evenly;
        row-gap: 36px;
    }
    .aptitude-quiz-report-summary {
        gap: 32px 0 !important;
        justify-content: space-between !important;
    }
    .universal-quiz-page-footer {
        display: block;
    }
    .universal-quiz-page-wrapper {
        background: unset;
    }
    #thank-you-modal.universal-quiz-popup .success-animation {
        margin-bottom: 50px;
    }
    .skip-button {
        float: right;
        font-family: Poppins;
        font-size: 14px;
        line-height: 18px;
        color: #333333 !important;
        margin-right: 16px;
    }
    .react-quizzes-quizz .question-options-div.radio-quiz {
        padding: 16px 16px 100px 16px !important;
        margin: 16px !important;
    }
    .question-counter {
        font-size: 16px !important;
        line-height: 18px !important;
        padding: 0 16px !important;
        margin: 24px 0 !important;
    }
    .aptitude-quiz-content .question-counter {
        padding: 0px 0px !important;
    }
    .universal-quiz-question-counter {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .slick-list {
        width: 100%;
    }
    .slick-current .universal-quiz-question-wrapper {
        filter: none !important;
    }
    .universal-quiz-question-wrapper {
        width: 90% !important;
    }
    .slick-arrow {
        display: none !important;
    }
    .quiz-score {
        width: 100%;
    }
    .quiz-score>div:nth-child(3) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .quiz-score>div:nth-child(2) {
        display: none;
    }
    .quiz-score>div:nth-child(4) {
        display: block;
        font-size: 40px;
        line-height: 40px;
        color: #f2994a;
    }
    .universal-quiz-summary {
        margin: 24px auto;
        width: 80%;
    }
    .radioImage {
        width: 130px;
    }
    .react-quizzes-quizz .radioImage img {
        max-height: 80px;
        margin: 0 auto;
    }
    #quiz-progess-section {
        background: url("https://d1dhn4cz1wmlqo.cloudfront.net/staging/ASSESSMENT_PROCESSOR/PS35IR_Rectangle 603.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom;
        height: 85px;
        position: relative;
    }
    #quiz-progess-section>p {
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translate(-50%);
        font-size: 12px;
        line-height: 10px;
        font-weight: 600;
        font-family: Poppins;
    }
    #quiz-progess-section>.progress-bar {
        position: relative;
        top: 15px;
    }
    #quiz-progess-section>.left-arrow {
        position: absolute;
        top: 15px;
        left: 20px;
    }
    #quiz-progess-section .right-arrow {
        position: absolute;
        top: 15px;
        right: 20px;
    }
    .progress-bar>div {
        width: 75% !important;
    }
    /* For mobile phones: */
    /* p img {
    height: 150px;
    width: 150px;
  } */
    .aptitude-quiz-question-option-wrapper .ant-radio-wrapper {
        width: 100% !important;
        margin: 0 !important;
    }
    .react-quizzes-quizz .ant-radio-wrapper {
        margin-left: 5%;
        margin-right: 5%;
    }
    #footerGraphic img {
        height: 50px;
    }
    .react-quizzes-quizz .radioImage div {
        font-size: 10px;
    }
    .reportContent {
        width: 350px;
    }
    #quiz-progess-section>div.progress-bar>div>div>span {
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 12px;
        font-family: Poppins;
    }
    #thank-you-modal .success-animation {
        width: 100px;
        height: 100px;
    }
    #thank-you-modal.universal-quiz-popup .success-animation {
        margin-bottom: 0;
    }
    .lead-form-header {
        font-size: 24px !important;
        justify-content: center;
    }
    #lead-squared-form>div:nth-child(1) {
        flex-direction: column;
    }
    #lead-squared-form input[name="first_name"],
    #lead-squared-form input[name="last_name"] {
        width: 94%;
    }
    #lead-form-description {
        padding: 0 40px;
    }
    .horizontal-line {
        margin: 0 auto;
        width: 43.1px !important;
        border: 2px solid #efcf1b !important;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .double-input-asterik {
        position: absolute;
        top: 55%;
        right: 5%;
    }
    #top-wave .desktop {
        display: none;
    }
    #top-wave .mobile {
        display: block;
    }
    #report-wrapper {
        top: 140px;
    }
    .react-quizzes-quizz .question-options-div .ant-form-item-label label {
        font-size: 16px;
    }
    .report-content {
        padding: 32px 19.5px 8px 19.5px;
        max-width: 85%;
    }
    .report-content>div:nth-child(1) {
        width: 100%;
        position: relative;
        display: block;
    }
    .report-content>div:nth-child(1)>img {
        max-width: 100%;
    }
    #report-wrapper>div>div:nth-child(1)>p>span {
        margin-top: 16px;
    }
    .report-content>div:nth-child(1)>.content {
        margin-right: 0;
        max-width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
    }
    #report-wrapper .report-progress-bar {
        margin-top: 16px;
        height: 40px;
    }
    .scroll-down-arrow-animation>div {
        height: 40px !important;
        width: 40px !important;
    }
    #report-wrapper>div>div:nth-child(2)>div.report-progress-bar>div>span {
        line-height: 30px;
    }
    .report-content>div:nth-child(2) {
        margin: 0;
    }
    .report-content>div:nth-child(2)>p {
        line-height: 20px;
        font-size: 14px;
        margin: 16px auto;
        padding: 0;
    }
    #top-wave>.report-title {
        font-size: 16px;
        line-height: 1.2;
    }
    #lead-squared-form-wrapper {
        padding: 0 !important;
        width: 100%;
        overflow: hidden;
    }
    .react-quizzes-quizz.aptitude-quiz .ant-radio-group {
        grid-template-columns: 1fr !important;
        justify-items: center;
    }
    .radioImageOptions>.ant-radio-group {
        grid-template-columns: 1fr 1fr !important;
        justify-content: center;
    }
    .react-quizzes-quizz .question-options-div.radio-quiz .ant-radio-wrapper {
        margin: 0;
    }
    .react-quizzes-quizz .ant-radio-group {
        grid-template-columns: auto !important;
    }
    .radioImageOptions>.ant-radio-group {
        grid-template-columns: 1fr 1fr !important;
    }
    #lead-squared-form>.form-description>div:nth-child(1) {
        font-size: 24px;
        text-align: center;
        line-height: 36px;
    }
    #lead-squared-form>.form-description>div:nth-child(2) {
        text-align: center;
        font-size: 14px;
        line-height: 21px;
        width: 210px;
        margin: 0 auto;
    }
    #lead-squared-form>div:nth-child(3) {
        flex-direction: column;
    }
    .universal-quiz-instruction-title {
        font-size: 20px;
        line-height: 40px;
    }
    .universal-quiz-instruction-info {
        /* margin-top: 16px; */
        margin-top: 7px;
        font-size: 16px;
        line-height: 22px;
        color: #4f4f4f;
    }
}

.ElementWrapper .ant-slider {
    display: none;
}

.question_title_quiz p img {
    max-width: 100%;
    max-height: max-content;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}


/* Classes for Custom Slider : react-quizzes */

.ant-slider {
    margin-left: 25px;
}

.ant-slider-step {
    background-color: #aecbdf;
    height: 10px;
    border-radius: 40px;
}

.ant-slider-step::before {
    content: '';
    color: transparent;
    position: absolute;
    width: 20px;
    height: 10px;
    margin-left: -15px;
    border-radius: 40px;
    background-color: #aecbdf;
}

.ant-slider-step::after {
    content: '';
    color: transparent;
    position: absolute;
    width: 20px;
    height: 10px;
    left: 96%;
    border-radius: 40px;
    background-color: #aecbdf;
}

.ant-slider-dot {
    height: 20px;
    width: 2px !important;
    border-radius: 0px;
    border-width: 0px;
    background-color: #aecbdf;
    border-color: #aecbdf;
    margin-top: -3px;
}

.ant-slider-handle {
    background-color: #1c6ae4;
    height: 30px;
    width: 30px;
    margin-top: -10px;
    border-color: #fff;
    border-width: 3px;
}


/* .ant-slider-handle:focus {
  box-shadow: none;
  border-color: #fff;
} */

.ant-slider-mark {
    margin-top: 10px;
}

.ant-slider-mark-text {
    max-width: 65px;
}

@media only screen and (max-width: 767px) {
    .react-quizzes-quizz .question-options-div.radio-quiz .ant-form-item-label {
        margin-bottom: 0px;
    }
    .ant-slider-mark {
        width: 98%;
    }
}

@media (min-width: 767px) {
    .ant-slider-mark-text {
        min-width: 80px;
    }
}

@media (min-width: 1200px) {
    .aptitude-quiz-page-wrapper {
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}