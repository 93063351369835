.about_us_container {
  display: flex;
  justify-content: center;
}
.lost_container {
  background: rgba(18, 115, 183, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  border-radius: 80px;
}
.left_lost_container {
  width: 40%;
}
.lost_img_self {
  width: 60%;
}
.lost_content_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}
.lost_text {
  font-family: "Poppins";

  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
}
.lost_under_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 24px;
  margin: 0px 0 10px 0;
}
.lost_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  font-family: Roboto;
  letter-spacing: -0.01em;
  text-align: justify;

  color: #4f4f4f;
}
.lost_img_container {
}
@media (max-width: 767px) {
  .lost_img_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 53%;
    margin-top: -19%;
  }
  .confused_img_container {
    width: 57%;
    margin-top: -23%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .confident_img_container {
    width: 77%;
    margin-top: -59%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .about_us_container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 30% auto 10% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    border-radius: 10px;
    padding: 0 4% 5% 4%;
    margin: auto;
  }
  .left_lost_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .lost_img_self {
    width: 100%;
    max-width: 100%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .lost_text {
    line-height: 39px;
    font-size: 29px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    margin: 0;
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .lost_img_container {
    width: 81%;
    margin: -51px 0 0 0;
  }
  .confused_img_container {
    width: 68%;
    margin: -68px 0 0 0;
  }
  .confident_img_container {
    width: 100%;
    margin: -152px 0 0 -41px;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 12% auto 10% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
    border-radius: 50px;
    padding: 0 2% 0% 2%;
    margin: 0 auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_self {
    max-width: 100%;
    width: 100%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 3% 0 3% 0;
  }
  .lost_text {
    line-height: 39px;
    font-size: 29px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 13px;
    line-height: 18px;
    text-align: justify;
  }
}

@media (min-width: 991px) {
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10% auto 6% auto;
  }
  .lost_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 71%;
    border-radius: 48px;
    padding: 0 3% 0% 0%;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_container {
    width: 81%;
    margin: -51px 0 0 0;
  }
  .confused_img_container {
    width: 61%;
    margin: -68px 0 0 0;
  }
  .confident_img_container {
    width: 98%;
    margin: -194px 0 0 -33px;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  }
  .lost_text {
    line-height: 47px;
    font-size: 37px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 16px;
    line-height: 21px;
  }
}

@media (min-width: 1200px) {
  .res_about_us_container {
    max-width: 1200px;
    margin: auto;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    margin: 81px auto 1% auto;
  }
  .lost_container {
    width: 70%;
    border-radius: 100px;
    padding: 0 3% 0 2%;
    margin: 0% auto 5% auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_container {
    width: 79%;
    margin-top: -25%;
  }
  .confused_img_container {
    width: 65%;
    margin: -29% 0 0 0;
  }
  .confident_img_container {
    width: 98%;
    margin: -72% 0 0 -81px;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 2% 0 3% 0;
  }
  .lost_text {
    line-height: 51px;
    font-size: 41px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 19px;
    line-height: 24px;
    margin-top: 8px;
    text-align: left;
  }
  .lost_under_line {
    width: 35px;
    margin: 0px 0 10px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
}

@media (min-width: 1400px) {
  .res_about_us_container {
    max-width: 1440px;
    margin: auto;
  }
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 81px auto 0% auto;
  }
  .lost_container {
    width: 1003px;
    border-radius: 138px;
    padding: 0 3% 0 2%;
    margin: 0% auto 0% auto;
    min-height: 275.81px;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .lost_img_container {
    width: 291px;
    margin-top: -32%;
    height: 364px;
    margin-left: 46px;
  }
  .confused_img_container {
    margin: -29% 0 0 58px;
    /* width: 272px; */
    /* margin-top: -64%; */
    /* height: 304px; */
    /* margin-left: 46px; */
    width: 68%;
  }
  .confident_img_container {
    margin: -65% 0 0 -27px;
    width: 100%;
  }
  .lost_img_self {
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 0% 0 0% 0;
  }
  .lost_text {
    margin: 30.98px 0 0 0;
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
  }

  .lost_extra_content {
    margin-top: 8px;
    text-align: justify;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 35.98px;
  }
  .lost_under_line {
    width: 40px;
    margin: 4px 0 24.98px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
}

/* @media (min-width: 1200px) {
  .about_us_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 81px auto 10% auto;
    width: 1200px;
  }
  .lost_container {
    width: 70%;
    border-radius: 100px;
    padding: 0 3% 0 2%;
    margin: 0% auto 5% auto;
  }
  .left_lost_container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lost_img_self {
    width: 86%;
    margin-top: -25%;
  }
  .lost_content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 2% 0 3% 0;
  }
  .lost_text {
    line-height: 51px;
    font-size: 41px;
    margin: 0;
  }
  .lost_under_line {
    width: 24px;
    margin: 0px 0 10px 0;
  }
  .lost_extra_content {
    font-size: 20px;
    line-height: 29px;
    margin-top: 8px;
    text-align: left;
  }
  .lost_under_line {
    width: 35px;
    margin: 0px 0 10px 0;

    border-bottom: 5px solid rgba(239, 207, 27, 1);
  }
} */
