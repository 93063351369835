.info-icon {
  opacity: 0.6;
  color: gray;
  font-size: 30px;
}
.avatar {
  height: 1rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 100%;
}

.phrase-tooltip {
  position: relative;
  cursor: pointer;
}
.phrase-tooltip:hover::after {
  color: #222;
  padding: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 1.5rem;
  position: absolute;
  width: max-content;
  border-radius: 10px;
  content: attr(data-tooltip);
  background-color: white;
  transform: translateX(-100%);
  box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
}

@media only screen and (max-width: 768px) {
  .phrase-tooltip:hover::after {
    color: #222;
    left: -68vw;
    width: 90vw;
    bottom: -16vw;
    padding: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    margin-left: 1rem;
    position: absolute;
    line-height: 2rem;
    content: attr(data-tooltip);
    transform: translateX(-20%);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgb(0, 0, 0, 0.2) 1rem 1rem 2rem;
  }
}
