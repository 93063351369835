.disclaimer_container {
    padding: 20px;
    margin: 5rem 0;
    border-radius: 10px;
    background-color: #fff;
}

.disclaimer_container > p {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.disclaimer_container > h1 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}


/* styles */
.bLXDyt {
    background-color: transparent !important;
    padding: 0 !important;
}

.container-md {
    max-width: calc(1400px + 40px);
    padding: 0 20px;
    margin: 0 auto;
}

.report-hero-section-alignment {
    background: #FAF9FF;
    padding: 60px 0 120px 0;
    font-family: 'Poppins', sans-serif;

}

.report-hero-section-alignment .image-center-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 100px 0;
}

.report-hero-section-alignment .image-center-alignment > img {
    max-width: 434px;
    cursor: pointer;
    height: 80px;
    object-fit: cover;
}

.report-hero-section-alignment .report-hero-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}


.take-section-banner-box-center-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items h1 {
    font-weight: 700;
    font-size: 42px;
    color: #352958;
    line-height: 52px;
    margin: 0 0 30px 0;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items p:last-child {
    margin: 0;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    margin: 0 0 20px 0;
    letter-spacing: 0.02em;
    text-align: justify;
    color: #706E84;
}

.report-hero-section-alignment .report-hero-grid .report-hero-grid-items .chart-image img {
    max-width: 100%;
}

.management-aptitide-section-alignemnt {
    font-family: 'Poppins', sans-serif;
    padding: 120px 0 0 0;
    background-color: #fff;
}

.all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(1) {
    order: 2;
}

.all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(2) {
    order: 1;
}

.management-aptitide-section-alignemnt .common-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 0 0 60px 0;
    align-items: center;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment img {
    max-width: 500px;
    object-fit: cover;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment {
    display: flex;
    justify-content: center;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style {
    padding: 0 0 15px 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style h2 {
    font-weight: 500;
    color: #352958;
    font-size: 32px;
    margin: 0 0 10px 0;
    line-height: 40px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style p {
    font-weight: 500;
    font-size: 16px;
    color: #A3A2B4;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style {
    padding: 0 0 20px 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p:last-child {
    margin: 0;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul {
    list-style: devanagari;
    margin: 0;
    padding: 0 0 0 15px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul li {
    font-weight: 400;
    padding: 0 0 10px 0;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    line-height: 26px;
}

.management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p {
    font-weight: 500;
    color: #352958;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 15px 0;
    letter-spacing: 0.02em;
}

.management-aptitide-section-alignemnt .score-box {
    width: 574px;
    background: #F8F7FC;
    padding: 15px;
    margin: 0 0 15px 0;
    border-radius: 10px;
}

.score-box .score-grid {
    display: grid;
    grid-template-columns: 60px 1fr auto;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}

.score-box .score-grid .score-grid-items .rounded-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0px 40px 60px #ECE6FF;

    justify-content: center;
    cursor: pointer;
}

.score-box .score-grid .score-grid-items .rounded-icon img {
    max-width: 25px;
}

.score-box .score-grid .score-grid-items:last-child {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.score-box .score-grid .score-grid-items h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: #5330BA;
}

.score-box .score-grid .score-grid-items p {
    font-weight: 500;
    font-size: 18px;
    margin: 0 0 10px 0;
    line-height: 28px;
    color: #352958;
}

.score-box .score-grid .score-grid-items .score-range {
    width: 100%;
    background: #FFFFFF;
    height: 14px;
    border-radius: 30px;
    position: relative;
}

.score-box .score-grid .score-grid-items .score-range .score-range-active {
    background: #FFC700;
    border-radius: 10px;
    height: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
}

.take-section-banner {
    font-family: 'Poppins', sans-serif;
    background-image: url("../../../assets/images/bg-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 80px 0;
    display: flex;
    align-content: center;
}

.take-section-banner .take-box {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 0 80px 80px 80px;
}

.take-section-banner .take-box .image-center-alignment {
    display: flex;
    padding: 0 0 0px 0;
    justify-content: center;
}

.take-section-banner .take-box .image-center-alignment img {
    max-width: 366px;
    height: 350px;
}

.take-section-banner .take-box .heigh-alignment {
    padding: 0 300px;
    display: grid;
    grid-template-columns: 1fr 125px;
    gap: 0;
}

input:focus {
    outline: none;
}

.take-section-banner .take-box .heigh-input input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px 0px 0px 20px;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-left: 20px;
    width: 100%;
    height: 60px;
    text-align: center;
}

.take-section-banner .take-box .heigh-input ::placeholder {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
}

.take-section-banner .take-box .button-style button {
    width: 100%;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(135deg, #9173E9 0%, #E128EE 100%);
    border-radius: 0px 20px 20px 0px;
    cursor: pointer;
    height: 60px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.take-section-banner .take-box .button-style p {
    width: 100%;
    color: #FFFFFF;
    border: none;
    background: linear-gradient(135deg, #9173E9 0%, #E128EE 100%);
    border-radius: 0px 20px 20px 0px;
    height: 60px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-box-button-alignment {
    padding: 50px 0 0 0;
}

.text-box-button-alignment p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 30px 0;
    color: #fff;
    text-align: center;
    text-align: justify;
}

.text-box-button-alignment div {
    display: flex;
    justify-content: center;
}

.text-box-button-alignment div button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgba(56, 39, 88, 0.2);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;
}

.three-month-section {
    font-family: 'Poppins', sans-serif;
    padding: 90px 0px 50px 0;
    background-color: #fff;
}

.three-month-section .page-title {
    padding: 0 0 90px 0;
}

.three-month-section .page-title2 {
    padding: 0 0 90px 0;
}

.three-month-section .page-title h3 {
    font-weight: 700;
    font-size: 42px;
    max-width: 850px;
    margin: 0 auto;
    letter-spacing: -0.01em;
    line-height: 52px;
    color: #352958;
    position: relative;
    text-align: center;
}

.three-month-section .page-title2 h3 {
    font-weight: 700;
    font-size: 42px;
    max-width: 850px;
    margin: 0 auto;
    letter-spacing: -0.01em;
    line-height: 52px;
    color: #352958;
    position: relative;
    text-align: center;
}

.three-month-section .page-title h3::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}


.three-month-section .three-month-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 110px;
}

.three-month-section .three-month-grid-items:nth-child(1) {
    background: #FFFFFF;
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.05));
    padding: 50px 60px;
    border-radius: 50px;
}

.three-month-section .three-month-grid-items p {
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.02em;
    color: #706E84;
    line-height: 36px;
    margin: 0 0 30px 0;
}

.three-month-section .three-month-grid-items h3 {
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 10px 0;
    letter-spacing: 0.02em;
    color: #352958;
    line-height: 45px;
}

.three-month-section .three-month-grid-items .all-text-alignment h4 {
    font-weight: 600;
    font-size: 32px;
    color: #352958;
    letter-spacing: 0.02em;
    line-height: 42px;
    text-align: left;
}

.three-month-section .three-month-grid-items .all-text-alignment div {
    padding: 0 0 40px 0;
}

.three-month-section .three-month-grid-items .all-text-alignment div:last-child {
    padding: 0;
}

.three-month-section .three-month-grid-items .all-text-alignment h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #A3A2B4;
    letter-spacing: 0.02em;
}

.container-md-2 {
    max-width: calc(1310px + 40px);
    padding: 0 20px;
    margin: 0 auto;
}

.our-promise-section {
    font-family: 'Poppins', sans-serif;
    background: #F9F7FD;
    padding: 50px 0;
}

.our-promise-section .page-title h2 {
    font-weight: 700;
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    position: relative;
    text-align: center;
    line-height: 52px;
}

.our-promise-section .page-title {
    padding: 0 0 90px 0;
}

.our-promise-section .page-title2 {
    padding: 0 0 90px 0;
}

.our-promise-section .page-title h2::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}

.our-promise-section .page-title2 h2::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}

.our-promise-section .our-grid {
    display: grid;
    grid-template-columns: 350px 1fr;
    gap: 160px;
    align-items: center;
}

.our-promise-section .profile-details {
    padding: 15px 0 0 0;
}

.our-promise-section .profile-details span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A3A2B4;
    display: block;
}

.our-promise-section .profile-details p {
    font-weight: 500;
    color: #352958;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 3px 0;
}

.our-promise-section .profile-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    height: 326px;
}

.our-promise-section .child-text-style p {
    font-weight: 400;
    font-size: 18px;
    color: #352958;
    margin: 0 0 30px 0;
    line-height: 28px;
    text-align: justify;
}

.career-section-alignment {
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    padding: 50px 0 0 0;
}

.career-section-alignment .page-title h4 del {
    color: #514EFF;
}

.career-section-alignment .page-title2 h4 del {
    color: #514EFF;
}

.career-section-alignment .page-title h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    max-width: 950px;
    margin: 0 auto 30px auto;
    text-align: center;
    letter-spacing: -0.01em;
}

.career-section-alignment .page-title2 h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 52px;
    max-width: 950px;
    margin: 0 auto 30px auto;
    text-align: center;
    letter-spacing: -0.01em;
}

.career-section-alignment .page-title p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    text-align: center;
    position: relative;
}

.career-section-alignment .page-title2 p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    text-align: center;
    position: relative;
}

.career-section-alignment .page-title p::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}

.career-section-alignment .page-title2 p::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}

.career-section-alignment .page-title {
    padding: 0 0 100px 0;
}

.career-section-alignment .page-title2 {
    padding: 0 0 100px 0;
}

.new-col-grid {
    display: grid;
    padding: 0 0 100px 0;
    grid-template-columns: 1fr 421px;
    gap: 232px;
}

.new-col-grid .new-dummy-text-style p {
    font-weight: 500;
    font-size: 16px;
    color: #0F0BCB;
    margin: 0 0 5px 0;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.new-col-grid .new-dummy-text-style {
    padding: 0 0 20px 0;
}

.new-col-grid .new-dummy-text-style span {
    display: block;
    font-weight: 400;
    color: #281E42;
    font-size: 16px;
    line-height: 25px;
}

.new-col-grid .all-question-box-alignment .question-box {
    margin: 0;
}

.new-col-grid .all-question-box-alignment .question-box {
    border: 1px solid #D9D9D9;
    margin: 0 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.question-box-header {
    padding: 20px;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    gap: 10px;

}

.question-box-header-items .taj-icon img {
    cursor: pointer;
}

.question-box-header-items h6 {
    font-weight: 500;
    color: #281E42;
    font-size: 18px;
    line-height: 27px;
}

.question-box-header-items .action-button {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #D9D9D9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.new-text-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.new-text-show {
    max-height: calc(100vh - 0px);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.text-style {
    padding: 0 20px 16px 20px;
}

.text-style p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #7C7492;
}

.new-col-grid .new-col-grid-items .new-image-style img {
    max-width: 100%;
}

.two-col-grid {
    display: grid;
    grid-template-columns: 550px 1fr;
    padding: 0 0 50px 0;
    gap: 160px;
}

.two-col-grid .two-col-grid-items .image-style img {
    max-width: 100%;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style p {
    font-weight: 500;
    font-size: 16px;
    color: #0F0BCB;
    margin: 0 0 5px 0;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style {
    padding: 0 0 20px 0;
}

.two-col-grid .two-col-grid-items .new-dummy-text-style span {
    display: block;
    font-weight: 400;
    color: #281E42;
    font-size: 16px;
    line-height: 25px;
}

.two-col-grid .two-col-grid-items .all-question-box-alignment .question-box {
    margin: 0;
}

.two-col-grid .two-col-grid-items .all-question-box-alignment .question-box {
    border: 1px solid #D9D9D9;
    margin: 0 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.question-box-header {
    padding: 20px;
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    align-items: center;
    gap: 10px;

}

.question-box-header-items .taj-icon img {
    cursor: pointer;
}

.question-box-header-items h6 {
    font-weight: 500;
    color: #281E42;
    font-size: 18px;
    line-height: 27px;
}

.question-box-header-items .action-button {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #D9D9D9;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.question-box-header-items .action-button-blue {
    border: 1px solid #D9D9D9;
    height: 30px;
    font-size: 25px;
    border-radius: 50%;
    color: #0F0BCB;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.new-text-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: .3s ease-in-out;
}

.new-text-show {
    max-height: calc(100vh - 0px);
    overflow: hidden;
    transition: .3s ease-in-out;
}

.text-style {
    padding: 0 20px 0 30px;
}

.text-style p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #7C7492;
}

.lorem-text-style-section {
    font-family: 'Poppins', sans-serif;
    background: #F9F7FD;
    padding: 50px 0;
}

.lorem-text-style-section .page-title h1 {
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    font-weight: 600;
    line-height: 52px;
    position: relative;
    text-align: center;
}

.lorem-text-style-section .page-title2 h1 {
    font-size: 42px;
    letter-spacing: -0.01em;
    color: #352958;
    font-weight: 600;
    line-height: 52px;
    position: relative;
    text-align: center;
}

.lorem-text-style-section .page-title h1::before {
    position: absolute;
    bottom: -30px;
    background-image: url("../../../assets/images/text-vectore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
}

.lorem-text-style-section .page-title {
    padding: 0 0 90px 0;
}

.lorem-text-style-section .page-title2 {
    padding: 0 0 90px 0;
}

.lorem-text-style-section .lorem-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 0 0 60px 0;
}

.lorem-text-style-section .pay-now-button {
    display: flex;
    justify-content: space-between;

}

.lorem-text-style-section .pay-now-button div {
    display: flex;
    width: 48%;
}

.lorem-text-style-section .pay-now-button button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgb(56 39 88 / 20%);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;

}

.lorem-text-style-section .lorem-grid .lorem-grid-items {
    border: 2px solid #D9D9D9;
    padding: 20px;
    border-radius: 20px;
}

.lorem-text-style-section .lorem-header {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
}

.lorem-text-style-section .lorem-header div span {
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding-left: 15px;
    line-height: 24px;
    color: #7C7492;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 10px 0;
    color: #352958;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p {
    font-weight: 500;
    color: #7C7492;
    font-size: 16px;
    line-height: 24px;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p span {
    font-weight: 700;
    color: #7C7492;
    font-size: 16px;
    line-height: 24px;

}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #D9D9D9;
}

.lorem-text-style-section .child-box {
    padding: 20px 0 0 0;
}

.lorem-text-style-section .child-box h6 {
    font-weight: 500;
    font-size: 20px;
    color: #352958;
    line-height: 29px;
    margin: 0 0 15px 0;
}

.lorem-text-style-section .child-box h6 {
    font-weight: 500;
    font-size: 20px;
    color: #352958;
    line-height: 29px;
    margin: 0 0 15px 0;
}

.lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid {
    padding: 0;
}

.lorem-text-style-section .icon-text-grid {
    display: grid;
    grid-template-columns: 30px 1fr;
    padding: 0 0 20px 0;
    gap: 10px;
}

.lorem-text-style-section .icon-text-grid img {
    position: relative;
    top: 10px;
}

.lorem-text-style-section .icon-text-grid p {
    font-weight: 400;
    font-size: 16px;
    color: #7C7492;
    line-height: 26px;
}

.lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid img {
    max-width: 16px;
}

.lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p:last-child {
    color: #E76262;
}

.classess-banner {
    background-image: url("../../../assets/images/bg-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding: 80px 0;
}

.classess-banner2 {
    background-color: #044063;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding: 80px 0;
}

.classess-banner2 .slick-list {
    width: 100% !important;
}

.classess-banner .slick-list {
    width: 100% !important;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
    z-index: 999;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: -50px;
    transform: translateY(-50%);
    z-index: 999;
}

.same-slider-class-arrow {
    border: 3px solid rgb(255, 255, 255, .1);
    width: 40px;
    background-color: transparent;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.classess-banner2 .slick-slide {
    padding: 0 20px;
}

.classess-banner .slick-slide {
    padding: 0 20px;
}

.classess-page-title {
    padding: 0 0 60px 0;
}

.classess-page-title p {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #BAA8EB;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 26px;
    text-align: center;
}

.classess-page-title h1 {
    font-weight: 700;
    font-size: 42px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin: 0 0 20px 0;
    line-height: 52px;
}

.slider-section-card {
    background: #FFFFFF;
    padding: 25px 10px 0;
    min-height: 540px;
    border-radius: 20px;
}

@media (min-width: 320px) and (max-width: 640px) {
    .slider-section-card {
        min-height: 446px;
    }
}

.slider-image img {
    width: 100%;
    height: 280px;
    object-fit: contain;
}

.slider-card-details {
    padding: 24px 29px;
}

.slider-card-details h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 8px 0;
    letter-spacing: -0.01em;
    color: #352958;
}

.slider-card-details p {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #352958;
    opacity: .5;
    margin: 0 0 12px 0;
    line-height: 24px;
    /*min-height: 50px;*/
}

.slider-card-details span {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #352958;
    opacity: .5;
    margin: 0 0 12px 0;
    line-height: 24px;
    display: block;
}

@media (min-width: 1360px) and (max-width: 1560px) {
    .slider-card-details span {
        min-height: 10px;
    }
}

@media (min-width: 320px) and (max-width: 640px) {
    .slider-card-details span {
        min-height: 0px;
    }
}

.slider-card-image-alignment {
    display: flex;
    align-items: center;
}

.slider-card-image-alignment div:last-child {
    margin: 0;
}

.slider-card-image-alignment div img {
    cursor: pointer;
    max-width: 60px;
    height: 30px;
    object-fit: contain;
}

.slider-card-image-alignment div {
    margin-right: 48px;
}

.get-started-button-center-alignment {
    display: flex;
    padding: 60px 0 0 0;
    align-items: center;
    justify-content: center;
}

.get-started-button-center-alignment button {
    padding: 16px 32px;
    border: none;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(135deg, #5330BA 0%, #8E2596 100%);
    box-shadow: 0px 10px 30px rgb(56 39 88 / 20%);
    border-radius: 15px;
    line-height: 30px;
    cursor: pointer;
    color: #F2EFFB;
}

.see-section-alignment {
    background-color: #fff;
    padding: 60px 0;
}

.see-section-alignment .page-title h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0 0 20px 0;
    color: #352958;
}

.see-section-alignment .page-title2 h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 52px;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0 0 20px 0;
    color: #352958;
}

.see-section-alignment .page-title p {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    position: relative;
    text-align: center;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
}

.see-section-alignment .page-title2 p {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #A3A2B4;
    position: relative;
    text-align: center;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
}

.see-section-alignment .page-title {
    padding: 0 0 100px 0;
}

.see-section-alignment .page-title2 {
    padding: 0 0 100px 0;
}

.see-section-alignment .page-title p::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    background-image: url("../../../assets/images/new-text-vectore.svg");
    background-repeat: no-repeat;
    width: 200px;
    height: 4px;
    background-size: cover;
    content: "";
}

.see-section-alignment .slick-list {
    margin: 0 -20px;
}

.see-section-alignment .slick-slide {
    padding: 0 20px;
}

.see-section-alignment .first-card {
    background: #FFFFFF;
    border: 1px solid #d0d0d069;
    border-radius: 20px 20px 0px 0px;
    padding: 28px 40px;
    min-height: 400px;
}

.sec-card {
    height: 142px;
    background: #F2EFFB;
    border-radius: 0px 0px 20px 20px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}


.sec-card .rating-alignment {
    display: flex;
    align-items: center;
}

.sec-card .rating-alignment img {
    margin-right: 5px;
    cursor: pointer;
}

.sec-card .rating-alignment img:last-child {
    margin: 0;
}

.sec-card .text-s p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: rgba(53, 41, 88, 0.65);
}

.sec-card .text-s h5 {
    font-weight: 500;
    font-size: 24px;
    color: #352958;
    line-height: 32px;
}


.first-card .card-profile-image-alignment img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
}

.first-card .card-profile-image-alignment {
    padding: 0 0 30px 0;
}

.new-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #281E42;

}

.see-section-alignment .slick-list {
    width: 100%;
}

@media screen and (max-width: 1500px) {
    .container-md {
        max-width: 100%;
        padding: 0 80px;
    }

    .take-section-banner .take-box .image-center-alignment img {
        max-width: 266px;
        height: 250px;
    }

    .text-box-button-alignment p {
        font-size: 16px;
        line-height: 26px;
    }

    .take-section-banner .take-box {
        border-radius: 30px;
    }
}

@media screen and (max-width: 992px) {
    .report-hero-section-alignment .report-hero-grid,
    .three-month-section .three-month-grid,
    .our-promise-section .our-grid,
    .two-col-grid,
    .new-col-grid,
    .lorem-text-style-section .lorem-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .management-aptitide-section-alignemnt .common-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .take-section-banner .take-box {
        padding: 40px;
    }

    .container-md-2,
    .container-md {
        max-width: 100%;
        padding: 0 40px;
    }
}

@media screen and (max-width: 767px) {
    .report-hero-section-alignment .image-center-alignment > img {
        max-width: 260px;
        height: 35px;
    }

    .report-hero-section-alignment {
        padding: 20px 0 20px 0;
        height: auto;
    }

    .classess-banner .slick-slide {
        padding: 0 10px;
    }

    .left-arrow {
        top: auto;
        bottom: -55px;
        left: 44%;
        transform: translateX(-50%);
    }

    .right-arrow {
        top: auto;
        bottom: -55px;
        left: 60%;
        transform: translateX(-50%);
    }

    .classess-banner {
        padding: 40px 0;
    }

    .see-section-alignment {
        padding: 70px 0;
    }

    .see-section-alignment .page-title h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .see-section-alignment .page-title2 h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .see-section-alignment .page-title p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 312px;
    }

    .see-section-alignment .page-title2 p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 312px;
    }

    .slider-image img {
        height: 210px;
        width: 100%;
        object-fit: cover;
        border-radius: 6px;
    }

    .get-started-button-center-alignment {
        padding: 70px 0 0 0;
    }

    .see-section-alignment .slick-slide {
        padding: 0 15px;
    }

    .first-card .card-profile-image-alignment img {
        width: 60px;
        height: 60px;
    }

    .sec-card .text-s p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .see-section-alignment .first-card {
        padding: 15px;
    }

    .sec-card {
        padding: 15px;
        min-height: auto;
    }

    .new-text p {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .get-started-button-center-alignment button {
        padding: 14px;
        letter-spacing: 0.02em;
        width: 100%;
        font-size: 14.6033px;
        line-height: 22px;
    }

    .slider-card-details {
        padding: 15px;
    }

    .see-section-alignment .page-title {
        padding: 0 0 50px 0;
    }
    .see-section-alignment .page-title2 {
        padding: 0 0 50px 0;
    }

    .see-section-alignment .page-title p::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }
    .see-section-alignment .page-title2 p::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .classess-page-title {
        padding: 0 0 30px 0;
    }

    .classess-page-title p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        letter-spacing: 0.02em;

    }

    .classess-page-title h1 {
        font-size: 26px;
        line-height: 39px;
        text-align: center;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;
    }

    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.01em;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style p {
        font-weight: 500;
        letter-spacing: 0.02em;
        font-size: 16px;
        line-height: 24px;
    }

    .score-box .score-grid {
        grid-template-columns: 40px 1fr 70px;
        gap: 10px;
    }

    .score-box .score-grid .score-grid-items .rounded-icon {
        width: 40px;
        height: 40px;
        background-color: #fff;
    }

    .score-box .score-grid .score-grid-items .rounded-icon img {
        max-width: 20px;
    }

    .score-box .score-grid .score-grid-items p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 4px 0;
    }

    .management-aptitide-section-alignemnt .score-box {
        width: 100%;
        padding: 10px;
        border-radius: 15px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .management-aptitide-section-alignemnt .common-grid {
        gap: 0;
    }

    .management-aptitide-section-alignemnt {
        padding: 40px 0 0 0;
    }

    .management-aptitide-section-alignemnt .common-grid {
        padding: 0;
    }

    .all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(2) {
        order: 2;
    }

    .all-common-grid-alignment .common-grid:nth-child(2n+ 2) .common-grid-items:nth-child(1) {
        order: 1;
    }

    .take-section-banner .take-box {
        padding: 30px 15px;
    }

    .take-section-banner .take-box .heigh-alignment {
        grid-template-columns: 1fr 91px;
    }

    .three-month-section .three-month-grid-items:nth-child(1) {
        padding: 15px;
        border-radius: 20px;
    }

    .three-month-section .three-month-grid-items h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        margin: 0 0 5px 0;
    }

    .our-promise-section {
        padding: 40px 0;
    }

    .our-promise-section .page-title h2 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #352958;
    }
    .our-promise-section .page-title2 h2 {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #352958;
    }

    .three-month-section .three-month-grid-items .all-text-alignment h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        letter-spacing: 0.02em;
    }

    .three-month-section .three-month-grid-items .all-text-alignment div {
        padding: 0 0 30px 0;
    }

    .three-month-section .three-month-grid-items .all-text-alignment h6 {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 24px;
    }

    .three-month-section .three-month-grid {
        gap: 30px;
    }

    .three-month-section .three-month-grid-items p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    .take-section-banner .take-box .button-style button {
        height: 42px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        padding: 10px;
    }

    .take-section-banner .take-box .heigh-input input {
        height: 42px;
        font-weight: 700;
        font-size: 12px;
        padding-left: 16px;
        text-align: center;
        line-height: 18px;
    }

    .take-section-banner .take-box .heigh-alignment {
        padding: 0;
    }

    .take-section-banner .take-box .image-center-alignment img {
        max-width: 250px;
        height: 190px;
    }

    .take-section-banner {
        padding: 80px 23px;
        height: auto;
    }

    .take-section-banner .container-md {
        padding: 0 !important;
    }

    .text-box-button-alignment p {
        font-weight: 500;
        font-size: 14px;
        text-align: justify;
        margin: 0 0 20px 0;
        line-height: 26px;
    }

    .take-section-banner .take-box {
        border-radius: 30px;

    }

    .three-month-section .page-title h3 {
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 26px;
        line-height: 32px;
    }
    .three-month-section .page-title2 h3 {
        font-weight: 700;
        letter-spacing: -0.01em;
        font-size: 26px;
        line-height: 32px;
    }

    .three-month-section .page-title {
        padding: 0 0 40px 0;
    }
    .three-month-section .page-title2 {
        padding: 0 0 40px 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p {
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        margin: 0 0 5px 0;
    }

    .lorem-text-style-section .lorem-grid {
        gap: 20px;
    }

    .lorem-text-style-section .pay-now-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;


    }

    .lorem-text-style-section .pay-now-button div {
        width: 90%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    .lorem-text-style-section .pay-now-button button {
        padding: 10px 24px;
        border-radius: 6px;
        letter-spacing: 0.02em;
        width: 100%;
        font-size: 14.6033px;
        line-height: 22px;

    }

    .lorem-text-style-section .lorem-grid {
        padding: 0 0 40px 0;
    }

    .lorem-text-style-section .all-true-icon-text-alignment .icon-text-grid .icon-text-grid-items img {
        max-width: 11px;
    }

    .lorem-text-style-section .icon-text-grid p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
    }

    .lorem-text-style-section .icon-text-grid {
        grid-template-columns: 20px 1fr;
    }

    .lorem-text-style-section .child-box h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
    }

    .lorem-text-style-section .child-box {
        padding: 15px 0 0 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items h4 {
        font-weight: 700;
        margin: 0 0 10px 0;
        font-size: 18px;
        line-height: 30px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .box-header-style {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section .lorem-header div span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    .three-month-section .page-title h3::before,
    .our-promise-section .page-title h2::before,
    .career-section-alignment .page-title p::before,
    .lorem-text-style-section .page-title h1::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .three-month-section .page-title2 h3::before,
    .our-promise-section .page-title2 h2::before,
    .career-section-alignment .page-title2 p::before,
    .lorem-text-style-section .page-title2 h1::before {
        bottom: -10px;
        width: 80px;
        height: 2px;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items {
        padding: 15px;
    }

    .lorem-text-style-section .page-title {
        padding: 0 0 50px 0;
    }

    .lorem-text-style-section .page-title2 {
        padding: 0 0 50px 0;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style p {
        font-weight: 500;
        margin: 0 0 10px 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
    }

    .question-box-header .taj-icon img {
        max-width: 15px;
    }

    .two-col-grid {
        padding: 0 0 10px 0;
    }

    .new-col-grid {
        gap: 24px;
    }

    .new-col-grid-items:nth-child(1) {
        order: 2;
    }

    .new-col-grid {
        padding: 0 0 10px 0;
    }

    .lorem-text-style-section {
        padding: 40px 0;
    }

    .new-col-grid-items:nth-child(2) {
        order: 1;
    }

    .lorem-text-style-section .page-title h1 {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.01em;
        line-height: 33px;
    }

    .lorem-text-style-section .page-title2 h1 {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.01em;
        line-height: 33px;
    }

    .new-col-grid .new-dummy-text-style {
        padding: 0 0 20px 0;
    }

    .new-col-grid .new-dummy-text-style span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .new-col-grid .new-dummy-text-style p {
        font-weight: 500;
        letter-spacing: 0.02em;
        margin: 0 0 10px 0;
        font-size: 14px;
        line-height: 21px;
    }

    .new-col-grid .new-col-grid-items .new-image-style img {
        height: 275px;
    }

    .new-col-grid .new-col-grid-items .new-image-style {
        display: flex;
        justify-content: center;
    }

    .question-box-header-items h6 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .text-style p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .question-box-header {
        padding: 10px;
    }

    .text-style {
        padding: 0 10px 0 10px;
    }

    .question-box-header-items .action-button {
        width: 20px;
        height: 20px;
        font-size: 15px;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style {
        padding: 0 0 20px 0;
    }

    .question-box-header {
        grid-template-columns: 20px 1fr auto;
        align-items: inherit;
    }

    .question-box-header-items .action-button-blue {
        width: 20px;
        height: 20px;
    }

    .classess-page-title p,
    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
        text-align: justify !important;
    }

    .two-col-grid .two-col-grid-items .new-dummy-text-style span {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .three-month-section {
        padding: 40px 0;
    }

    .career-section-alignment .page-title {
        padding: 0 0 43px 0;
    }

    .career-section-alignment .page-title2 {
        padding: 0 0 43px 0;
    }

    .two-col-grid {
        gap: 23px;
    }

    .our-promise-section .profile-image img {
        width: 100%;
        height: 312px;
    }

    .our-promise-section .profile-details {
        padding: 20px 0 0 0;
    }

    .our-promise-section .profile-details p {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        margin: 0 0 10px 0;
    }

    .our-promise-section .our-grid {
        gap: 30px;
    }

    .career-section-alignment {
        padding: 40px 0 0 0;
    }

    .career-section-alignment .page-title p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }
    .career-section-alignment .page-title2 p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
    }

    .career-section-alignment .page-title h4 {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;

    }

    .career-section-alignment .page-title2 h4 {
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 10px 0;
        letter-spacing: -0.01em;

    }

    .our-promise-section .child-text-style p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 20px 0;
    }

    .our-promise-section .profile-details span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .our-promise-section .page-title {
        padding: 0 0 50px 0;
    }

    .our-promise-section .page-title2 {
        padding: 0 0 50px 0;
    }

    .text-box-button-alignment {
        padding: 20px 0 0 0;
    }

    .text-box-button-alignment div button {
        padding: 10px;
        width: 100%;
        height: 52px;
        font-weight: 700;
        font-size: 14.6033px;
        line-height: 22px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .list-type-text ul li {
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        letter-spacing: 0.02em;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style {
        padding: 0;
    }

    .score-box .score-grid .score-grid-items h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .image-center-alignment img {
        max-width: 100%;
    }

    .management-aptitide-section-alignemnt .common-grid .common-grid-items .child-text-style p {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin: 10px 0 0 0;
        letter-spacing: 0.02em;
    }

    .management-aptitide-section-alignemnt .score-box {
        margin: 0 0 20px 0;
    }

    .score-box .score-grid .score-grid-items .score-range {
        height: 10px;
    }

    .score-box .score-grid .score-grid-items .score-range .score-range-active {
        height: 6px;
    }

    .report-hero-section-alignment .report-hero-grid {
        gap: 10px;
        padding: 20px 0 0 0;
    }

    .management-aptitide-section-alignemnt {
        padding: 40px 0;
    }

    .report-hero-section-alignment .report-hero-grid .report-hero-grid-items p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;

    }

    .report-hero-section-alignment .image-center-alignment {
        padding: 0 0 20px 0;
    }

    .take-section-banner .take-box .heigh-input ::placeholder {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
    }

    .container-md-2,
    .container-md {
        max-width: 100%;
        padding: 0 24px;
    }

    .take-section-banner .take-box .button-style p {
        height: 42px;
    }

    .three-month-section .three-month-grid-items:nth-child(1) img {
        max-width: 100%;
    }

    .slider-card-details p {
        min-height: 10px;
    }

    .lorem-text-style-section .lorem-grid .lorem-grid-items .sec-text-style p span {
        font-size: 13px;
    }
}

.apexcharts-tooltip {
    background: #f3f3f3;
    color: orange;
}

/* styles */



