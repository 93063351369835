.about_us_main_section {
}
.res_about_us_container {
}
.dont_worry_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.dont_worry_content_container {
}
.dont_worry_title_text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
}
.dont_worry_under_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 24px;
  margin: 0px 0 10px 0;
}
.dont_worry_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  font-family: Roboto;
}
.lock_img_btn {
  cursor: pointer;
  border: 0;
  font-weight: 600;
  outline: none;
  font-family: Poppins;
  color: #fff;
  height: 40px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    92.13deg,
    #1273b7 -10.42%,
    #3194e7 51.89%,
    #1b89e5 114.19%
  );
  border-radius: 4px;
}
.lock_img_btn:hover {
  background: #3194e7;
}

.lock_img {
  margin: 0 10px;
  width: 5%;
}
.img_dont_worry_self {
}
.rocket_img {
}

@media (max-width: 767px) {
  .about_us_main_section {
    max-width: 450px;
    margin: auto;
  }
  .res_about_us_container {
    max-width: 850px;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin: 9% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 90%;
  }
  .dont_worry_title_text {
    line-height: 22px;
    letter-spacing: -0.01em;
    font-size: 18px;
  }
  .dont_worry_under_line {
    border-bottom: 2px solid rgba(239, 207, 27, 1);
    width: 50px;
    margin: 3% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
  .lock_img_btn {
    font-size: 14px;
    border: 0;
    outline: none;
    margin: 4% 0 4% 0;

    height: 40px;
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
  }
  .lock_img {
    margin: 0 10px;
    width: 4%;
  }
  .img_dont_worry_self {
  }
  .rocket_img {
    width: 0;
  }
}

@media (min-width: 768px) {
  .about_us_main_section {
  }
  .res_about_us_container {
    max-width: 768px;
    margin: auto;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 84%;
    flex-wrap: wrap;
    margin: 10% auto 0% auto;
  }
  .dont_worry_content_container {
    width: 59%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 28px;

    font-size: 20px;
  }
  .dont_worry_under_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 50px;
    margin: 1% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }
  .lock_img_btn {
    margin: 4% 0 4% 0;
    height: 35px;
    font-size: 13px;
    width: 284px;

    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-bottom: -50px;
  }
}

@media (min-width: 991px) {
  .about_us_main_section {
  }
  .res_about_us_container {
    max-width: 1024px;
    margin: auto;
  }
  .dont_worry_container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 85%;
    flex-wrap: wrap;
    margin: 7% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 32px;
    font-size: 24px;
  }
  .dont_worry_under_line {
    border-bottom: 4px solid rgba(239, 207, 27, 1);
    width: 80px;
    margin: 1% 0 10px 0;
  }
  .dont_worry_extra_content {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
  .lock_img_btn {
    margin: 3% 0 3% 0;
    height: 39px;
    font-size: 15px;
    width: 313px;
    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-top: -50px;
  }
}

@media (min-width: 1200px) {
  .about_us_main_section {
    margin: 168px 0 0 0;
  }

  .dont_worry_container {
    width: 86%;

    margin: 7% auto 5% auto;
  }
  .dont_worry_content_container {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    line-height: 32px;
    font-size: 24px;
  }
  .dont_worry_under_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 77px;

    margin: 4px 0 0px 0;
  }
  .dont_worry_extra_content {
    font-size: 19px;
    line-height: 24px;
    margin: 50px 0 23px 0;
  }
  .lock_img_btn {
    margin: 3% 0 3% 0;
    height: 45px;
    font-size: 17px;
    width: 347px;
    border-radius: 4px;
  }
  .lock_img {
    margin: 0 8px;
    width: 5%;
  }
  .img_dont_worry_self {
    width: 35%;
    margin: 0 0 0 auto;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-bottom: 10px;
  }
}

@media (min-width: 1390px) {
  .about_us_main_section {
    margin: 168px 0 0 0;
  }

  .dont_worry_container {
    width: 79%;
    margin: 120px 142px 120px 142px;
  }
  .dont_worry_content_container {
    width: 58%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .dont_worry_title_text {
    font-size: 32px;
    line-height: 60px;
  }
  .dont_worry_under_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 102px;
    margin: 4px 0 0px 0;
  }
  .dont_worry_extra_content {
    margin: 60px 0 68px 0;
    font-size: 20px;
    line-height: 30px;
  }
  .lock_img_btn {
    margin: 0px 0 0% 0;
    height: 43px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    width: 373px;
    padding: 5px 5px;
    border-radius: 4px;
    font-family: Poppins;
  }
  .lock_img {
    margin: 0 8px;
    width: 12px;
    height: 15px;
  }
  .img_dont_worry_self {
    margin: 0 0 0 60px;
    width: 412px;
    height: 411px;
  }
  .rocket_img {
    width: 100%;
  }

  .down-scroller {
    margin-top: -100px;
  }
}