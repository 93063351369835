@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
.main_quiz_container {
  background: rgb(78, 182, 254);
  background: -moz-linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 0%,
    rgba(28, 139, 217, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 0%,
    rgba(28, 139, 217, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 0%,
    rgba(28, 139, 217, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4eb6fe",endColorstr="#1c8bd9",GradientType=1);
}
.white_center_container {
  position: relative;
  margin: 0% auto 0% auto;
  padding: 8% 0 8% 0;
  /* left: 50%;
    transform: translate(-50%, 0%); */
  width: 75%;
}
.white_container {
  background: #fff;
  padding: 1%;
  border-radius: 20px;
  /* margin: 10% 0 10% 0; */
}
.intro_orange_btn_option {
  font-size: 19px;
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 40%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  background: rgb(18, 115, 183);
  border-radius: 20px;
  margin: 3% auto 0 auto;
}
/* .intro_orange_btn_option:hover {
  background: #ff9900cc;
  color: rgb(0, 0, 0);
} */
.circular_timer_container {
  display: flex;
  justify-content: center;
}
.circulat_timer_content {
  width: 100px;
  height: 100px;
  background: lightyellow;
  margin-top: -5%;
}

.quiz_blue_container {
  background-color: #eef8ff;
  margin: 5% 3% 3% 3%;
  padding: 0 3% 3% 3%;
  position: relative;
  border-radius: 20px;
}
.question_head_timer_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  top: -23px;
}
.question_head_container {
  background-color: #091b28;
  display: flex;
  color: #fff;
  font-size: 20px;
  font-family: "POPPINS";
  align-items: center;

  justify-content: center;
  border-radius: 30px;
  height: 45px;
}
/* .question_timer_container {
  background-color: #ff4c4c;
  display: flex;
  align-items: center;
  width: 12%;
  justify-content: center;
  border-radius: 30px;
  height: 45px;
  margin: 0 0 0 2%;
} */
.out_of_timer_text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.quiz_title_text {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  margin: 0 0 0 3%;
}
.yellow_quiz_text {
  color: #ffd600;
  font-size: 22px;
  margin: 0 3px 0 8px;
  font-family: Poppins;
  font-weight: 600;
}
.out_of_text_quiz {
  opacity: 0.7;
  color: #fff;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
}
/* .out_of_title_text {
  color: #fff;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 0 10%;
} */
.question_title_quiz {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 200;
}
.option_container_main_quiz {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  flex-direction: row;
}
.option_coponent_container_quiz {
  display: flex;
  flex-direction: row;
  margin: 2% 0 0 0;
  width: 50%;
  border: 0;
  outline: none;
  background-color: transparent;
  justify-content: center;
  cursor: pointer;
}
.option_blue_part_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d8efff;
  width: 90%;

  border-radius: 10px;
  min-height: 45px;
}

.option_head_text_quiz {
  font-size: 20px;
  color: #091b28;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0 0 0 3%;
}

.option_white_part_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  width: 90%;
  margin: 0 0 0 15px;
  min-height: 45px;
  border-radius: 10px;
  min-height: 45px;
  text-align: left;
}
.option_ans_text_quiz {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;

  color: #333;
  text-align: left;
  min-height: 45px;
  font-size: 14px;
  margin: 0 0 0 5%;
  display: flex;
  align-items: center;
}
.next_btn_container {
  display: flex;
  justify-content: space-evenly;
  margin: 5% 0 0 0;
}

.same_btn_quiz {
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 48%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
}
.prev_btn_quiz {
  background: #091b28;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.next_btn_quiz {
  background: #1273b7;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
/* .prev_btn_quiz:hover {
  background: #202020;
}
.next_btn_quiz:hover {
  background: rgb(46, 134, 197);
} */

/* after click  */

.option_blue_part_container_color {
  background: #27cb7c;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  border-radius: 10px;
  min-height: 45px;
}
.option_head_text_quiz_color {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0 0 0 3%;
  color: #fff;
}
.option_white_part_container_color {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d9ffed;
  width: 90%;
  margin-left: auto;
  min-height: 45px;
  border-radius: 10px;
}
.timer_content {
  top: 15%;
  right: 10%;
  /* height: 20px; */
  width: 60%;
  margin: 0% auto 0 auto;
  position: absolute;
  z-index: 1;
}
/* .prev_btn_quiz:hover {
  background: #202020;
  color: #fdaf39;
}
.next_btn_quiz:hover {
  background: rgb(46, 134, 197);
  color: #202020;
} */
.orange_label_container {
  background-color: #fff6e8;
  margin: 5% 3% 3% 3%;
  padding: 0 3% 3% 3%;
  border-radius: 20px;
  position: relative;
}
.orange_label_next_btn {
  font-size: 19px;
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;
  justify-content: center;
  width: 40%;
  outline: none;
  border: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: "Poppins";
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  background: #ff9b00;
  border-radius: 20px;
  margin: 3% auto 0 auto;
}
.orange_label_prev_btn_quiz {
  background: #091b28;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.orange_label_next_btn_quiz {
  background: #ff9b00;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

@media screen and (max-width: 767px) {
  .orange_label_container {
    margin: 50px 3% 3% 3%;
    padding: 0 3% 0% 3%;

    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .orange_label_next_btn {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;

    cursor: pointer;

    margin: 3% auto 0 auto;
  }
  .orange_label_prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .orange_label_next_btn_quiz {
    background: #ff9b00;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .timer_content {
    top: 75px;
    left: 58%;
    /* height: 20px; */
    width: 100px;
    margin: 0;
    position: absolute;
    z-index: 1;
    /* background-color: red; */
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 21% 0 40% 0;
    width: 90%;
  }
  .intro_orange_btn_option {
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;

    width: 100%;

    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    background: rgb(18, 115, 183);
    border-radius: 20px;
    margin: 3% auto 0 auto;
  }

  .white_container {
    background: #fff;
    padding: 1%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    margin-top: -19%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 50px 3% 10px 3%;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_head_timer_container {
    top: -15px;
    left: -45px;
  }
  .question_head_container {
    min-width: 125px;
    width: 125px;
    border-radius: 26px;
    height: 33px;
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
  }
  /* .question_timer_container {
    width: 45%;

    border-radius: 26px;
    height: 33px;
    margin: 0 0 0 3%;
  } */
  /* .timer_img {
    width: 16%;
  } */
  .out_of_timer_text {
    font-size: 18px;
  }
  .quiz_title_text {
    margin: 30px 0 14% 4%;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #091b28;
  }
  .yellow_quiz_text {
    margin: 0 2px 0 6px;
    line-height: 10px;
    font-weight: 700;
    font-size: 15px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 10px;
  }
  /* .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    padding: 15px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    color: #091b28;
    line-height: 17px;
    text-align: justify;
  }
  .option_container_main_quiz {
    flex-direction: column;
    margin: 8% 0 6% 0;
  }
  .option_coponent_container_quiz {
    margin: 2% 0 2% 0;
    width: 100%;
  }
  .option_blue_part_container {
    width: 100%;
  }

  .option_head_text_quiz {
    font-size: 16px;
    margin: 0 0 0 5%;
  }

  .option_white_part_container {
    width: 90%;
  }
  .option_ans_text_quiz {
    font-size: 14px;
    padding: 2% 0;
    margin: 0 0 0 5%;
  }
  .next_btn_container {
    display: flex;
    position: relative;
    margin: 5% 0 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 15px auto 10px auto;
    width: 100%;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    width: 48%;
  }
  .prev_btn_quiz {
  }
  .next_btn_quiz {
  }

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;

    width: 100%;
  }
  .option_head_text_quiz_color {
    font-size: 16px;

    margin: 0 0 0 5%;

    color: #fff;
  }
  .option_white_part_container_color {
    width: 86%;
  }
}

@media screen and (min-width: 768px) {
  .orange_label_container {
    margin: 60px 3% 3% 3%;
    padding: 0 3% 15px 3%;

    position: relative;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .orange_label_next_btn {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;

    cursor: pointer;

    margin: 3% auto 0 auto;
  }
  .orange_label_prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .orange_label_next_btn_quiz {
    background: #ff9b00;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .timer_content {
    top: 82px;
    right: -11%;
    /* height: 20px; */
    width: 60%;
    margin: 0% auto 0 auto;
    position: absolute;
    z-index: 1;
  }
  .white_center_container {
    width: 55%;
    padding: 11% 0 8% 0;
  }
  .intro_orange_btn_option {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 100%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 3% auto 0 auto;
  }

  .white_container {
    background: #fff;
    padding: 1%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    margin-top: -14%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 60px 3% 15px 3%;
    padding: 0 3% 15px 3%;
    border-radius: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .question_head_timer_container {
    top: -18px;
    left: -8px;
  }
  .question_head_container {
    color: #fff;
    font-size: 14px;
    width: 125px;
    border-radius: 26px;
    height: 34px;
    position: relative;
    left: -63px;
  }

  .out_of_timer_text {
    font-size: 18px;
  }
  .quiz_title_text {
    font-size: 22px;
    margin: 4% 0 8% 4%;
  }
  .yellow_quiz_text {
    font-size: 15px;
    margin: 0 3px 0 8px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 10px;
  }
  /* .out_of_title_text {
    font-size: 14px;

    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #091b28;
    line-height: 24px;
  }
  .option_container_main_quiz {
    margin: 2% 0 0% 0;

    flex-direction: column;
  }
  .option_coponent_container_quiz {
    margin: 2% 0 2% 0;
    width: 100%;
  }
  .option_blue_part_container {
    width: 100%;
  }

  .option_head_text_quiz {
    font-size: 19px;
    margin: 0 0 0 4%;
  }

  .option_white_part_container {
    width: 89%;
  }
  .option_ans_text_quiz {
    font-size: 16px;
    margin: 0 0 0 3%;
    padding: 2% 0;
  }
  .next_btn_container {
    margin: 5% 0 0 0;
    position: relative;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;
    border: 0;
    text-transform: uppercase;

    font-family: "Poppins";

    letter-spacing: 1px;
    cursor: pointer;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
  }
  .prev_btn_quiz {
  }
  .next_btn_quiz {
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;

    width: 100%;
  }
  .option_head_text_quiz_color {
    font-size: 16px;

    margin: 0 0 0 4%;

    color: #fff;
  }
  .option_white_part_container_color {
    width: 89%;
  }
}

@media screen and (min-width: 991px) {
  .orange_label_container {
    margin: 80px 3% 3% 3%;
    padding: 0 3% 2% 3%;

    min-height: 500px;
  }
  .orange_label_next_btn {
    font-size: 19px;

    height: 50px;

    width: 50%;
  }
  .orange_label_prev_btn_quiz {
  }
  .orange_label_next_btn_quiz {
  }
  .timer_content {
    top: 97px;
    right: -12%;
  }
  .intro_orange_btn_option {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 10px auto 0 auto;
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 8% 0 8% 0;
    /* left: 50%;
      transform: translate(-50%, 0%); */
    width: 75%;
  }
  .intro_orange_btn {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: center;
    width: 40%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
    background: #ff9b00;
    border-radius: 20px;
    margin: 3% auto 0 auto;
  }
  .white_container {
    background: #fff;
    padding: 0%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    width: 100px;
    height: 100px;
    background: lightyellow;
    margin-top: -7%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 80px 3% 3% 3%;

    padding: 0 3% 2% 3%;

    min-height: 500px;
    border-radius: 20px;
  }
  .question_head_timer_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;

    top: -23px;
    left: -21px;
  }
  .question_head_container {
    background-color: #091b28;
    display: flex;
    color: #fff;
    font-size: 20px;
    font-family: "POPPINS";
    align-items: center;
    width: 160px;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
  }
  /* .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 18%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  } */
  .out_of_timer_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .quiz_title_text {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0 0 0 3%;
  }
  .yellow_quiz_text {
    color: #ffd600;
    font-size: 22px;
    margin: 0 3px 0 8px;
    font-family: Poppins;
    font-weight: 600;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
  }
  /* .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-size: 17px;
    font-family: "Poppins";
    font-weight: 400;
    line-height: 22px;
  }
  .option_container_main_quiz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 2% 0 5% 0;
    flex-direction: row;
  }
  .option_coponent_container_quiz {
    display: flex;
    flex-direction: row;
    margin: 1% 0 0 0;
    width: 50%;
    padding: 1% 0px;
    border: 0;
    outline: none;
    background-color: transparent;
    justify-content: center;
    cursor: pointer;
  }
  .option_blue_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d8efff;
    width: 94%;
    border-radius: 10px;
    min-height: 50px;
  }

  .option_head_text_quiz {
    font-size: 20px;

    margin: 0 0 0 6%;
  }

  .option_white_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    width: 82%;
    margin-left: auto;
    min-height: 50px;
    border-radius: 10px;
  }
  .option_ans_text_quiz {
    font-size: 17px;
    font-family: "Poppins";
    font-weight: 500;
    margin: 0 0 0 5%;
    color: #333;
    overflow: auto;
  }
  .next_btn_container {
    display: flex;
    justify-content: space-evenly;
    margin: 5% 0 0 0;
  }

  .same_btn_quiz {
    width: 48%;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 0;
    color: #fff;
    font-family: "Poppins";
    cursor: pointer;

    padding: 0%;

    text-transform: uppercase;
    letter-spacing: 1px;

    display: block;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    height: 50px;
  }
  .prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .next_btn_quiz {
    background: #1273b7;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 94%;
    border-radius: 10px;
    min-height: 50px;
  }
  .option_head_text_quiz_color {
    font-size: 20px;

    margin: 0 0 0 6%;
  }
  .option_white_part_container_color {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d9ffed;
    width: 82%;
    margin-left: auto;
    min-height: 50px;
    border-radius: 10px;
    overflow: auto;
  }
}

@media screen and (min-width: 1200px) {
  .orange_label_container {
    margin: 8% 3% 3% 3%;
    padding: 0 3% 2% 3%;

    min-height: 600px;
  }
  .orange_label_next_btn {
    font-size: 22px;

    height: 60px;

    width: 50%;
  }
  .orange_label_prev_btn_quiz {
  }
  .orange_label_next_btn_quiz {
  }
  .timer_content {
    top: 82px;
    right: -12%;
  }
  .white_center_container {
    position: relative;
    margin: 0% auto 0% auto;
    padding: 8% 0 8% 0;
    /* left: 50%;
      transform: translate(-50%, 0%); */
    width: 75%;
  }
  .white_container {
    background: #fff;
    padding: 0%;
    border-radius: 20px;
    /* margin: 10% 0 10% 0; */
  }
  .circular_timer_container {
    display: flex;
    justify-content: center;
  }
  .circulat_timer_content {
    width: 100px;
    height: 100px;
    background: lightyellow;
    margin-top: -7%;
  }

  .quiz_blue_container {
    background-color: #eef8ff;
    margin: 8% 3% 3% 3%;
    padding: 0 3% 2% 3%;
    border-radius: 20px;
    min-height: 600px;
  }
  .question_head_timer_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;

    top: -24px;
    left: -25px;
  }
  .question_head_container {
    background-color: #091b28;
    display: flex;
    color: #fff;
    font-size: 21px;
    font-family: "POPPINS";
    align-items: center;
    width: 170px;
    justify-content: center;
    border-radius: 30px;
    height: 50px;
  }
  /* .question_timer_container {
    background-color: #ff4c4c;
    display: flex;
    align-items: center;
    width: 14%;
    justify-content: center;
    border-radius: 30px;
    height: 45px;
    margin: 0 0 0 2%;
  } */
  .out_of_timer_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .quiz_title_text {
    font-size: 29px;
    font-weight: 600;
  }
  .yellow_quiz_text {
    font-size: 22px;
    margin: 0 3px 0 8px;
  }
  .out_of_text_quiz {
    opacity: 0.7;
    color: #fff;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
  }
  /* .out_of_title_text {
    color: #fff;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0 0 10%;
  } */
  .question_title_quiz {
    font-size: 22px;
    line-height: 27px;
  }
  .option_container_main_quiz {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0% 0 0% 0;
    flex-direction: row;
  }
  .option_coponent_container_quiz {
    display: flex;
    flex-direction: row;
    margin: 5px 0 0 0;
    width: 50%;
    border: 0;
    outline: none;
    background-color: transparent;
    justify-content: center;
    cursor: pointer;
  }
  .option_blue_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d8efff;
    width: 94%;

    border-radius: 10px;
    min-height: 60px;
  }

  .option_head_text_quiz {
    font-size: 22px;

    margin: 0 0 0 5%;
  }

  .option_white_part_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    width: 85%;
    margin-left: auto;
    min-height: 60px;

    border-radius: 10px;
  }
  .option_ans_text_quiz {
    font-size: 22px;
    font-family: "Poppins";
    font-weight: 500;
    margin: 0 0 0 3%;
    color: #333;
  }
  .next_btn_container {
    display: flex;
    justify-content: space-evenly;
    margin: 2% 0 0 0;
  }

  .same_btn_quiz {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    width: 48%;
    outline: none;

    color: #fff;
    font-family: "Poppins";
    font-weight: 700;

    cursor: pointer;
    border: 0;

    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 5% auto 0% auto;
    height: 60px;
    font-size: 22px;
    line-height: 32px;
  }
  .prev_btn_quiz {
    background: #091b28;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .next_btn_quiz {
    background: #1273b7;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  /* .prev_btn_quiz:hover {
    background: #202020;
  }
  .next_btn_quiz:hover {
    background: rgb(46, 134, 197);
  } */

  /* after click  */

  .option_blue_part_container_color {
    background: #27cb7c;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 94%;
    border-radius: 10px;
    min-height: 60px;
  }
  .option_head_text_quiz_color {
    font-size: 24px;
    margin: 0 0 0 5%;
  }
  .option_white_part_container_color {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d9ffed;
    width: 85%;
    margin-left: auto;
    min-height: 60px;
    border-radius: 10px;
  }
  .intro_orange_btn_option {
    font-size: 22px;
    display: flex;
    flex-direction: row;
    height: 60px;
    align-items: center;
    justify-content: center;
    width: 50%;
    outline: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;

    border-radius: 20px;
    margin: 19px auto 0 auto;
  }
}
