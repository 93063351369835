.student-block {
  background: rgba(26, 100, 214, 0.08);
  /*background: rgba(71, 33, 95, 1);*/
  color: #000;
  padding: 30px;
  padding-bottom: 60px;
}
.student-block .student-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.student-info{
  margin-bottom: 20px;
}

.student-block h3 {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 0;

  /* identical to box height */

  color: #000;

  opacity: 0.5;
}
.student-block p {
  margin: 0;
}
.student-block .name {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: #333333;
}
.student-block .email {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  /* identical to box height */
  letter-spacing: 0.01em;

  color:#333333;
}
.student-block .card {
  background: linear-gradient(180deg, #FFFFFF 0%, #DAE3F1 100%);
  /*width: 22.5%;*/
  min-height: 250px;
  border-radius: 22px;
  margin: 0;
  display: flex;
  align-items: center;
  border: none;
}
.student-block .card:hover{
  background: linear-gradient(180deg, #1A64D6 0%, #1A64D6 100%);
}
.student-block .card.aptitude {
  background: #1A64D6;
}
.student-block .card .card-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.student-block .card .card-body .head {
  font-family: "Gordita", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #BDBDBD;
  opacity: 0.6;
}
.student-block .card .card-body .value {
  font-family: "Gordita", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.01em;
  color: #333333;
}
.student-block .card .card-body .icon{
  font-size: 30px;
  margin-top: 10px;
}
.student-block .card .card-body .icon img{
  width: 30px;
  height: 30px;
}
.student-block .card.aptitude .card-body .head {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
}
.student-block .card.aptitude .card-body .value {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
}
.student-block .card.aptitude .card-body .icon {
  color: #47215f;
  font-size: 30px;
  margin-top: 10px;
}
.report-block {
  position: relative;
  background: #fbfafe;
}
.report-block .card {
  position: relative;
  top: -30px;
  background: #fff;
  border: none;
  border-radius: 20px;
}

.report-block h3 {
  font-family: "Gordita", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}
.report-block .table-responsive {
  margin-top: 30px;
  padding: 0 30px;
}
.report-block td {
  vertical-align: baseline;
}
.report-block .subject {
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  color: #18161f;
}
.report-block .score {
  width: 50%;
}
.report-block .score .progress {
  background: #f9f9fc;
  border: none;
  border-radius: 500px;
  height: 20px;
}
.report-block .score .progress-bar {
  /*background: linear-gradient(180deg, #00b8ff 0%, #57d0ff 47.92%, #89deff 100%);*/
  border-radius: 500px;
}
.report-block .remarks .badge {
  position: relative;
  top: -5px;
  font-family: "Gordita", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;

  /*background-color: #f5e8f7;*/
  border-radius: 20px;
  padding: 5px 10px;
}

.card-body h3{
  margin-top: 30px !important;
  font-weight: 800;
}

@media (max-width: 768px) {
  .student-block .card {
    margin-bottom: 10px;
    min-height: 160px;
  }
  .student-block .card .card-body .head {
    font-family: "Gordita", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #BDBDBD;
    opacity: 0.6;
  }
  .student-block .card .card-body .value {
    font-family: "Gordita", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #333333;
  }
  .student-block .card .card-body .icon {
    font-size: 30px;
    margin-top: 0px;
  }
  .student-block .card .card-body .icon img{
    width: 30px;
    height: 30px;
  }
  .student-block .card.aptitude .card-body .head {
    color: #ffffff;
    font-size: 14px;
  }
  .student-block .card.aptitude .card-body .value {
    color: #ffffff;
    font-size: 30px;
    font-weight: 900;
  }
  .student-block .card.aptitude .card-body .icon {
    color: #47215f;
    font-size: 40px;
    margin-top: 0px;
  }
  .report-block .table-responsive {
    margin-top: 20px;
    padding: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .student-block .card {
    margin-bottom: 10px;
  }
}