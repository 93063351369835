.first_step_container {
    display: flex;
    flex-direction: column;
}

.open_login_form_container {
    position: fixed;
    bottom: 0;
    right: 0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #fff;
    background-color: #fff;
    width: 100%;
    padding: 10px 12px;
    z-index: 10;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.46);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.46);
}

.open_login_form_container>button {
    color: #fff;
    background: #1273b7;
    padding: 1rem;
    font-size: 1.8rem;
    border-radius: 9px;
    width: 20%;
    outline: none;
    border: 0;
    letter-spacing: .5px;
    cursor: pointer;
}

.open_login_form_container>button:hover {
    color: #333;
    background: #0d5b92;
}

.google_icon {
    font-size: 19px;
}



@media (max-width:767px) {
    div[label="Personal info"]::after {
        left: 80%;

    }

    .open_login_form_container>button {
        width: 170px;
    }

}

@media (max-width:300px) {
    .open_login_form_container>button {
        width: 100%;

        font-size: 1.4rem;
    }

}

@media (min-width:1200px) {
    div[label="Personal info"]::after {
        left: 80%;

    }

    .open_login_form_container>button {
        color: #fff;
        background: #1273b7;
        padding: 1rem;
        font-size: 1.8rem;
        border-radius: 9px;
        width: 200px;
    }
}