@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.questions-table {
    margin: 0 auto;
    width: 100% !important;
    line-height: initial;
}

.questions-table tbody,
.questions-table td,
.questions-table tfoot,
.questions-table th,
.questions-table thead,
.questions-table tr {
    border: 1px solid black;
}

img {
    max-width: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


/* This below line of code increases size of error in SignUp form */


/* .text-danger{
  font-size: 25px;
} */

html {
    font-size: 10px !important;
}

body {
    font-family: Roboto, Poppins, Ubuntu, sans-serif;
}

@font-face {
    font-family: "Gordita";
    src: local("GorditaRegular"), url("./fonts/Gordita-Regular.otf") format("truetype");
}

@font-face {
    font-family: "Gordita Bold";
    src: local("GorditaBold"), url("./fonts/Gordita-Bold.otf") format("truetype");
}

@font-face {
    font-family: "Gordita Light";
    src: local("GorditaLight"), url("./fonts/Gordita-Light.otf") format("truetype");
}

@font-face {
    font-family: "Gordita Medium";
    src: local("GorditaMedium"), url("./fonts/Gordita-Medium.otf") format("truetype");
}


/* Hide scrollbar for Chrome, Safari and Opera */

::-webkit-scrollbar {
    width: 10px;
}


/* Hide scrollbar for IE, Edge and Firefox */

body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
    width: 12px;
    border-radius: 3px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #1273b7;
    border-radius: 3px;
}

.is_active {
    font-weight: 600 !important;
    color: #1273B7 !important;
}

.redutech_Is_Active {
    border: 2px solid #122a39 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}