.one_work_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tell_img_left_container {
}
.tell_img_self {
}
.tell_img_right_container {
}
.step_one_text {
  font-family: "Roboto";

  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  margin: 0;
}
.tell_us_sub_text {
  font-family: "Roboto";

  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #1273b7;
  margin: 0;
}
.tell_us_extra_content {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #4f4f4f;
  font-family: Roboto;
}

@media (max-width: 767px) {
  .tell_us_extra_content {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    padding: 2px 6% 8% 6%;
  }
  .one_work_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .step_one_text {
    margin: 5% 0 2% 0;
    font-size: 17px;
    line-height: 22px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 18px;
    margin: 6px 0 3px 0;
    width: 92%;
  }
}

@media (min-width: 768px) {
  .tell_img_self {
    width: 70%;
    /* margin: 0 auto; */
  }
  .tell_img_left_container {
    display: flex;
    justify-content: center;
  }
  .tell_us_extra_content {
    margin: 0;
    width: 92%;
    font-size: 13px;
    line-height: 15px;

    text-align: center;

    padding: 4px 6% 4% 6%;
  }
  .one_work_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .step_one_text {
    margin: 2% 0 0 0;
    font-size: 17px;
    line-height: 21px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 26px;
    margin: 10px 0 0 0;
  }
}

@media (min-width: 991px) {
  .tell_img_self {
    width: 80%;
  }
  .tell_img_left_container {
    width: 30%;
  }
  .tell_us_extra_content {
    margin: 0;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    padding: 4px 0%;
    width: 100%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 2% 0 0 0;
    font-size: 17px;
    line-height: 21px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 21px;
    margin: 3% 0 0 0;
  }
}

@media (min-width: 1200px) {
  .tell_img_self {
    width: 70%;
    z-index: 1;
  }
  .tell_img_left_container {
    width: 30%;
    margin: 0 3% 0 0%;
    display: flex;
    justify-content: flex-end;
  }
  .tell_us_extra_content {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    padding: 4px 0%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 160px;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 0% 0 0 0;
    font-size: 19px;
    line-height: 15px;
    letter-spacing: 0.5px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 20px;

    margin: 3% 0 0 0;
  }
}

@media (min-width: 1390px) {
  .tell_img_self {
    width: 80%;
    z-index: 1;
    max-width: 100%;
  }
  .tell_img_left_container {
    width: 30%;
    margin: 0 3% 0 0%;
    display: flex;
    justify-content: flex-end;
  }
  .tell_us_extra_content {
    margin: 6px 0 0 0;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    padding: 0px 0%;
    width: 100%;
  }
  .one_work_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 184px;
  }
  .tell_img_right_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
  .step_one_text {
    margin: 0% 0 21px 0;
    font-size: 19px;
    line-height: 15px;
    letter-spacing: -0.01em;
    font-size: 22px;
    line-height: 30px;
  }
  .tell_us_sub_text {
    margin: 0% 0 0 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #1273b7;
  }
}
