.work_main_container {
  background: rgba(18, 115, 183, 0.06);
}
.res_work_container {
}
.work_under_section_contianer {
  padding: 4% 0 0 0;
}
.title_main_text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.01em;
  color: #1273b7;
  font-size: 42px;
  margin: 0;
  text-align: center;
}
.work_line {
  border-bottom: 3px solid rgba(239, 207, 27, 1);
  width: 68px;
  margin: 17px auto 10px auto;
}
.work_content_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.work_left_side_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.work_right_side_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btn_center {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.dashed_line_container {
  position: relative;
}
.first_dash {
  width: 100%;
  position: absolute;
}
.sec_dash {
  width: 100%;
  position: absolute;
}
.third_dash {
  width: 100%;
  position: absolute;
}
.four_dash {
  width: 100%;
  position: absolute;
}

@media (max-width: 767px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    margin: auto;
  }
  .third_dash {
    width: 0%;
  }
  .four_dash {
    width: 0%;
  }
  .sec_dash {
    width: 0%;
  }
  .first_dash {
    width: 0%;
    position: absolute;
    left: 32%;
    transform: translate(-50%, -50%);
    top: 37%;
  }
  .work_right_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .work_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work_under_section_contianer {
    padding: 8% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 77px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .third_dash {
    width: 0%;
  }
  .four_dash {
    width: 0%;
  }
  .sec_dash {
    width: 0%;
  }
  .first_dash {
    width: 0%;
    position: absolute;
    left: 32%;
    transform: translate(-50%, -50%);
    top: 37%;
  }
  .work_right_side_container {
    width: 100%;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .work_content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 27px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 991px) {
  .step_one_text {
    margin: 0% 0 0 0;
    font-size: 19px;
    line-height: 22px;
  }
  .tell_us_sub_text {
    font-size: 17px;
    line-height: 21px;
    margin: 6% 0 0 0;
  }
  .work_right_side_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .four_dash {
    width: 44%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 96%;
  }
  .third_dash {
    width: 43%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 49%;
    z-index: -1;
  }

  .sec_dash {
    width: 42%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 100%;
  }
  .first_dash {
    width: 53%;
    position: absolute;
    left: 30%;
    transform: translate(-50%, -50%);
    top: 29%;
  }
  .work_content_container {
    flex-direction: row;
    width: 86%;

    margin: auto;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 39px;
    font-size: 29px;

    margin: 0;
  }
  .work_line {
    border-bottom: 3px solid rgba(239, 207, 27, 1);
    width: 30px;
    margin: 5px auto 30px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 40px 0 80px 0;
  }

  .four_dash {
    width: 47%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 90%;
    z-index: -1;
  }
  .third_dash {
    width: 47%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 53%;
    z-index: -1;
  }
  .sec_dash {
    width: 46%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 100%;
    z-index: -1;
  }
  .first_dash {
    width: 50%;
    position: absolute;
    left: 34%;
    transform: translate(-50%, -50%);
    top: 32%;
    z-index: -1;
  }
  .res_work_container {
    width: 1200px;
    margin: 0 auto;
  }
  .work_content_container {
    flex-direction: row;

    width: 94%;

    margin: auto;
  }
  .work_under_section_contianer {
    padding: 3% 0 0 0;
  }
  .title_main_text {
    line-height: 40px;
    font-size: 36px;
    margin: 0;
  }
  .work_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 60px;
    margin: 11px auto 23px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (min-width: 1390px) {
  .btn_center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 94px 0 0px 0;
    padding: 0 0 80px 0;
  }

  .four_dash {
    width: 48%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 101%;
    z-index: -1;
  }
  .third_dash {
    width: 48%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 57%;
    z-index: -1;
  }
  .sec_dash {
    width: 49.2%;
    position: absolute;
    left: 35%;
    transform: translate(-50%, -50%);
    top: 100%;
    z-index: -1;
  }
  .first_dash {
    width: 50%;
    position: absolute;
    left: 34.5%;
    transform: translate(-50%, -50%);
    top: 28%;
    z-index: -1;
  }
  .res_work_container {
    width: 1440px;
    margin: 0 auto;
  }
  .work_content_container {
    flex-direction: row;
    width: 94%;
    margin: 10px auto;
  }
  .work_under_section_contianer {
    padding: 0% 0 0 0;
    width: 1395px;
    margin: auto;
  }
  .title_main_text {
    padding: 66px 0 0 0;
    font-size: 32px;
    line-height: 60px;
  }
  .work_line {
    border-bottom: 5px solid rgba(239, 207, 27, 1);
    width: 40px;
    margin: 0px auto 100px auto;
  }
  .work_left_side_container {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
