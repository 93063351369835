.strength-desc {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1rem 1rem 1rem 1rem;
  transition: opacity 200ms ease-in;
}
.card-details-container {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.show_desc {
  top: 2%;
  right: 2%;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  border-radius: 10rem;
}
.show_desc:hover ~ .card-details-container .strength-desc {
  opacity: 1;
}
