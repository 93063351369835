@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
.pop_box_sucessfull_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.sucessfull_img_container {
  width: 250px;
}
.sucessfull_img_self {
  width: 100%;
}
.test_completed_text {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}
.text_succes_extra_content {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 300;
  color: #fff;
  line-height: 22px;
  margin: 4% 0 5% 0;
  width: 81%;
}
.sucess_extra_content_highlight {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.ans_extra_conetent_yellow {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #ffd600;
}
.success-btn-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.done_btn {
  border: 0;
  outline: none;
  background: #fff;
  color: #1273b7;
  width: 60%;
  border-radius: 10px;
  height: 40px;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 1% 2% 8% 0;
  cursor: pointer;
}
.profile_navigation_btn {
  border: 0;
  outline: none;
  background:#1273b7;
  color: #fff;
  width: 60%;
  border-radius: 10px;
  height: 40px;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 1% 2% 8% 2%;
  cursor: pointer;
}

.profile_navigation_from_report_page {
  position: absolute;
  right: 4px;
  width: 20%;
  top: 0px;
  z-index: 1;
  outline: none;
  border: none;
  background: #fff;
  color: #1273b7;
  border-radius: 10px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 1% 0 8% 2%;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
}

@media screen and (max-width: 767px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 150px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 1% 0 4% 0;
    width: 95%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 13px;
    letter-spacing: 1px;
    margin: 3% 1% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color: #fff;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 13px;
    letter-spacing: 1px;
    margin: 3% 0 8% 1%;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 150px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 1% 0 4% 0;
    width: 95%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 14.5px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 15px;
    letter-spacing: 1px;
    margin: 3% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color:  #fff;
    width: 60%;
    border-radius: 10px;
    height: 35px;
    font-size: 15px;
    letter-spacing: 1px;
    margin: 3% 0 8% 2%;
    cursor: pointer;
  }
  .profile_navigation_from_report_page {
    width: 10%;
  }
}

@media screen and (min-width: 991px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 250px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 4% 0 5% 0;
    width: 81%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color: #fff;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 0 8% 2%;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .pop_box_sucessfull_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .sucessfull_img_container {
    width: 250px;
  }
  .sucessfull_img_self {
    width: 100%;
  }
  .test_completed_text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: #fff;
  }
  .text_succes_extra_content {
    font-family: Poppins;
    font-size: 19px;
    font-weight: 300;
    color: #fff;
    line-height: 22px;
    margin: 4% 0 5% 0;
    width: 81%;
  }
  .sucess_extra_content_highlight {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
  .ans_extra_conetent_yellow {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    color: #ffd600;
  }
  .done_btn {
    border: 0;
    outline: none;
    background: #fff;
    color: #1273b7;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 2% 8% 0;
    cursor: pointer;
  }
  .profile_navigation_btn {
    border: 0;
    outline: none;
    background:#1273b7;
    color:  #fff;
    width: 60%;
    border-radius: 10px;
    height: 40px;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 1% 0 8% 2%;
    cursor: pointer;
  }
}
