.heading {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  margin-top: 2rem;
  line-height: 36px;
}

.summary-text {
  height: 60%;
  opacity: 0.6;
  color: #000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

.heade_image {
  border-radius: 100%;
}

@media screen and (max-width: 768px) {
  .summary-text {
    opacity: 0.6;
    color: #000;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    height: fit-content;
    margin-bottom: 5px;
  }
}
