.modal_styles {
  transition: all 10s ease-in-out; /*make it smooth*/
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2% 2% 0 2%;
  width: 50%;
  z-index: 1000;
  max-height: 650px;
  overflow-y: auto;
  border-radius: 8px;
  background: rgb(78, 182, 254);
  background: -moz-linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 100%,
    rgba(28, 139, 217, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 100%,
    rgba(28, 139, 217, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(78, 182, 254, 1) 100%,
    rgba(28, 139, 217, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4eb6fe",endColorstr="#1c8bd9",GradientType=1);
}
.overlay_styles {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.close_btn_text_container {
  display: flex;
  justify-content: flex-end;
}
.modal_close_btn_modal {
  border: none;
  background: #01bc82;
  padding: 1%;
  border-radius: 50%;
  text-align: center;
  display: flex;
  outline: none;
  margin: 0 0 2% auto;
}
.fill_the_details_txt {
  font-size: 35px;
  font-family: PSMed;
  color: #01bc82;
  letter-spacing: 2px;
}
.form_container {
  margin: 5% 0 5% 0;
}
.title_input_field_reset_btn_container {
  margin: 1% 0 5% 0;
}
.input_title {
  font-size: 25px;
  font-family: "PSReg";
  letter-spacing: 0.5px;
  text-align: left;
}
.full_name_container {
  background: #f6faff;
  width: 100%;
  padding: 2% 1% 2% 1%;
}
.full_name_input_field {
  border: 0px;
  outline: none;
  width: 98%;
  font-family: "PSREG";
  letter-spacing: 1px;
  font-size: 20px;
  background: #f6faff;
}
.btn_reset_cross {
  border: 0px;
  outline: none;
  background-color: transparent;
}
.btn_reset_cross {
  border: 0px;
  outline: none;
  background-color: transparent;
}
.btn_reset_cross1 {
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 0 2% 0 0;
}
.select_box_container_under {
  outline: none;
  border: 0;
  padding: 18% 3% 18% 3%;
  font-size: 20px;
  background: #f6faff;
}
.title_input_field_reset_btn_container1 {
  margin: 3% 0 5% 0;
}
.contact_number_reset_btn_container1 {
  display: flex;
  width: 100%;
  margin: 0 0% 0 3%;
  background: #f6faff;
  padding: 2% 0 2% 0;
}
.contact_number_input_field {
  border: 0px;
  outline: none;
  width: 100%;
  font-family: "PSREG";
  letter-spacing: 1px;
  font-size: 20px;
  background: #f6faff;
  padding: 0 2% 0 3%;
}
.select_box_number_reset_btn_container {
  display: flex;
  width: 100%;
  padding: 1% 0 1% 0;
  /* border: 1px solid #000; */
}
.select_box_container_under1 {
  width: 100%;
  border: 0;
  outline: none;
  font-size: 21px;
  padding: 3% 0% 3% 0;
  background: #f6faff;
  margin: 2% 0 0 0;
}
.modal_btn_txt_submit {
  background: #1273b7;
  width: 100%;
  padding: 1% 0 1% 0;
  color: #fff;
  font-family: "PSMED";
  letter-spacing: 1px;
  font-size: 27px;
  outline: none;
  border: 0;
  border-radius: 10px;
}
.modal_btn_txt_submit:hover {
  background: #01bc82;
  color: #fff;
}
.close_btn_dialog {
  background: transparent;
  border: 0;
  outline: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md_close_btn_icon1 {
  font-size: 22px;
}
.close_btn_dialog:hover {
  background: rgba(255, 255, 255, 0.39);
  border: 0;
  outline: none;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .modal_styles {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 7% 5% 4% 5%;
    width: 90%;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 4px;
  }

  .overlay_styles {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  .close_btn_text_container {
    display: flex;
  }
  .modal_close_btn_modal {
    border: none;
    background: #01bc82;
    padding: 1%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    outline: none;
    margin: 0 0 2% auto;
    align-items: center;
    flex-direction: row;
    height: 24px;
    width: 23px;
  }
  .fill_the_details_txt {
    font-size: 21px;
    letter-spacing: 1px;
  }
  .form_container {
    margin: 5% 0 5% 0;
  }
  .title_input_field_reset_btn_container {
    margin: 1% 0 5% 0;
  }
  .input_title {
    font-size: 16px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_container {
    background: #f6faff;
    width: 100%;
    padding: 2% 1% 2% 1%;
  }
  .full_name_input_field {
    border: 0px;
    outline: none;
    width: 94%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 14px;
    background: #f6faff;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    font-size: 10px;

    background-color: transparent;
  }
  .btn_reset_cross1 {
    border: 0px;
    outline: none;
    background-color: transparent;
    padding: 0 2% 0 0;
    font-size: 10px;
  }
  .select_box_container_under {
    font-size: 12px;
  }
  .title_input_field_reset_btn_container1 {
    margin: 3% 0 5% 0;
  }
  .contact_number_reset_btn_container1 {
    display: flex;
    width: 100%;
    margin: 0 0% 0 3%;
    background: #f6faff;
    padding: 2% 0 2% 0;
  }
  .contact_number_input_field {
    border: 0px;
    outline: none;
    width: 100%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 13px;
    background: #f6faff;
    padding: 0 2% 0 3%;
  }
  .select_box_number_reset_btn_container {
    display: flex;
    width: 100%;
    padding: 1% 0 1% 0;
    /* border: 1px solid #000; */
  }
  .select_box_container_under1 {
    font-size: 12px;
  }
  .modal_btn_txt_submit {
    background: #1273b7;
    width: 100%;
    padding: 3% 0 3% 0;
    color: #fff;
    font-family: "PSMED";
    letter-spacing: 0.8px;
    font-size: 16px;
    outline: none;
    border: 0;
    border-radius: 4px;
  }
  .modal_btn_txt_submit:hover {
    background: #01bc82;
    color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .modal_styles {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 3% 3% 0 3%;
    width: 50%;
    z-index: 1000;
    max-height: 460px;
    overflow-y: auto;
    border-radius: 8px;
  }

  .overlay_styles {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  .close_btn_text_container {
    display: flex;
  }
  .modal_close_btn_modal {
    border: none;
    background: #01bc82;
    padding: 1%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    outline: none;
    margin: 0 0 2% auto;
  }
  .fill_the_details_txt {
    font-size: 30px;
    font-family: PSMed;
    color: #01bc82;
    letter-spacing: 1px;
  }
  .form_container {
    margin: 5% 0 5% 0;
  }
  .title_input_field_reset_btn_container {
    margin: 1% 0 5% 0;
  }
  .input_title {
    font-size: 17px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_container {
    background: #f6faff;
    width: 100%;
    padding: 2% 1% 2% 1%;
  }
  .full_name_input_field {
    border: 0px;
    outline: none;
    width: 96%;
    font-family: "PSREG";
    letter-spacing: 1px;
    font-size: 15px;
    background: #f6faff;
  }
  .btn_reset_cross {
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross {
    font-size: 13px;
    border: 0px;
    outline: none;
    background-color: transparent;
  }
  .btn_reset_cross1 {
    border: 0px;
    outline: none;
    background-color: transparent;
    padding: 0 2% 0 0;

    font-size: 12px;
  }
  .select_box_container_under {
    outline: none;
    border: 0;
    padding: 18% 3% 18% 3%;
    font-size: 14px;
    background: #f6faff;
  }
  .title_input_field_reset_btn_container1 {
    margin: 3% 0 5% 0;
  }
  .contact_number_reset_btn_container1 {
    display: flex;
    width: 100%;
    margin: 0 0% 0 3%;
    background: #f6faff;
    padding: 2% 0 2% 0;
  }
  .contact_number_input_field {
    border: 0px;
    outline: none;
    width: 100%;
    font-family: "PSREG";
    letter-spacing: 1px;

    font-size: 14px;

    background: #f6faff;
    padding: 0 2% 0 3%;
  }
  .select_box_number_reset_btn_container {
    display: flex;
    width: 100%;
    padding: 1% 0 1% 0;
    /* border: 1px solid #000; */
  }
  .select_box_container_under1 {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 13px;
    padding: 3% 0% 3% 0;
    background: #f6faff;
    margin: 2% 0 0 0;
  }
  .modal_btn_txt_submit {
    background: #1273b7;
    width: 100%;
    padding: 2% 0 2% 0;
    color: #fff;
    font-family: "PSMED";
    letter-spacing: 0px;
    font-size: 19px;
    outline: none;
    border: 0;
    border-radius: 5px;
  }
  .modal_btn_txt_submit:hover {
    background: #01bc82;
    color: #fff;
  }
}

@media screen and (min-width: 991px) {
  .modal_styles {
    width: 50%;

    max-height: 650px;
  }
}

@media screen and (min-width: 1200px) {
  .modal_styles {
    width: 50%;

    max-height: 650px;
  }
  .input_title {
    font-size: 24px;
    font-family: "PSReg";
    letter-spacing: 0.5px;
  }
  .full_name_input_field {
    font-size: 19px;
  }
  .select_box_container_under {
    font-size: 19px;
  }
  .contact_number_input_field {
    font-size: 19px;
  }
  .select_box_container_under1 {
    font-size: 19px;
  }
}
